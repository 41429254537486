import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../auth/auth_overlay.css';

const CompanyAuthOverlay = ({ returnTo }) => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate('/welcome-lexpro', { 
      state: { 
        from: { pathname: returnTo || '/movers-dashboard' }
      }
    });
  };

  return (
    <div className="auth-overlay">
      <div className="auth-modal">
        <h2 className="auth-title">Moving Company Access Only</h2>
        <p className="auth-message">
          You must be signed in as a verified moving company to view this page
        </p>
        <button 
          className="auth-signin-btn"
          onClick={handleSignIn}
        >
          Sign In as LexPro
        </button>
      </div>
    </div>
  );
};

export default CompanyAuthOverlay;