import React, { useState, useEffect, useCallback, useContext} from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Layout from '../../layout/layout';
import { FormContext } from '../context/form_context';
import { AddressContext } from '../context/address_context';
import './property_info.css';
import House from '../../../assets/lexos_imgs/house.png';
import ElevatorIcon from '../../../assets/lexos_imgs/icons/elevator.png';
import StairsIcon from '../../../assets/lexos_imgs/icons/stairs.png';
import DrivewayIcon from '../../../assets/lexos_imgs/icons/driveway.png';
import StreetParkingIcon from '../../../assets/lexos_imgs/icons/street_parking.png';
import LoadingDockIcon from '../../../assets/lexos_imgs/icons/dock.png';
import GatedEntranceIcon from '../../../assets/lexos_imgs/icons/gate.png';
import NavigationArrows from '../../layout/nav_arrows';
import { FaFastForward } from "react-icons/fa";
import DestinationComponent from './inventory-dest';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';



const PropertyInformation = () => {
  // const {address } = useContext(AddressContext);
  const location = useLocation();
  const [step, setStep] = useState(0);
  const [direction, setDirection] = useState('');
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [openDropdown, setOpenDropdown] = useState('currentAddress');
  const { formData, setFormData, accessOptions, setAccessOptions } = useContext(FormContext);
  const [isFromReview, setIsFromReview] = useState(false);
  const [isFromInventory, setIsFromInventory] = useState(false);
  const [initialDestinations, setInitialDestinations] = useState(formData.destinations || []);
  const [hasChangedAddress, sethasChangedAddress] = useState(false);

  const toggleDropdown = (address) => {
    setOpenDropdown(openDropdown === address ? null : address);
  };
  useEffect(() => {
    if(location.state?.fromInventory){
      setIsFromInventory(true);
    }
    if (location.state?.fromReview) {
      setIsFromReview(true);
      if (location.state.formData) {
        setFormData(location.state.formData);
        setInitialDestinations(location.state.formData.destinations || []);
      }
    } else {
      setInitialDestinations(formData.destinations || []);
    }
  }, [location.state, setFormData]);


  const handleBackToReview = () => {
    navigate('/review');
  };
  const handleGoToInventory = () => {
    navigate('/generate-inventory', { state: { formData, fromAccess: true } });
  };
  
  useEffect(() => {
    // Open the first dropdown when reaching the access step
    if (steps[step].isAccessStep) {
      const firstAddress = formData.currentAddress;
      setOpenDropdown(firstAddress);
    } else {
      setOpenDropdown(null);
    }
  }, [step, formData.currentAddress]);

  const handleDestinationsChange = (newDestinations) => {
    const abbreviated = newDestinations.map(dest => {
      const parts = dest.split(',');
      return parts.length > 1 ? `${parts[0]}, ${parts[1].trim()}` : dest;
    });

    // Check if destinations have changed
    const destinationsChanged = JSON.stringify(newDestinations) !== JSON.stringify(initialDestinations);
    if (destinationsChanged) {
      sethasChangedAddress(true);
      
    }

    setFormData(prevData => ({
      ...prevData,
      destinations: newDestinations,
      abbreviatedDestinations: abbreviated
    }));

      // Update accessOptions dynamically
      abbreviated.forEach((dest) => {
        if (!accessOptions[dest]) {
          setAccessOptions((prev) => ({
            ...prev,
            [dest]: {
              elevator: false,
              stairs: false,
              driveway: false,
              streetParking: false,
              loadingDock: false,
              gatedEntrance: false,
              noneOfTheAbove: false,
            }
          }));
        }
      });

    // Update isNextDisabled based on whether there are destinations
    setIsNextDisabled(newDestinations.length === 0);
  };

  const handleAccessChange = useCallback((address, option) => {
    setAccessOptions(prev => {
      const newOptions = {
        ...prev,
        [address]: {
          ...prev[address],
          [option]: !prev[address][option],
          noneOfTheAbove: option === 'noneOfTheAbove' ? !prev[address].noneOfTheAbove : false
        }
      };
  
      if (option === 'noneOfTheAbove' && newOptions[address].noneOfTheAbove) {
        Object.keys(newOptions[address]).forEach(key => {
          if (key !== 'noneOfTheAbove') {
            newOptions[address][key] = false;
          }
        });
      }
  
      return newOptions;
    });
  }, []);

  const isAccessComplete = useCallback(() => {
    // Iterate over each address (current address + all destination addresses)
    const allAddresses = [formData.currentAddress, ...formData.abbreviatedDestinations];
    
  
    // Check that each address has at least one access option selected
    return allAddresses.every((address) => {
      const accessOptionsForAddress = accessOptions[address] || {};
      const hasAccessOptionSelected = Object.values(accessOptionsForAddress).some((value) => value === true);
      // Ensure at least one access option or 'None of the above' is selected
      return hasAccessOptionSelected;
    });
  }, [accessOptions, formData.currentAddress, formData.abbreviatedDestinations]);
  /* ***************************** */
  const dropdownVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto', transition: { duration: 0.3 } }
  };
  

  useEffect(() => {
    if (location.state?.step) {
      const stepIndex = steps.findIndex(stepObj => stepObj.name === location.state.step);
      if (stepIndex !== -1) {
        setStep(stepIndex); // Navigate to the provided step (e.g., 'destinations')
      }
    }
  }, []);
  useEffect(() => {
    const currentStep = steps[step];
    const value = formData[currentStep.name];
    if (currentStep.name === 'destinations') {
      setIsNextDisabled(formData.destinations.length === 0 || !formData.destinations.every(dest => dest && dest.trim() !== ''));
    } else if (currentStep.isAccessStep) {
      setIsNextDisabled(!isAccessComplete());
    } else if (currentStep.name === 'buildingFloor') {
      setIsNextDisabled(false);
    } 
    else if (currentStep.name === 'bedrooms') {
      if(value === '4+'){
        setIsNextDisabled(!parseInt(formData.exactBedrooms) > 0);
      }
      else{
        setIsNextDisabled(!value);
      }
    } else {
      
      setIsNextDisabled(!value);
    }
  }, [formData, step, isAccessComplete]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (direction) {
      const timer = setTimeout(() => {
        setDirection('');
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [direction]);

  const steps = [
    {
      question: 'Type of Move',
      name: 'moveType',
      options: [
        'Packing, moving, and unpacking',
        'Packing and moving',
        'Moving and unpacking',
        'Packing and unpacking',
        'Just moving'
      ]
    },
    {
      question: 'Type of Residence',
      name: 'residenceType',
      options: ['House', 'Apartment', 'Townhouse', 'Condo']
    },
    {
      question: 'Number of Floors',
      name: 'floors',
      options: ['Single-story', '2', '3', '4+'],
      description: 'This includes basements and mezzanines',
      condition: () => formData.residenceType === 'House' || formData.residenceType === 'Townhouse' || 
                 (formData.residenceType === 'Apartment' && formData.hasMultipleFloors === 'Yes') ||
                 (formData.residenceType === 'Condo' && formData.hasMultipleFloors === 'Yes'),
    },
    {
      question: 'What is your unit number?',
      name: 'buildingFloor',
      type: 'number',
      condition: () => (formData.residenceType === 'Apartment' || formData.residenceType === 'Condo')
    },
    {
      question: 'Number of Bedrooms',
      name: 'bedrooms',
      // pickup here 
      options: ['Studio', '1', '2', '3', '4+'] 
    },
    {
      question: 'Square Footage Estimate',
      name: 'squareFootage',
      type: 'number'
    },

    {
      question: 'Destination(s)',
      name: 'destinations',
      description: 'Add the address(es) your inventory will be moved to'
    },
    {
      question: 'Access to Residences',
      name: 'access',
      isAccessStep: true,
    }
  ];

  /* ***************************** */

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleNextStep = () => {
    if (step < steps.length - 1) {
      let nextStep = step + 1;
      while (nextStep < steps.length && steps[nextStep].condition && !steps[nextStep].condition()) {
        nextStep++;
      }
      if (nextStep < steps.length) {
        setDirection('left');
        setStep(nextStep);
      }
    }
  };

  const handleBackStep = () => {
    if (step > 0) {
      let prevStep = step - 1;
      while (prevStep > 0 && steps[prevStep].condition && !steps[prevStep].condition()) {
        prevStep--;
      }
      setDirection('right');
      setStep(prevStep);
    } else {
      navigate('/');
    }
  };
  useEffect(() => {
    if (steps[step].isAccessStep) {
      setIsNextDisabled(!isAccessComplete());
    }
  }, [accessOptions, step, steps, isAccessComplete]);

  useEffect(() => {
    if (location.state?.step) {
      const stepIndex = steps.findIndex(stepObj => stepObj.name === location.state.step);
      if (stepIndex !== -1) {
        setStep(stepIndex); // Navigate to the provided step (e.g., 'destinations')
      }
    }
  }, []);

  const renderStep = () => {
    const currentStep = steps[step];
    if (currentStep.name === 'floors') {
      return (
        <label>
          <h2 className="question-text">{currentStep.question}</h2>
          {currentStep.description && (
            <p className="question-description">{currentStep.description}</p>
          )}
          <div className="options-container">
            {currentStep.options.map((option) => (
              <label key={option} className="radio-option">
                <input
                  type="radio"
                  name={currentStep.name}
                  value={option}
                  checked={formData[currentStep.name] === option}
                  onChange={handleChange}
                />
                <span>{option}</span>
              </label>
            ))}
          </div>
          {formData.floors === '4+' && (
            <div className="follow-up-question">
              <input
                type="number"
                name="exactFloors"
                value={formData.exactFloors}
                onChange={handleChange}
                placeholder="What floor are you on?"
                className="enhanced-input"
              />
            </div>
          )}
        </label>
      );
    }

    if (currentStep.name === 'bedrooms') {
      return (
        <label>
          <h2 className="question-text">{currentStep.question}</h2>
          <div className="options-container">
            {currentStep.options.map((option) => (
              option === 'Studio' ?
              formData.residenceType === 'Apartment' && 
              <label key={option} className="radio-option">
                <input
                  type="radio"
                  name={currentStep.name}
                  value={option}
                  checked={formData[currentStep.name] === option}
                  onChange={handleChange}
                />
                <span>{option}</span>
              </label>
              :
              <label key={option} className="radio-option">
              <input
                type="radio"
                name={currentStep.name}
                value={option}
                checked={formData[currentStep.name] === option}
                onChange={handleChange}
              />
              <span>{option}</span>
            </label>
            ))}
          </div>
          {formData.bedrooms === '4+' && (
            <div className="follow-up-question">
              <input
                type="number"
                name="exactBedrooms"
                value={formData.exactBedrooms}
                onChange={handleChange}
                placeholder="Enter number of bedrooms"
                className="enhanced-input"
              />
            </div>
          )}
        </label>
      );
    }
    if (currentStep.name === 'moveType') {
      return (
        <label>
          <h2 className="question-text">{currentStep.question}</h2>
          <div className="options-container">
            {currentStep.options.map((option) => (
              <label key={option} className="radio-option">
                <input
                  type="radio"
                  name={currentStep.name}
                  value={option}
                  checked={formData[currentStep.name] === option}
                  onChange={handleChange}
                />
                <span>{option}</span>
              </label>
            ))}
          </div>
          <div className="note-container">
            {/* <div className="note">
              <strong>Note: </strong> After the virtual walkthrough, you can specify different services for individual inventory items
            </div> */}
          </div>
        </label>
      );
    }
    if (currentStep.name === 'destinations') {
      return (
        <div>
          <h2 className="question-text">{currentStep.question}</h2>
          <p className="dest-question-description">{currentStep.description}</p>
          <DestinationComponent
            destinations={formData.destinations}
            setDestinations={handleDestinationsChange}
          />
        </div>
      );
    }

    if (currentStep.isAccessStep) {
      return (
        <>
          <h2 className="access-text">{currentStep.question}</h2>
          {[formData.currentAddress, ...formData.abbreviatedDestinations].map((address, index) => (
            <div key={index} className={`access-dropdown ${openDropdown === address ? 'open' : ''}`}>
              <div className="dropdown-header" onClick={() => toggleDropdown(address)}>
                <h3 className= "header-label">{address === formData.currentAddress ? formData.currentAddress : address}</h3>
                <ChevronDown className="chevron-icon" size={24} />
              </div>
              <AnimatePresence mode="wait">
                {openDropdown === address && (
                  <motion.div
                    key={address}
                    className="dropdown-content"
                    initial={{ opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 }}
                    animate={{ opacity: 1, height: 'auto', paddingTop: 15, paddingBottom: 15 }}
                    exit={{ opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 }}
                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                  >
                    
                    <div className="icon-grid">
                      {/* Safely access accessOptions for this address */}
                      {accessOptions[address] ? (
                        [
                          { name: 'elevator', icon: ElevatorIcon, label: 'Elevator' },
                          { name: 'stairs', icon: StairsIcon, label: 'Stairs' },
                          { name: 'driveway', icon: DrivewayIcon, label: 'Driveway' },
                          { name: 'streetParking', icon: StreetParkingIcon, label: 'Street Parking' },
                          { name: 'loadingDock', icon: LoadingDockIcon, label: 'Loading Dock' },
                          { name: 'gatedEntrance', icon: GatedEntranceIcon, label: 'Gated Entrance' },
                        ].map(({ name, icon, label }) => (
                          <label key={name} className="icon-checkbox">
                            <input
                              type="checkbox"
                              checked={accessOptions[address][name]}  // Safe access
                              onChange={() => handleAccessChange(address, name)}
                            />
                            <img src={icon} alt={label} />
                            <span>{label}</span>
                          </label>
                        ))
                      ) : (
                        <p>Loading access options...</p> // Handle case where accessOptions is undefined
                      )}
                    </div>
                    <label className="none-checkbox">
                      <input
                        type="checkbox"
                        checked={accessOptions[address]?.noneOfTheAbove || false}  // Safe access
                        onChange={() => handleAccessChange(address, 'noneOfTheAbove')}
                      />
                      <span>None of the above</span>
                    </label>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </>
      );
    }

    if (currentStep.type === 'number') {
      if (currentStep.name === 'buildingFloor') {
        return (
          <label>
            <h2 className="question-text">{currentStep.question}</h2>
            <input
              type="number"
              name={currentStep.name}
              value={formData[currentStep.name] || ''}
              onChange={handleChange}
              placeholder="Press next if not applicable"
              className="enhanced-input"
            />
          </label>
        );
      } else { 
        return (
          
          <label>
            <h2 className="question-text">{currentStep.question}</h2>
            <input
              type="number"
              name={currentStep.name}
              value={formData[currentStep.name] || ''}
              onChange={handleChange}
              placeholder="Enter a number"
              className="enhanced-input"
            />
          </label>
        );
      }
    }

    return (
      <label>
        <h2 className="question-text">{currentStep.question}</h2>
        {currentStep.description && (
          <p className="question-description">{currentStep.description}</p>
        )}
        <div className="options-container">
          {currentStep.options.map((option) => (
            <label key={option} className="radio-option">
              <input
                type="radio"
                name={currentStep.name}
                value={option}
                checked={formData[currentStep.name] === option}
                onChange={handleChange}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>

        {/* Inline Yes/No question for multiple floors (only on the first step) */}
        {step === 1 && (formData.residenceType === 'Apartment' || formData.residenceType === 'Condo') && (
          <div className="follow-up-question">
            <h3>Are there multiple floors?</h3>
            <div className="options-container">
              <label className="radio-option">
                <input
                  type="radio"
                  name="hasMultipleFloors"
                  value="Yes"
                  checked={formData.hasMultipleFloors === 'Yes'}
                  onChange={handleChange}
                />
                <span>Yes</span>
              </label>
              <label className="radio-option">
                <input
                  type="radio"
                  name="hasMultipleFloors"
                  value="No"
                  checked={formData.hasMultipleFloors === 'No'}
                  onChange={handleChange}
                />
                <span>No</span>
              </label>
            </div>
          </div>
        )}
      </label>
    );
  };

  const navigate = useNavigate();

  const handleNext = () => {
    if(isFromInventory){
      navigate('/generate-inventory', { state: { formData, fromAccess: true } });
    }
    else {
      navigate('/begin-tour', {
        state: {
          ...formData,  // Include the formData containing moveType, residenceType, etc.
          accessOptions: accessOptions,  // Include the accessOptions for each address
          moveType: formData.moveType,
          residenceType: formData.residenceType,
          bedrooms: formData.bedrooms,
          squareFootage: formData.squareFootage,
          floors: formData.floors,
          abbreviatedDestinations: formData.abbreviatedDestinations
        }
      });
    }

};
const renderNavigation = () => {
  if (isFromReview) {
    const showBackToReviewButton = !hasChangedAddress;
    if (step < steps.length - 1) {
      return (
        <>
          <NavigationArrows
            onPrevClick={handleBackStep}
            onNextClick={handleNextStep}
            prevLabel={step === 0 ? 'Home' : 'Previous'}
            nextLabel="Next"
            inverted
            isNextDisabled={isNextDisabled}
          />
            {showBackToReviewButton && (
              <button 
                className="back-to-review-button" 
                onClick={handleBackToReview}
                disabled={steps[step].isAccessStep && !isAccessComplete()}
              >
                Back to Review
                <FaFastForward className="fast-forward-icon" />
              </button>
            )}
          </>
        );
      } else {
        return (
          <NavigationArrows
            onPrevClick={handleBackStep}
            onNextClick={hasChangedAddress? handleGoToInventory: handleBackToReview}
            prevLabel="Previous"
            nextLabel={hasChangedAddress? "Verify Inventory" : "Back to Review"}
            isNextDisabled={isNextDisabled || (steps[step].isAccessStep && !isAccessComplete())}
            inverted
          />
        );
      }
    } else {
    return (
      <NavigationArrows
        onPrevClick={handleBackStep}
        onNextClick={step < steps.length - 1 ? handleNextStep : handleNext}
        prevLabel={step === 0 ? 'Home' : 'Previous'}
        nextLabel={step < steps.length - 1 ? 'Next' : 'Next Page'}
        inverted
        isNextDisabled={isNextDisabled || (steps[step].isAccessStep && !isAccessComplete())}
      />
    );
  }
};

  return (
    <Layout>
      <div className="property-info-container">
        <div className="property-info-header">
          <h1>Property Information <img src={House} alt="House" className="img-house" /></h1>
        </div>
        <form className="property-info-form" onSubmit={(e) => e.preventDefault()}>
          <div className={`step-content ${direction}`}>
            {renderStep()}
          </div>
          <div className="property-info-nav">
          {renderNavigation()}
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default PropertyInformation;
