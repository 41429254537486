import { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, where, limit, onSnapshot } from 'firebase/firestore';
import { db } from './fireconfig';
import { getAuth, onAuthStateChanged  } from 'firebase/auth';

/* 
* This entire function is responsible for the offers PULLED into the dashboard
* 1. Pulls all offers if there is not an offer booked
* 2. Upon an offer being accepted, update the offers to only reflect the accepted offers
* NOTE: This function is not responsible for handling the accepted offer (this is done in the user dashboard)
* This function is states, then useEffect
*/
const GetUserOffers = () => {
  // All states below returned
  const [offers, setOffers] = useState(new Map());
  const [acceptedOffer, setAcceptedOffer] = useState(new Map());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    const auth = getAuth();
    // unsubscribeOffers field responsible for monitoring user state
    let unsubscribeOffers = null;

    // Offers Listener function 
    const setupOffersListener = async (user) => {
      try {
        // See if the user has any moveDetails (i.e. any submitted moves to gain offers)
        // TODOF: Eventually, this should be a descriptive "you have not yet submitted a move" message
        const moveDetailsQuery = query(collection(db, 'moveDetails'), where('userId', '==', user.uid), orderBy('created_at', 'desc'), limit(1));
        const moveDetailsSnapshot = await getDocs(moveDetailsQuery);
        
        if (moveDetailsSnapshot.empty) {
          setLoading(false);
          return;
        }
        const moveDetailsId = moveDetailsSnapshot.docs[0].data().moveDetailsId;

        // With our moveDetailsId, query the offers table for offers with that Id
        const offersQuery = query(collection(db, 'offers'), where('moveDetailsId', '==', moveDetailsId), orderBy('timestamp', 'desc'));
        // IMPORTANT: onSnapshot pulls a direct, realtime snapshot
        unsubscribeOffers = onSnapshot(offersQuery, (snapshot) => {
          const userOffersMap = new Map();
          const acceptedOfferMap = new Map();

          // Iterates through all offers in the given snapshot
          snapshot.docs.forEach((doc) => {
            const offerDetails = doc.data();
            const companyName = offerDetails.movingCompany;

            // Map one offer per moving company into the "userOffersMap" pool
            if (!userOffersMap.has(companyName)) {
              const baseOffer = {
                moveDetailsId: offerDetails.moveDetailsId,
                offerId: offerDetails.offerId,
                estimatedTime: offerDetails.estimatedTime,
                fileUrl: offerDetails.fileUrl,
                hourlyRate: offerDetails.hourlyRate,
                numericTime: offerDetails.numericTime,
                insuranceCoverage: offerDetails.insuranceCoverage,
                estimateTypes: offerDetails.estimateTypes,
                displayQuote: offerDetails.displayQuote,
                quoteValue: offerDetails.quoteValue,
                status: offerDetails.status
              };

              userOffersMap.set(companyName, baseOffer);

              // Check if this offer was ever accepted by the user
              // We consider an offer as "user accepted" if it was ever in accepted/booked state
              // or if it's in a payment-related state (which means it was accepted)
              const wasAccepted = 
                offerDetails.status === 'accepted' || 
                offerDetails.status === 'booked' ||
                offerDetails.status === 'awaiting_payment' ||
                offerDetails.status === 'payment_failed';
              if (wasAccepted) {
                acceptedOfferMap.set(companyName, {
                  ...baseOffer,
                  // Always show as booked to the user once they've accepted
                  status: 'booked'
                });
              }
            }
          });


          // IMPORTANT: set the state's here
          setOffers(userOffersMap);
          setAcceptedOffer(acceptedOfferMap);
          setLoading(false);
        }, (err) => {
          console.error('Error fetching Offers:', err);
          setError(err);
          setLoading(false);
        });

      } catch (err) {
        console.error('Error setting up offers listener:', err);
        setError(err);
        setLoading(false);
      }
    };

    // Monitor the user authentication state;
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setupOffersListener(user);
      } else {
        setLoading(false);
      }
    });

    return () => {
      if (unsubscribeOffers) unsubscribeOffers();
      unsubscribeAuth();
    };
  }, []);

  return { offers, acceptedOffer, loading, error };
};

export default GetUserOffers;