// import React from "react";
// import PropTypes from "prop-types";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// import './alert.css';
// import { confirmable, createConfirmation } from "react-confirm";

// export const Confirmation = ({
//   okLabel = "Restart",
//   cancelLabel = "Cancel",
//   title = "Restart Virtual Walkthrough?",
//   confirmation = "Returning to the previous page will resubmit all photos for a new inventory generation.",
//   show,
//   proceed,
//   enableEscape = true
// }) => {
//   return (
//     <div className="static-modal">
//       <Modal 
//         animation={false}
//         show={show}
//         onHide={() => proceed(false)}
//         backdrop={enableEscape ? true : "static"}
//         keyboard={enableEscape}
//         dialogClassName="custom-modal"
//         centered
//       >
//         <Modal.Header>
//           <Modal.Title centered>{title}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="alert-modal">{confirmation}</Modal.Body>
//         <Modal.Footer className = "alert-nav">
//           <Button className="alert-no"onClick={() => proceed(false)}>{cancelLabel}</Button>
//           <Button
//             className="alert-yes"
//             bsStyle="primary"
//             onClick={() => proceed(true)}
//           >
//             {okLabel}
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// Confirmation.propTypes = {
//   okLabel: PropTypes.string,
//   cancelLabel: PropTypes.string,
//   title: PropTypes.string,
//   confirmation: PropTypes.string,
//   show: PropTypes.bool,
//   proceed: PropTypes.func, // called when ok button is clicked.
//   enableEscape: PropTypes.bool
// };

// export function confirm(
//   confirmation,
//   proceedLabel = "OK",
//   cancelLabel = "cancel",
//   options = {}
// ) {
//   return createConfirmation(confirmable(Confirmation))({
//     confirmation,
//     okLabel: proceedLabel,
//     cancelLabel,
//     ...options
//   });
// }
import React, { createContext, useContext, useState, useCallback } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import './alert.css';

// Create a context for the confirmation modal
const ConfirmationContext = createContext({});

// Provider component
export const ConfirmationProvider = ({ children }) => {
  const [state, setState] = useState({
    isOpen: false,
    title: '',
    message: '',
    okLabel: '',
    cancelLabel: '',
    onConfirm: () => {},
  });

  const confirm = useCallback(
    (message, okLabel = 'Accept', cancelLabel = 'Cancel', options = {}) => {
      return new Promise((resolve) => {
        setState({
          isOpen: true,
          message,
          okLabel,
          cancelLabel,
          ...options,
          onConfirm: resolve,
        });
      });
    },
    []
  );

  const handleClose = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));
  }, []);

  const handleConfirm = useCallback(() => {
    state.onConfirm(true);
    handleClose();
  }, [state.onConfirm, handleClose]);

  const handleCancel = useCallback(() => {
    state.onConfirm(false);
    handleClose();
  }, [state.onConfirm, handleClose]);

  return (
    <ConfirmationContext.Provider value={{ confirm }}>
      {children}
      <Modal
        show={state.isOpen}
        onHide={handleCancel}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>{state.title || 'Confirm Action'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="alert-modal">{state.message}</Modal.Body>
        <Modal.Footer className="alert-nav">
          {state.cancelLabel !== null && <Button className="alert-no" onClick={handleCancel}>
            {state.cancelLabel}
          </Button>}
          <Button className="alert-yes" onClick={handleConfirm}>
            {state.okLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </ConfirmationContext.Provider>
  );
};

// Hook to use the confirmation dialog
export const useConfirm = () => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmationProvider');
  }
  return context.confirm;
};