import React from 'react';
import {useNavigate } from 'react-router-dom';
import Layout from '../../layout/layout';
import Confetti from 'react-confetti';  // Import Confetti
import Offer from '../../../assets/lexos_imgs/move-submitted.png';
import './final_message.css';



const FinalMessage = () => {
  // Use navigate to redirect to the offers page
  const navigate = useNavigate();

  const handleViewOffers = () => {
    navigate('/dashboard');
  }
  // Determine screen dimensions for confetti
  const { innerWidth: width, innerHeight: height } = window;

    return (
      <Layout>
        <div className="fm-container" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* {<Confetti width={width} height={height} numberOfPieces={1000} recycle={false}/>} Confetti animation */}
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Thank You for Your Submission!</h2>
              <img src={Offer} alt="Congratulations" className="img-fluid" />
              <p className="text-muted">
                Moving companies will bid on your move shortly.
                <br />
                <span className="view-offers-link" onClick={handleViewOffers}> View Offers Here</span> {/* Link to offers page */}
              </p>
            </div>
          </div>
        </div>
      </Layout>
    );
    }


export default FinalMessage;
