import React , {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './offer_modal.css';
import { MdOutlineLocalOffer, MdOutlineHandshake, MdAccessTimeFilled   } from "react-icons/md";
import { FaHandshake,FaPercentage } from "react-icons/fa";
import { IoShieldCheckmark } from "react-icons/io5";
import Rate from '../../../assets/lexos_imgs/icons/working-hours.svg';
import { motion } from 'framer-motion';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import ViewBreakdown from "./view_breakdown";

// ### - ###

const estimates = {
  "weightbased" : "Weight-based",
  "distancebased" : "Distance-based",
  "binding" : "Binding",
  "nonbinding" : "Non-binding",
  "guaranteed nottoexceed" : "Guaranteed not-to-exceed"
}
// 'Weight-based', 'Distance-based', 'Binding', 'Non-binding', 'Guaranteed Not-to-exceed'
const getEstimateTypes = (estimatesArr) => {
  let formatEstimates = [];
  estimatesArr.forEach((entry) => 
    formatEstimates.push(estimates[entry])
  );
  return formatEstimates.join(", ");
}

// {movingCompanyId : {estimatedHours, fileUrl, hourlyRate, insuranceCoverage, movingCompanyId, pricingModels, quoteAmount, status}}
const OfferModal = ({ show, onClose, name, offerDetails, acceptOffer}) => {

  const handleViewFile = (e) => {
    e.preventDefault();
    // Pass the Firebase Storage path directly
    navigate('/view-breakdown', { 
        state: { breakdownUrl: offerDetails.fileUrl }
    });
};
  
  const navigate = useNavigate();
  
    if (!show) {
    return null;
  }

  return (
    <motion.div 
      className="offer-modal"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className="modal-content"
        initial={{ scale: 0.8, y: -50 }}
        animate={{ scale: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <button className="close-btn" onClick={onClose}>&times;</button>
        <h2 className="modal-title">Offer Breakdown</h2>
        <h5 className="company-name">{name}</h5>
        
        <div className="offer-details">
          <OfferDetail icon={<MdOutlineLocalOffer />} label="Total Estimate" value={offerDetails.displayQuote} />
          <OfferDetail icon={<MdOutlineHandshake />} label="Offer Type" value={getEstimateTypes(offerDetails.estimateTypes)} />
          {offerDetails.hourlyRate && 
            <OfferDetail icon={<FaPercentage />} label="Hourly Rate" value={`$${offerDetails.hourlyRate}/hour`} />
          }
          <OfferDetail icon={<MdAccessTimeFilled />} label="Estimated Time" value={offerDetails.hourlyRate !== '' ? `${offerDetails.numericTime} hours` : offerDetails.estimatedTime} />
          <OfferDetail icon={<IoShieldCheckmark />} label="Insurance" value={`${offerDetails.insuranceCoverage[0].toUpperCase()}${offerDetails.insuranceCoverage.substring(1)}`} />
        </div>
        
        <div className="modal-actions">
          {offerDetails.fileUrl !== null && <button className="btn btn-secondary btn-sm" onClick={handleViewFile}> View File</button>}
          <button className="btn-close-text" onClick={onClose}>Close</button>
          
          {/* {offerDetails.status !== 'booked' ? <button onClick={acceptOffer} className="btn accept-offer-btn">Accept Offer</button> : ''} */}
        </div>
      </motion.div>
    </motion.div>
  );
};

const OfferDetail = ({ icon, label, value }) => (
  <div className="offer-detail">
    {React.cloneElement(icon, { className: "detail-icon" })}
    <div className="detail-text">
      <span className="detail-label">{label}</span>
      <span className="detail-value">{value}</span>
    </div>
  </div>
);

export default OfferModal;