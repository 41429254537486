import { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, where, limit } from 'firebase/firestore';
import { db } from './fireconfig';


const UseUserListings = () => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        // Fetch all moveDetails documents
        const moveDetailsQuery = query(
          collection(db, 'moveDetails'),
          orderBy('created_at', 'desc')
        );
        const moveDetailsSnapshot = await getDocs(moveDetailsQuery);
        
        // Group listings by userId and keep only the most recent one
        const userListingsMap = new Map();
        
        for (const moveDoc of moveDetailsSnapshot.docs) {
          const moveDetails = moveDoc.data();
          const userId = moveDetails.userId;
          
          if (!userListingsMap.has(userId)) {
            userListingsMap.set(userId, {
              moveDetailsId: moveDoc.id,
              userId,
              moveDetails,
            });
          }
        }
        
        // Fetch related data for each unique user listing
        const listingsData = await Promise.all(Array.from(userListingsMap.values()).map(async ({ moveDetailsId, userId, moveDetails }) => {
          // Fetch related documents using the userId
          const [inventorySnapshot, destSnapshot, originSnapshot, walkSnapshot] = await Promise.all([
            getDocs(query(collection(db, 'Inventory'), where('userId', '==', userId), orderBy('created_at', 'desc'), limit(1))),
            getDocs(query(collection(db, 'destinationInfo'), where('userId', '==', userId), orderBy('created_at', 'desc'), limit(1))),
            getDocs(query(collection(db, 'originInfo'), where('userId', '==', userId), orderBy('created_at', 'desc'), limit(1))),
            getDocs(query(collection(db, 'virtualWalkthroughs'), where('userId', '==', userId), orderBy('created_at', 'desc'), limit(1)))
          ]);

          const inventory = inventorySnapshot.docs[0]?.data();
          const destinationInfo = destSnapshot.docs[0]?.data();
          const originInfo = originSnapshot.docs[0]?.data();
          const virtualWalkthrough = walkSnapshot.docs[0]?.data();

          return {
            moveDetailsId,
            userId,
            inventory: inventory?.rooms || null,
            destinationInfo: destinationInfo || null,
            moveDetails,
            originInfo: originInfo || null,
            virtualWalkthrough: virtualWalkthrough?.roomImages || null,
          };
        }));

        setListings(listingsData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching listings:', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchListings();
  }, []);

  return { listings, loading, error };
};

export default UseUserListings;