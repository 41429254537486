import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import BidIcon from '../../../assets/lexos_imgs/new-offer.svg';
import { db } from '../../../firebase/fireconfig';
import Layout from '../../layout/layout';
import {Modal} from 'react-bootstrap';
import './view_listing.css';
import NavigationArrows from '../../layout/nav_arrows';

const ViewListing = () => {
  const location = useLocation();
  const { moveDetailsId } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(location.state?.status === 'booked' ? 'contact' : 'general'); 
  const [showModal, setShowModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Grab the totals (detail) for the listing
  const totals = (inventory) => {
    let sumWeight = 0;
    let sumVolume = 0;
    // For each room and it's items object
    Object.entries(inventory.rooms).forEach(([room, items]) => {
      // For each items object, grab the items array
      Object.entries(items).map((item) => 
        Object.entries(item[1]).map((info) => {
          const detailArray = info[1].details;
          let weightMatches = 0;
          let volumeMatches = 0;
          if(detailArray.length > 2){
            weightMatches = detailArray[1]?.match(/(\d+)/);
            if(weightMatches){
              sumWeight += parseInt(weightMatches[0]);
            }
            volumeMatches = detailArray[2]?.match(/(\d+)/);
            if(volumeMatches){
              sumVolume += parseInt(volumeMatches[0]);
            }          
          } else {
            weightMatches = detailArray[0]?.match(/(\d+)/);
            if(weightMatches){
              sumWeight += parseInt(weightMatches[0]);
            }
            volumeMatches = detailArray[1]?.match(/(\d+)/);
            if(volumeMatches){
              sumVolume += parseInt(volumeMatches[0]);
            }
          };
        })
        
        )
      });

      return {totalWeight: sumWeight, totalVolume: sumVolume};
    };
    


  useEffect(() => {
    let mounted = true;

    const fetchListing = async () => {
      try {
        const moveDetailsDoc = await getDoc(doc(db, 'moveDetails', moveDetailsId));
        if (moveDetailsDoc.exists()) {
          const moveDetails = moveDetailsDoc.data();
          const userId = moveDetails.userId;
          // IMPORTANT: Payment status must be mapped in the moveDetails document
          const paymentStatus = moveDetails.paymentStatus;


          // Fetch related documents using the userId
          const [inventorySnapshot, destSnapshot, originSnapshot, walkSnapshot, contact] = await Promise.all([
            getDocs(query(collection(db, 'Inventory'), where('userId', '==', userId), orderBy('created_at', 'desc'), limit(1))),
            getDocs(query(collection(db, 'destinationInfo'), where('userId', '==', userId), orderBy('created_at', 'desc'), limit(1))),
            getDocs(query(collection(db, 'originInfo'), where('userId', '==', userId), orderBy('created_at', 'desc'), limit(1))),
            getDocs(query(collection(db, 'virtualWalkthroughs'), where('userId', '==', userId), orderBy('created_at', 'desc'), limit(1))),
            getDoc(doc(db, 'homeOwners', userId))
          ]);

          if (!mounted) return;

          const inventory = inventorySnapshot.docs[0]?.data();
          const destinationInfo = destSnapshot.docs[0]?.data();
          const originInfo = originSnapshot.docs[0]?.data();
          const virtualWalkthrough = walkSnapshot.docs[0]?.data();
          const contactInfo = contact.data();

          if(mounted){
            setListing({
              moveDetailsId,
              userId,
              inventory: inventory?.rooms || null,
              destinationInfo: destinationInfo || null,
              moveDetails,
              originInfo: originInfo || null,
              virtualWalkthrough: virtualWalkthrough?.roomImages || null,
              contactInfo: contactInfo, 
              paymentStatus: paymentStatus,
              totals: inventory? totals(inventory) : null
            });
            setLoading(false);
            // Set initialized after a small delay to ensure smooth transition
            setTimeout(() => {
              if (mounted) {
                setInitialized(true);
              }
            }, 100);

          }

        } 
      } catch (err) {
        if (mounted) {
          setError(err);
          setLoading(false);
          setInitialized(true);
        }
      }
    };

    fetchListing();
    return () => {
      mounted = false;
    };


  }, [moveDetailsId]);

  // Return null during initial load to prevent flash
  if (!initialized) {
    return (<Layout>
    </Layout>)
  }

  const formatPhone = (phone) =>{
    return `+1(${phone.substring(0,3)}) ${phone.substring(3,6)}-${phone.substring(6,10)}`
  }


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const renderContactInfo = () => {
      return (
        <div>
          <p><strong>Name: </strong> {listing.contactInfo.fullName}</p>
          <p><strong>Phone:</strong> {listing.contactInfo.phone} </p>
          <p><strong>Email:</strong> {listing.contactInfo.email}</p>
        </div>
      )
  };

  const renderGeneralInfo = () => {
    const formatAccessType = (type) => {
      if (type === 'noneOfTheAbove') return 'N/A';
      return type.replace(/([A-Z])/g, ' $1').trim();
    };

  
    // Process the access data
    const processAccess = (accessData) => {
      if (!accessData || accessData.length === 0) return 'N/A';
      
      const filteredAccess = accessData.filter(item => item.value && item.type !== 'noneOfTheAbove');
      
      if (filteredAccess.length === 0) {
        // If only noneOfTheAbove is true or no options are selected
        return 'N/A';
      }
      
      return filteredAccess
        .map(item => formatAccessType(item.type.charAt(0).toUpperCase() + item.type.slice(1)))
        .join(', ');
    };
  
    const destinationAccess = processAccess(listing.destinationInfo?.access);
    const originAccess = processAccess(listing.originInfo?.access);
  
    return (
      <div>
        <p><strong>From:</strong> {listing.originInfo?.line}, {listing.originInfo?.city}, {listing.originInfo?.state}</p>
        <p><strong>Access to {listing.originInfo?.line}:</strong> {originAccess}</p>
        <p><strong>Origin Residence Type:</strong> {listing.originInfo.residenceType}</p>
        <p><strong>Bedrooms:</strong> {listing.originInfo?.numBedrooms}</p>
        <p><strong>Floors:</strong> {listing.originInfo?.numFloors}</p>
        {listing.originInfo.level >= 0 && (
          <p><strong>Level:</strong> {listing.originInfo.level || `N/A`}</p>
        )}
        <p><strong>Move Services: </strong> {listing.originInfo?.moveType}</p>
        <p><strong>Square Footage Estimate:</strong> {listing.originInfo?.squareFeet}</p>
        <p><strong>To:</strong> {listing.destinationInfo?.line}, {listing.destinationInfo?.city}, {listing.destinationInfo?.state}</p>
        <p><strong>Access to {listing.destinationInfo?.line}:</strong> {destinationAccess}</p>
        <p><strong>Preferred Arrival Time:</strong> {listing.moveDetails?.dom} at {listing.moveDetails?.time}</p>
      </div>
    );
  };

  const renderInventory = () => {
    const renderItems = (items) => {
      if (Array.isArray(items)) {
        return items.map((item, index) => (
          <tr key={index}>
            <td className="table-column-item"><strong>{item.item || 'N/A'}</strong></td>
            <td>
              {Array.isArray(item.details) ? (
                <ul className="list-disc pl-4">
                  {item.details.map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))}
                </ul>
              ) : (
                item.details || 'N/A'
              )}
            </td>
            <td>{item.destination || 'N/A'}</td>
            <td>{item.highValue > 0 ? 'Yes' : 'No'}</td>
            <td>
              {item.services && typeof item.services === 'object' 
                ? Object.entries(item.services)
                    .filter(([_, value]) => value)
                    .map(([service]) => 
                      service === 'furnitureAssembly' ? service.charAt(0).toUpperCase() + service.substring(1,9) + ' ' + service.substring(9)
                      :
                      service.charAt(0).toUpperCase() + service.slice(1))
                    .join(', ')
                : 'N/A'
              }
            </td>
          </tr>
        ));
      }
      return null;
    };
  
    return (
      <div className="listing-inventory">

        {listing.inventory && Object.keys(listing.inventory).length > 0 ? (
          Object.entries(listing.inventory).map(([room, roomData]) => (
            <div key={room}>
              <h3 className="listing-room-label">{room}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Details</th>
                    <th>Destination</th>
                    <th>High Value</th>
                    <th>Services</th>
                  </tr>
                </thead>
                <tbody>
                  {roomData.items && Array.isArray(roomData.items) 
                    ? renderItems(roomData.items)
                    : (
                      <tr>
                        <td colSpan="4">No items in this room</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>

            </div>
           
          ))
        ) : (
          <p>No inventory available</p>
        )}
        {listing.inventory && listing.totals ? (
        <div>
        <h3 className="listing-room-label">Totals</h3>
        <table>
          <thead>
            <tr>
              <th>Weight</th>
              <th>Volume</th>
            </tr>
          </thead>
          <tbody>
            <tr><td>{listing.totals.totalWeight} lbs</td> <td>{listing.totals.totalVolume} ft³</td></tr>
          </tbody>
        </table>
        </div>
        ) : (
          <p>No totals available</p>
          
        )}
      </div>
    );
  };

  const renderTour = () => {
    const roomNames = listing.inventory ? Object.keys(listing.inventory) : [];

    return (
      <div>
        <h3>Virtual Tour</h3>
        {listing.virtualWalkthrough && Object.keys(listing.virtualWalkthrough).length > 0 ? (
          Object.entries(listing.virtualWalkthrough).map(([roomIndex, images], index) => (
            <div key={roomIndex} className="room-tour">
              <h3 className="listing-room-label">{roomNames[index] || `Room ${parseInt(roomIndex) + 1}`}</h3>
              <div className="moving-photo-container">
                {images.map((imageUrl, idx) => (
                  <div key={idx} className="photo-preview-wrapper">
                    <img
                      src={imageUrl}
                      alt={`${roomNames[index] || `Room ${parseInt(roomIndex) + 1}`} - Image ${idx + 1}`}
                      className="photo-preview"
                      onClick={() => handlePhotoClick(imageUrl)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p>No virtual tour available</p>
        )}
      </div>
    );
  };

  return (
    <Layout>
      <div className="viewListing-container">
        <h1 className="listing-title">Listing Details</h1>
        <div className="tabs">
          {listing.paymentStatus === 'complete' && location.state?.status === 'booked' && <button
              className={`tab-button ${activeTab === 'contact' ? 'active' : ''}`}
              onClick={() => handleTabChange('contact')}
            >
              Contact
          </button>}
          <button
            className={`tab-button ${activeTab === 'general' ? 'active' : ''}`}
            onClick={() => handleTabChange('general')}
          >
            General
          </button>
          <button
            className={`tab-button ${activeTab === 'inventory' ? 'active' : ''}`}
            onClick={() => handleTabChange('inventory')}
          >
            Inventory
          </button>
          <button
            className={`tab-button ${activeTab === 'tour' ? 'active' : ''}`}
            onClick={() => handleTabChange('tour')}
          >
            Walkthrough
          </button>
        </div>
        <div className="tab-content">
          {activeTab === 'contact' && listing.paymentStatus === 'complete' && renderContactInfo()}
          {activeTab === 'general' && renderGeneralInfo()}
          {activeTab === 'inventory' && renderInventory()}
          {activeTab === 'tour' && renderTour()}
        </div>
        <div className="listingView-button-navigation">
          {/*       <NavigationArrows
        onPrevClick={() => navigate('/movers-dashboard')}
        onNextClick={() => navigate(`/bid-form/${listing.moveDetailsId}`)}
        prevLabel={'Back'}
        nextLabel={'Submit a Bid'}
        inverted
      /> */}
        <NavigationArrows
          onPrevClick={() => navigate('/movers-dashboard')}
          onNextClick={() => navigate(`/bid-form/${listing.moveDetailsId}`)}
          prevLabel={'Back'}
          nextLabel={'Submit a Bid'}
          bid
        />
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="photo-popup">
        <Modal.Header closeButton style={{ filter: 'invert(1)'}}>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedPhoto} alt="Full Size" style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default ViewListing;