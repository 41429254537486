import React, {useEffect, useRef, useState, useContext} from 'react';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import './inventory-dest.css';
import Trash from '../../../assets/lexos_imgs/icons/trash.png';
import {FormContext} from '../context/form_context';
import { abbreviateAddress } from './abbreviate';

const DestinationComponent = ({ destinations, setDestinations }) => {
  const {formData, setFormData, abbreviatedDestinations, inventoryItems, setInventoryItems} = useContext(FormContext);
  const [scrollableAddresses, setScrollableAddresses] = useState([]);

  const destinationRefs = useRef([]);
  const [editingIndex, setEditingIndex] = useState(null);

    // If there are no destinations, add an empty one
  useEffect(() => {

      if (destinations.length === 0) {
      setDestinations(['']);
      }
  }, []);

  // UseEffect to detect scrolls (especially on iOS)
  useEffect(() => {
    const checkOverflow = () => {
      const newScrollableAddresses = destinations.map((_, index) => {
        const el = destinationRefs.current[index];
        return el ? el.scrollWidth > el.clientWidth : false;
      });
      setScrollableAddresses(newScrollableAddresses);
    };
  
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [destinations]);

  // Handle Edit
  const handleEdit = (index) => {
    setEditingIndex(index);
  };
  const handleEditSubmit = (index, newAddress) => {
    const newDestinations = [...destinations];
    newDestinations[index] = newAddress;
    setDestinations(newDestinations);
    updatedDestinationFormData(newDestinations);
    setEditingIndex(null);
  };
  /* ***************************** */

  // Update Destination Form Data
  const updatedDestinationFormData = (newDestinations) => {
    setFormData(prevData => ({
      ...prevData,
      destinations: newDestinations,
      abbreviatedDestinations: newDestinations.map(abbreviateAddress)
    }));
  };

  const handleAddressChange = (index, place) => {
    const newDestinations = [...destinations];
    newDestinations[index] = place.formatted_address;
    setDestinations(newDestinations);
  };

  const addDestination = () => {
    setDestinations([...destinations, '']);
  };


  const updateInventoryDestinations = (inventoryItems, newDestinations, abbreviatedDestinations) => {
    const defaultDestination = abbreviatedDestinations.length > 0 
      ? abbreviatedDestinations[0] 
      : "No destination set";
  
    return Object.entries(inventoryItems).reduce((acc, [room, items]) => {
      acc[room] = items.map(item => {
        if (newDestinations.length === 0 || !newDestinations.includes(item.destination)) {
          return {
            ...item,
            destination: defaultDestination,
            goingToDifferentAddress: false
          };
        }
        return item;
      });
      return acc;
    }, {});
  };
  
  const removeDestination = (index) => {
    const removedDestination = destinations[index];
    const newDestinations = destinations.filter((_, i) => i !== index);
    setDestinations(newDestinations);

  };

  return (
    <div className="destination-component">
    {destinations.map((dest, index) => (
      <div key={index} className="destination-input">
        {!dest || editingIndex === index ? (
          <div className="search-bar">
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              onPlaceSelected={(place) => handleEditSubmit(index, place.formatted_address)}
              options={{
                types: ['address'],
                componentRestrictions: { country: 'us' }
              }}
              className="search-input"
              defaultValue={dest}
              placeholder={index === 0 ? "New home address..." : "New address..."}
            />
            {index !== 0 && (
            <button onClick={() => removeDestination(index)} className="dest-delete-icon">
              <img src={Trash} alt="Delete" />
            </button>
            )}
          </div>
        ) : (
          <div className="selected-address">
            <span ref={(el) => (destinationRefs.current[index] = el)}>{dest}</span>
            <button className = {index === 0 ? "first-edit-icon" : "dest-edit-icon"} onClick={() => handleEdit(index)} >
              <span style={{ 
                  backgroundColor: 'var(--primary-color)', 
                  borderRadius: '50%', 
                  width: '30px', 
                  height: '30px', 
                  marginLeft: '0px',
                  display: 'inline-flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  color: 'white'
                }}>
                ✎
              </span>
            </button>
            {index !== 0 && (
            <button onClick={() => removeDestination(index)} className="dest-delete-icon">
              <img src={Trash} alt="Delete" />
            </button>
            )}
          </div>
        )}

      </div>
    ))}

      <button
        onClick={addDestination}
        className="btn-add-destination"
      >
        + Add Another Destination
      </button>
        <div className = "dest-note-container">
          <div className="note">
            <strong>Note: </strong> Add personal storage units here
          </div>
        </div>
    </div>
  );
};

export default DestinationComponent;