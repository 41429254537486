import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/layout/layout';
import './policy.css';

const Privacy = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // Scroll visibility logic
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    // Termly loading logic
    const loadTermly = () => {
      if (!document.getElementById('termly-jssdk')) {
        const script = document.createElement('script');
        script.id = 'termly-jssdk';
        script.src = 'https://app.termly.io/embed-policy.min.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
          window.Termly?.load?.('c8e9e06c-a3d9-4aff-9c53-15c4711dfefd');
        };
      } else {
        // If the script already exists, manually trigger the Termly embed
        window.Termly?.load?.('c8e9e06c-a3d9-4aff-9c53-15c4711dfefd');
      }
    };

    loadTermly();

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
      const script = document.getElementById('termly-jssdk');
      if (script) {
        script.remove();
      }
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Layout>
      <div className="policy-container">
        <button onClick={handleBack} className="policy-back-button">
          Back
        </button>
        <div className="policy-content">
          <div
            name="termly-embed"
            data-id="3e7aab22-908a-499c-a306-a2b52c75f23d"
          ></div>
        </div>
        {isVisible && (
          <div className="scroll-top" onClick={scrollToTop}>
            ↑
          </div>
        )}
      </div>
    </Layout>
  );
};
export default Privacy;

// data-id="3e7aab22-908a-499c-a306-a2b52c75f23d"