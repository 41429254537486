import React, { useState, useEffect } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import Layout from '../../layout/layout.js';
import './lead_analysis.css';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { ArrowLeft } from 'lucide-react';
import { useAuth } from '../../homeOwner/context/auth_context';


const ViewAnalysis = () => {
    const { moveDetailsId } = useParams();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    
    useEffect(() => {
        const fetchPdfUrl = async () => {
            if (!user) return; 
            try {
                setIsLoading(true);
                // First, find the corresponding offer document
                const db = getFirestore();
                const offersRef = collection(db, 'offers');
                const q = query(offersRef, where('moveDetailsId', '==', moveDetailsId),
                where('movingCompanyId', '==', user.uid) );
                const querySnapshot = await getDocs(q);
                
                if (querySnapshot.empty) {
                    throw new Error('No matching offer found');
                }

                // Get the first matching offer's ID
                const offerId = querySnapshot.docs[0].id;
                // Initialize Firebase Storage
                const storage = getStorage();
                
                // Construct the path based on the moveDetailsId
                const pdfPath = `offer-files/${moveDetailsId}/${offerId}/analysis/lead_analysis_${moveDetailsId}.pdf`;
                const pdfRef = ref(storage, pdfPath);
                
                // Get the download URL
                const url = await getDownloadURL(pdfRef);
                setPdfUrl(url);
                setError(null);
            } catch (err) {
                console.error('Error fetching PDF:', err);
                setError('Coming soon!');
                setPdfUrl(null);
            } finally {
                setIsLoading(false);
            }
        };

        if (moveDetailsId) {
            fetchPdfUrl();
        }
    }, [moveDetailsId]);

    const navigate = useNavigate();

    return (
        <Layout>
            <div className="analysis-container">
            <button 
                onClick={() => navigate(-1)}
                className="submitOffer-back-button"
                >
                <ArrowLeft className="mr-2" size={20} />
                Back
                </button>
                <h1 className="form-title">Lead Analysis Report</h1>
                <div className="pdf-viewer-container">
                    {isLoading ? (
                        <div className="loading-state">
                            <p>Loading analysis report...</p>
                        </div>
                    ) : error ? (
                        <div className="error-state">
                            <p>{error}</p>
                        </div>
                    ) : pdfUrl ? (
                        <div className="pdf-preview">
                            <iframe
                                src={`${pdfUrl}#toolbar=0`}
                                title="Lead Analysis PDF"
                                className="pdf-frame"
                            />
                        </div>
                    ) : (
                        <div className="no-pdf-state">
                            <p>No analysis report available</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};



export default ViewAnalysis;