import React, { useState, useEffect, useRef, useContext } from 'react';
import {Button, Datepicker, Page, setOptions} from '@mobiscroll/react';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout from '../../layout/layout';
import { FormContext } from '../context/form_context';
import { createGlobalStyle } from 'styled-components';
import './destination.css';
import NewHomeImage from '../../../assets/lexos_imgs/destination-insight.png';
import NavigationArrows from '../../layout/nav_arrows';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'bootstrap-datepicker';
import CustomCalendar from './custom_calendar';
import CustomTime from './custom_time';

const Destination = () => {
  const GlobalStyle = createGlobalStyle`
    body {
      font-family: 'Open Sans', sans-serif;
    }
  `;

  const location = useLocation();
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormContext); 
  const [isFormComplete, setIsFormComplete] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(formData.selectedDate || '');
  const [address, setAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false); // New state for submitting status

  useEffect(() => {
    // Check if inventoryItems exist in the location state
    if (location.state && location.state.inventoryItems) {
      // Update the context with the inventoryItems
      setFormData(prevData => ({
        ...prevData,
        inventoryItems: location.state.inventoryItems
      }));
    }
  }, [location.state, setFormData]);

  const datepickerRef = useRef(null);

  useEffect(() => {
    // Initialize the datepicker
    $(datepickerRef.current).datepicker({
      autoclose: true,
      todayHighlight: true,
      todayBtn: "linked",
      title: "Select a Preferred Date",
      templates: {
        leftArrow: '«',
        rightArrow: '»'
      },
      // Set the initial date from formData if it exists
      setDate: formData.selectedDate ? new Date(formData.selectedDate) : null, 
    }).on('changeDate', function (e) {
      handleDateSelect(e.format('mm-dd-yyyy')); // Update this line to handle the date format properly
    });
  
    // If there is already a selected date, set it on the calendar
    if (formData.selectedDate) {
      $(datepickerRef.current).datepicker('setDate', new Date(formData.selectedDate));
    }
  }, [formData.selectedDate]);  // Re-run effect when selectedDate changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleNext = (e) => {
    e.preventDefault();
    setSubmitting(true); // Set submitting status to true

    navigate('/login');
};
  // Lol, update this to go back to the inventory page
  const handleBack = () => {
    navigate('/generate-inventory', { state: { ...location.state, formData } });
  };



  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setFormData({
      ...formData,
      selectedDate: date
    });
    setShowPopup(true);
  };


  const handleDateAndTimeSelect = (date, formattedTime) => {
    setFormData({
      ...formData,
      selectedDate: date,
      preferredTime: formattedTime,
      dateTimeDisplay: formattedTime,
    });
    setSelectedDate(date);
  };

  // formData.presentDuringMove

  useEffect(() => {
    const checkFormCompleteness = () => {
      const isDateTimeSelected = formData.selectedDate && formData.preferredTime;
      const isPresentDuringMoveAnswered = formData.presentDuringMove !== '';
      const isAccessInstructionsProvided = formData.presentDuringMove === 'No' ? !!formData.accessInstructions : true;
      const isSpecificInstructionsAnswered = formData.specificInstructions !== '';
      const isMoverNotesProvided = formData.specificInstructions === 'Yes' ? !!formData.moverNotes : true;

      setIsFormComplete(
        isDateTimeSelected &&
        isPresentDuringMoveAnswered &&
        isAccessInstructionsProvided &&
        isSpecificInstructionsAnswered &&
        isMoverNotesProvided
      );
    };

    checkFormCompleteness();
  }, [formData]);

  return (
    <Layout>
      <GlobalStyle />
      <div className="destination-container">
        <form className="destination-form" onSubmit={handleNext}>
          <h1>Almost There!</h1>
          <img src={NewHomeImage} alt="New Home" className="img-new-home" />

          <label>
            <h2>Preferred Date & Time:</h2>
            <div className="custom-calendar-container">
              <CustomCalendar onDateSelect={handleDateSelect} initialSelectedDate={formData.selectedDate} onTimeSelect={handleDateAndTimeSelect}/>
            </div>
          </label>
          <div className="form-section">
            <label>
              <h3>Will you be present during the move?</h3>
              <div >
                <label className= "radio-option">
                  <input
                    type="radio"
                    name="presentDuringMove"
                    value="Yes"
                    checked={formData.presentDuringMove === 'Yes'}
                    onChange={handleChange}
                    
                  />
                  Yes
                </label>
                <label className= "radio-option">
                  <input
                    type="radio"
                    name="presentDuringMove"
                    value="No"
                    checked={formData.presentDuringMove === 'No'}
                    onChange={handleChange}
                    
                  />
                  No
                </label>
              </div>
            </label>

            {formData.presentDuringMove === 'No' && (
              <label >
                <h3>Instructions for Movers to Access Home:</h3>
                <textarea
                  name="accessInstructions"
                  value={formData.accessInstructions || ''}
                  onChange={handleChange}
                  placeholder="Enter instructions here:"
                />
              </label>
            )}

            <label>
              <h3>Any specific instructions or notes for the movers?</h3>
              <div >
                <label className= "radio-option">
                  <input
                    type="radio"
                    name="specificInstructions"
                    value="Yes"
                    checked={formData.specificInstructions === 'Yes'}
                    onChange={handleChange}
                    
                  />
                  Yes
                </label>
                <label className= "radio-option">
                  <input
                    type="radio"
                    name="specificInstructions"
                    value="No"
                    checked={formData.specificInstructions === 'No'}
                    onChange={handleChange}
                    
                  />
                  No
                </label>
              </div>
            </label>

            {formData.specificInstructions === 'Yes' && (
              <label>
                <h3>Notes for the Movers:</h3>
                <textarea
                  name="moverNotes"
                  value={formData.moverNotes || ''}
                  onChange={handleChange}
                  placeholder="Specific notes for the movers:"
                />
              </label>
            )}
          </div>
          <div className="destination-nav">
            <NavigationArrows
              onPrevClick={handleBack}
              onNextClick={handleNext}
              prevLabel={'Previous'}
              nextLabel={"Review and Submit"} // Update button text based on submitting status
              isNextDisabled={!isFormComplete}
            />
        </div>
        </form>
      </div>
    </Layout>
  );
};

export default Destination;