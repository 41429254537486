import React, { useState, useEffect  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../layout/layout.js';
import { getAuth } from 'firebase/auth';
import './submit_offer.css';
import { useConfirm } from '../../homeOwner/context/alert';
import Trash from '../../../assets/lexos_imgs/icons/trash.png';
import ExtendOffer from '../../../assets/lexos_imgs/offer-extend.png';
import { getFirestore, addDoc, doc, updateDoc, arrayUnion, collection, query, orderBy, getDocs, where, limit, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import NavigationArrows from '../../layout/nav_arrows';
import CurrencyInput from 'react-currency-input-field';
import '../../homeOwner/context/alert.css';
import { IoCompassSharp } from 'react-icons/io5';

const SubmitOffer = () => {
    const { moveDetailsId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [offerSubmitted, setOfferSubmitted] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [minQuoteAmount, setMinQuoteAmount] = useState('');
    const [maxQuoteAmount, setMaxQuoteAmount] = useState('');
    const confirm = useConfirm();
    const [formState, setFormState] = useState({
        pricingModel: '',
        flatRateAmount: '',
        hourlyRate: '',
        rangeMin: '',
        rangeMax: '',
        estimatedTime: '',
        numericTime: '',
        packingMaterials: '',
        packingCostType: '',
        packingFlatRate: '',
        packingRangeMin: '',
        packingRangeMax: '',
        estimateTypes: [],
        insuranceCoverage: '',
        file: null,
    });

    const [totalAmount, setTotalAmount] = useState({
        display: '',
        value: null
      });
    
      useEffect(() => {
        calculateTotalAmount();
      }, [formState]);

      const calculateTotalAmount = () => {
        let baseMin = 0;
        let baseMax = 0;
        
        // Calculate base amount
        switch (formState.pricingModel) {
          case 'flatrate':
            baseMin = baseMax = Number(formState.flatRateAmount) || 0;
            break;
          case 'hourlyrate':
            const rate = Number(formState.hourlyRate) || 0;
            const hours = Number(formState.numericTime) || 0;
            baseMin = baseMax = rate * hours;
            break;
          case 'range':
            baseMin = Number(formState.rangeMin) || 0;
            baseMax = Number(formState.rangeMax) || 0;
            break;
          default:
            break;
        }
    
        // Add packing materials if included
        if (formState.packingMaterials === 'yes') {
          if (formState.packingCostType === 'fixed') {
            const packingCost = Number(formState.packingFlatRate) || 0;
            baseMin += packingCost;
            baseMax += packingCost;
          } else if (formState.packingCostType === 'range' && Number(formState.packingRangeMin) && Number(formState.packingRangeMax)) {
            baseMin += Number(formState.packingRangeMin) || 0;
            baseMax += Number(formState.packingRangeMax) || 0;
          }
        }
    
        const display = baseMin === baseMax ? 
        `$${baseMin.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 
        baseMax > baseMin ? 
        `$${baseMin.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} - $${baseMax.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
        `$${baseMin.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
          
        setTotalAmount({ display, value: baseMin === baseMax ? baseMin : [baseMin, baseMax] });
      };
    
      const handlePricingModelChange = (model) => {
        setFormState(prev => ({
          ...prev,
          pricingModel: model,
          flatRateAmount: '',
          hourlyRate: '',
          rangeMin: '',
          rangeMax: '',
          estimatedTime: '',
          numericTime: ''
        }));
      };
    
      const handleEstimateTypeChange = (type) => {
        setFormState(prev => {
          let newTypes = [...prev.estimateTypes];
          
          if (type === 'binding' || type === 'nonbinding') {
            newTypes = newTypes.filter(t => t !== 'binding' && t !== 'nonbinding');
            if (!newTypes.includes(type)) {
              newTypes.push(type);
            }
          } else {
            const index = newTypes.indexOf(type);
            if (index === -1) {
              newTypes.push(type);
            } else {
              newTypes.splice(index, 1);
            }
          }
          
          return { ...prev, estimateTypes: newTypes };
        });
      };
    

    useEffect(() => {
        validateForm();
    }, [formState]);

    const sendBidConfirm = async(email, moverName) => { 
          try {
            const response = await fetch('https://lexos-backend-547367433733.us-central1.run.app/api/contact/', {  // Update this URL if necessary
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: email,
                fullName: moverName,
                bidConfirmed: true // Flag to indicate that a welcome email should be sent
              }),
            });
          }catch (error) {
            console.error('Error sending user move confirmation email');
          }
        };

    const sendUserOffer = async(userId) => { 

            try {
            const response = await fetch('https://lexos-backend-547367433733.us-central1.run.app/api/contact/', {  // Update this URL if necessary
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                userId: userId,
                userOffer: true // Flag to indicate that a welcome email should be sent
                }),
            });
            }catch (error) {
            console.error('Error sending user move confirmation email');
            }
        };
    

    const validateForm = () => {
        const {
            pricingModel,
            rangeMin,
            rangeMax,
            hourlyRate,
            estimatedTime,
            insuranceCoverage,
            numericTime,
            packingMaterials,
            packingCostType,
            packingFlatRate,
            packingRangeMin,
            packingRangeMax
        } = formState;

        const isValid = 
            pricingModel.length > 0 &&
            (pricingModel.includes('range') ? (rangeMin !== '' && rangeMax !== '') : totalAmount.value !== 0) &&
            (!pricingModel.includes('hourlyrate') ? estimatedTime !== '' : true) &&
            insuranceCoverage !== '' &&
            (packingMaterials === 'yes' && packingCostType === 'fixed' ? packingFlatRate !== '' 
                : packingMaterials === 'yes' && packingCostType === 'range' ? packingRangeMin !== '' && packingRangeMax !== ''
                : true) &&
            (pricingModel.includes('hourlyrate') ? hourlyRate !== '' && numericTime !== '' : true);

        setIsFormValid(isValid);
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (name === 'pricingModels') {
            let updatedModels;
            if (checked) {
                // Add the new value
                updatedModels = formState.pricingModel
                    ? formState.pricingModel.split(', ').concat(value)
                    : [value];
            } else {
                // Remove the value
                updatedModels = formState.pricingModel
                    .split(', ')
                    .filter(model => model !== value);
            }
            // Join the array, ensuring no empty strings
            const joinedModels = updatedModels.filter(Boolean).join(', ');
            setFormState(prev => ({ ...prev, pricingModel: joinedModels }));
        } else {
            setFormState(prev => ({
                ...prev,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    // ### - ####
    useEffect(() => {
        if(minQuoteAmount || maxQuoteAmount){
            setFormState(prev => ({
                ...prev,
                quoteAmount: minQuoteAmount + ' - ' + maxQuoteAmount
            }))
        }

    }, [minQuoteAmount, maxQuoteAmount])


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFormState(prev => ({ ...prev, file }));
        
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewUrl(null);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const auth = getAuth();
        const user = auth.currentUser;
    
        if (!user) {
            alert('You must be logged in to submit an offer.');
            return;
        }
        try {
            const isConfirmed = await confirm(
                "If a user accepts your offer, you'll be charged 10% of the quote and receive their contact information. You are responsible for securing any required parking permits for the move day at the provided address(es).",
                "Yes",
                "No",
                { title: "Confirm Offer Submission" }
            );

            if (isConfirmed) {
                setIsSubmitting(true);
                const db = getFirestore();
                const storage = getStorage();

                let fileUrl = null;
                if (formState.file) {
                    const storageRef = ref(storage, `offer-files/${user.uid}/${formState.file.name}`);
                    await uploadBytes(storageRef, formState.file);
                    fileUrl = await getDownloadURL(storageRef);
                }
                const nameQuery = query(collection(db, 'movingCompanies'), where('userId', '==', user.uid));
                const querySnapshot = await getDocs(nameQuery);
                const moverName = querySnapshot.docs[0].data().companyName;
                const moverEmail = querySnapshot.docs[0].data().email;

                // Create the offer document
                const offerData = {
                    ...formState,
                    displayQuote: totalAmount.display,
                    quoteValue: totalAmount.value,
                    offerId: `${user.uid.substring(0,4)}-${moveDetailsId}`,
                    moveDetailsId: moveDetailsId,
                    movingCompany: moverName,
                    movingCompanyId: user.uid,
                    timestamp: new Date(),
                    fileUrl: fileUrl, // Add the download URL instead of the File object
                    status: 'pending' // You can use this to track offer status
                };
                
                // Remove the file property as it's not needed in Firestore
                delete offerData.file;
                
                const offerRef = await addDoc(collection(db, 'offers'), offerData);

                // Update moving details document to include this offer
                const moveDetailRef = doc(db, 'moveDetails', moveDetailsId);
                const moveDetailDoc = await getDoc(moveDetailRef);
                const relevantUserId =  moveDetailDoc.data().userId;
                await updateDoc(moveDetailRef, {
                    offers: arrayUnion(offerRef.id)
                });             

                // Update the moving company's document to track their offers
                const movingCompanyRef = doc(db, 'movingCompanies', user.uid);
                await updateDoc(movingCompanyRef, {
                    submittedOffers: arrayUnion(offerRef.id)
                });

                alert('Offer submitted successfully!');
                // sendBidConfirm(moverEmail, moverName);
                sendUserOffer(relevantUserId);
                navigate('/movers-dashboard'); // Redirect after successful submission
            }
        } catch (error) {
            console.error("Error submitting offer:", error);
            alert('Failed to submit offer. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };


    const handleFileDelete = () => {
        setFormState(prev => ({ ...prev, file: null }));
        setPreviewUrl(null);
    };

    const navigate = useNavigate();


    return (
        <Layout>
            <div className="submitOffer-container">
                <h1 className="form-title">Submit Your Offer</h1>
                <img src={ExtendOffer} alt="Extend Offer" className="extend-offer-img" />
                <form className="offer-form" onSubmit={handleSubmit}>
                    <div>
                        <h4><strong>Select Pricing Models:</strong></h4>
                        <div className="pricing-models-grid">
                        {['Flat Rate', 'Hourly Rate', 'Range'].map((model) => (
                            <label key={model} className="pricing-model-checkbox">
                            <input
                                type="checkbox"
                                name="pricingModel"
                                value={model.toLowerCase().replace(' ', '')}
                                checked={formState.pricingModel === model.toLowerCase().replace(' ', '')}
                                onChange={() => handlePricingModelChange(model.toLowerCase().replace(' ', ''))}
                            />
                            {model}
                            </label>
                        ))}
                        </div>
                    </div>

                    {formState.pricingModel && (
                        <div className="quote-section">
                        {formState.pricingModel === 'flatrate' && (
                            <>
                            <h4><strong>Flat Rate Amount:</strong></h4>
                            <CurrencyInput
                                id="flatRateAmount"
                                name="flatRateAmount"
                                placeholder="$ Enter flat rate amount"
                                prefix="$"
                                value={formState.flatRateAmount}
                                onValueChange={(value) => 
                                setFormState(prev => ({ ...prev, flatRateAmount: value || '' }))}
                            />
                            </>
                        )}

                        {formState.pricingModel === 'hourlyrate' && (
                            <>
                            <h4><strong>Hourly Rate:</strong></h4>
                            <CurrencyInput
                                id="hourlyRate"
                                name="hourlyRate"
                                placeholder="$ Enter hourly rate"
                                prefix="$"
                                suffix="/hour"
                                value={formState.hourlyRate}
                                onValueChange={(value) => 
                                setFormState(prev => ({ ...prev, hourlyRate: value || '' }))}
                            />
                            <h4><strong>Estimated Hours:</strong></h4>
                            <input
                                type="number"
                                value={formState.numericTime}
                                onChange={(e) => 
                                setFormState(prev => ({ ...prev, numericTime: e.target.value }))}
                                placeholder="Enter number of hours"
                            />
                            </>
                        )}

                        {formState.pricingModel === 'range' && (
                            <>
                            <h4><strong>Range Amounts:</strong></h4>
                            <div className="quote-input">
                                
                            <CurrencyInput
                                id="rangeMin"
                                name="rangeMin"
                                placeholder="$ Minimum"
                                prefix="$"
                                value={formState.rangeMin}
                                onValueChange={(value) => 
                                setFormState(prev => ({ ...prev, rangeMin: value || '' }))}
                            />
                            <h2>-</h2>
                            <CurrencyInput
                                id="rangeMax"
                                name="rangeMax"
                                placeholder="$ Maximum"
                                prefix="$"
                                value={formState.rangeMax}
                                onValueChange={(value) => 
                                setFormState(prev => ({ ...prev, rangeMax: value || '' }))}
                            />
                            </div>
                            </>
                        )}

                        {formState.pricingModel !== 'hourlyrate' && (
                            <>
                            <h4 htmlFor="estimatedHours"><strong>Estimated Time:</strong></h4>
                            <input
                                type="text"
                                value={formState.estimatedTime}
                                onChange={(e) => 
                                setFormState(prev => ({ ...prev, estimatedTime: e.target.value }))}
                                placeholder="Enter estimated duration of move"
                                name="estimatedHours"
                            />
                            </>
                        )}
                        </div>
                    )}
                    <h4><strong>Packing Materials Included?</strong></h4>
                        <div className="packing-mats-options">
                        {['Yes', 'No'].map((option) => (
                        <label key={option} className="extra-no-padding">
                            <input
                            type="radio"
                            name="packingMaterials"
                            value={option.toLowerCase()}
                            checked={formState.packingMaterials === option.toLowerCase()}
                            onChange={(e) => 
                                setFormState(prev => ({ ...prev, packingMaterials: e.target.value }))}
                            />
                            {option}
                        </label>
                        ))}
                        </div>
                    {formState.packingMaterials === 'yes' && (
                        <>
                            <div className="packing-mats-options">
                            {['Fixed', 'Range'].map((type) => (
                            <label key={type} >
                                <input
                                type="radio"
                                name="packingCostType"
                                value={type.toLowerCase()}
                                checked={formState.packingCostType === type.toLowerCase()}
                                onChange={(e) => 
                                    setFormState(prev => ({ ...prev, packingCostType: e.target.value }))}
                                />
                                {type}
                            </label>
                            ))}
                            </div>

                        {formState.packingCostType === 'fixed' && (
                            <CurrencyInput
                            id="packingFlatRate"
                            name="packingFlatRate"
                            placeholder="$ Enter packing materials cost"
                            prefix="$"
                            value={formState.packingFlatRate}
                            onValueChange={(value) => 
                                setFormState(prev => ({ ...prev, packingRangeMin: '', packingRageMax: '', packingFlatRate: value || '' }))}
                            />
                        )}

                        {formState.packingCostType === 'range' && (
                            <div className="quote-input">
                            <CurrencyInput
                                id="packingRangeMin"
                                name="packingRangeMin"
                                placeholder="$ Minimum"
                                prefix="$"
                                value={formState.packingRangeMin}
                                onValueChange={(value) => 
                                setFormState(prev => ({ ...prev, packingFlatRate: '', packingRangeMin: value || '' }))}
                            />
                            <h2>-</h2>
                            <CurrencyInput
                                id="packingRangeMax"
                                name="packingRangeMax"
                                placeholder="$ Maximum"
                                prefix="$"
                                value={formState.packingRangeMax}
                                onValueChange={(value) => 
                                setFormState(prev => ({ ...prev, packingFlatRate: '', packingRangeMax: value || '' }))}
                            />
                            </div>
                        )}
                        </>
                    )}

                    <h4 className="total-quote-heading"><strong>Total Quoted Amount:</strong></h4>
                    <div className="quote-display">
                        {totalAmount.display}
                    </div>

                    <h4><strong>Type of Estimate:</strong></h4>
                    <div className="estimate-types-grid">
                        {[
                        'Weight-based',
                        'Distance-based',
                        'Binding',
                        'Non-binding',
                        'Guaranteed Not-to-exceed'
                        ].map((type) => (
                        <label key={type} className="estimate-type-checkbox">
                            <input
                            type="checkbox"
                            checked={formState.estimateTypes.includes(type.toLowerCase().replace(/-/g, ''))}
                            onChange={() => handleEstimateTypeChange(type.toLowerCase().replace(/-/g, ''))}
                            />
                            {type}
                        </label>
                        ))}
                    </div>

                    <h4><strong>Are you providing insurance coverage?</strong></h4>
                    <div className = "packing-mats-options">
                    <label>
                        <input
                            type="radio"
                            name="insuranceCoverage"
                            value="yes"
                            checked={formState.insuranceCoverage === 'yes'}
                            onChange={handleChange}
                        />
                        Yes
                    </label>
                    <label  className="extra-no-padding">
                        <input
                            type="radio"
                            name="insuranceCoverage"
                            value="no"
                            checked={formState.insuranceCoverage === 'no'}
                            onChange={handleChange}
                        />
                        No
                    </label>
                    </div>

                    <div className="file-upload-container">
                        <input
                            type="file"
                            id="fileUpload"
                            name="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="fileUpload" className="file-upload-label">
                            {formState.file ? 'Change File' : 'Upload Cost Breakdown File'}
                        </label>
                        <p className="file-upload-description">Your customer will see any attachments you'd like to attach here</p>
                        {previewUrl && (
                            <div className="file-preview">
                                <h4><strong>File Preview:</strong></h4>
                                {formState.file.type.startsWith('image/') ? (
                                    <img src={previewUrl} alt="File preview" className="preview-image" />
                                ) : (
                                    <iframe src={previewUrl} title="File preview" className="preview-frame" />
                                )}
                            <button type="button" onClick={handleFileDelete} className="file-delete-button">
                            <img src={Trash} alt="Delete" style={{width: '30px', height: '30px'}} />
                        </button>
                        </div>
                        )}
                    </div>

                    <div className="buttons-container">
                    <NavigationArrows
                        onPrevClick={() => navigate('/movers-dashboard')}
                        onNextClick={handleSubmit}
                        prevLabel={'Back'}
                        nextLabel={isSubmitting ? 'Submitting... ': 'Submit Offer'}
                        isNextDisabled={!isFormValid || isSubmitting}
                        bid
                    />
                    </div>
                </form>
            </div>
        </Layout>
    );
};


export default SubmitOffer;