import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import CopyComponent from '../../layout/copy';
import WhiteIcon from '../../../assets/lexos_imgs/logos/white_icon_only.png';
import { ReactComponent as CursorIcon } from  '../../../assets/lexos_imgs/logos/Black_icon_only.svg';
import { FaArrowDown  } from "react-icons/fa";

const breakpoints = {
  xLarge: '773px',
  large: '572px'
};
// Create a media query helper
const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
/* ***Logo Spinning Animation*** 
* Keyframes
* SpinningLogo
*
*/
const ScrollArrowContainer = styled.div`
  bottom: 30px; 
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
  width: 100%;
  z-index: 1000;
`;
const StyledArrowButton = styled.div`
  background-color: var(--primary-color);
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  margin: 0 auto;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  &:hover {
    opacity: 0.8;
  }
`;
export const ScrollArrow = ({ onClick }) => {
  return (
    <ScrollArrowContainer>
    <StyledArrowButton onClick={onClick}>
      <FaArrowDown/>
    </StyledArrowButton>
    </ScrollArrowContainer>
  );
};
const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

export const TypingContainer = styled.div`
  position: relative;
  display: inline-block;
`;
export const TypingCursor = styled(CursorIcon)`
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: ${blink} 0.7s infinite;
  vertical-align: middle;
  margin-left: 2px;

  path {
    fill: #8b50f7;
  }

  &.waiting-cursor {
    animation: blink 0.7s infinite;
  }

  @media (max-width: 426px) {
    animation: ${blink} 0.7s infinite;
  }
`;
// export const TypingCursor = styled.div `
//   position: relative;
//   display: inline-block;
//   width: 12px;
//   height: 12px;
//   background-image: url(${WhiteIcon});
//   background-size: contain;
//   background-repeat: no-repeat;
//   animation: ${blink} 0.7s infinite;
//   filter: brightness(0) saturate(100%) invert(34%) sepia(77%) saturate(1422%) hue-rotate(233deg) brightness(94%) contrast(93%);
//   vertical-align: middle;
//   margin-left: 2px;
//   &.waiting-cursor {
//   animation: blink 0.7s infinite;
// }

// @media(max-width: 426px){
//   nimation: ${blink} 0.7s infinite;
//   background-image: url(${BlackIcon});
//   // filter: invert(29%) sepia(59%) saturate(2106%) hue-rotate(242deg) brightness(107%) contrast(94%);
// }
// `;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;



/* ********** Inventory Content Styles ********** 
* 
* 
* 
* 
*/

export const InventoryTitle = styled.h1`
  color: #fff;
  text-align: center;
  margin-top: 50px;
`;
export const InventoryCheckbox = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
padding-top: 10px;
color: #8b50f7;

input[type='checkbox'] {
  margin-bottom: 10px;
}
`;
export const StyledInventoryBox = styled.div`
  padding: 20px;
  border: 1px solid #8b50f7;
  border-radius: 10px;
  background-color: #f8f8ff;
  margin: 20px auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh); // Adjust back to 200px value if needed
  overflow-y: auto;


  ${media.xLarge`
  width: 95%;
  padding: 10px;
  `}
  @media(max-width:425px){
    width: 100%;
  }

`;


export const CopyButtonWrapper = styled.div`
  position: absolute;
  right: 5px; // Align with the right padding of ButtonContainer
  top: 50%;
  transform: translateY(-50%);
`;

export const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 40% 30% 30%; 
  font-weight: bold;
  color: #8b50f7;
  padding-bottom: 15px;
  border-bottom: 1px solid #8b50f7;
  margin-bottom: 20px;
  text-align: center;
  font-size: 30px;
  font-family: 'Montserrat-ExtraBold', sans-serif;

  ${media.xLarge`
  font-size: 24px;
`}
  ${media.large`
  font-size: 12px;
`}
`;
export const ContentArea = styled.div`
  min-height: 100%;
  max-height: calc(70vh - 100px);
  overflow-y: auto;
  padding-right: 5px;
`;
export const ScrollableRows = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 40% 30% 30%; 
  border-bottom: 2px solid #8b50f7;
  padding: 10px 0;
  color: #8b50f7;

  @media(max-width: 920px){
    
  }
`;
export const ItemColumn = styled.div`
  padding-right: 20px;
`;

export const RoomTitleRow = styled(ItemRow)`
  background-color: #f0f0ff;
  font-weight: bold;
`;

export const RoomTitle = styled.h2`
  color: #8b50f7;
`;


export const Column = styled.div`
  padding-right: 20px;
`;

export const ServiceColumn = styled.div`
  border-left: 2px solid #8b50f7;
  padding-left: 20px;
  display: flex;
  // justify-content: center; /* Center vertically */
  flex-direction: column;
 
  @media(max-width: 965px){
    padding-left: 10px;
  }
`;

export const HandledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  border-left: 2px solid #8b50f7;
  padding-left: 20px;

  @media(max-width: 965px){
    padding-left: 10px;
  }
`;

export const ScrollableContent = styled.div`
  overflow-y: auto;
`;

export const InventoryContent = styled.div`
  color: #4A4860;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;

  width: 107%;

  strong {
    display: block;
    margin-top: 10px;
  }

  @media(max-width: 879px){
    .item-details {
      margin-left: 10px;
    }
  }
  @media(max-width: 751px){
    font-size: 12px;
    width: 110%;

    .item-details {
      margin-left: 0px;
    }
  }
  @media(max-width: 321px){
    width: 116%;
  }
`;



export const EditButton = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #8b50f7;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: #7226f5;
  }

  .edit-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  @media(max-width: 690px){
    font-size: 12px;
    width: 100px;
    height: 35px;
  }
    
  @media(max-width: 515px){
    font-size: 12px;
    width: 60px;
    height: 35px;
  }
  @media(max-width: 351px){
    font-size: 12px;
    width: 30px;
    height: 30px;
  }
`;

export const EditButtonText = styled.div`
  &::after {
    content: '${props => props.isEditing ? 'Done' : 'Edit My Inventory'}';
  }

  @media (max-width: 515px) {
    &::after {
      content: '${props => props.isEditing ? 'Done' : 'Edit'}';
    }
  }
      @media (max-width: 351px) {
    &::after {
      content: '${props => props.isEditing ? 'Done' : ''}';
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
  width: 40%;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 10px;
  word-wrap: break-word;
  overflow-wrap: break-word;

  @media(max-width: 500px){
    margin-left: -5px;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #4A4860;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  max-width: 100%;
  
  
  @media (max-width: 639px) {
    font-size: 12px;
  }
  
`;
export const StyledCheckbox = styled.input`
  -webkit-appearance: none;
  appearance: none;
  min-width: 18px;
  width: 18px;
  height: 18px;
  border: 2px solid #8b50f7;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  flex-shrink: 0;

  &:checked {
    background-color: #8b50f7;
    
    &::after {
      content: '✓';
      font-size: 12px;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const CheckboxText = styled.span`
  flex: 1;
  word-break: break-word;
  @media(max-width: 425px){
    margin-left: -5px;
  }
`;
export const SubCheckbox = styled(CheckboxLabel)`
  margin-left: 30px;
  font-size: 12px;

  @media(max-width: 500px){
    margin-left: 0px;
  }

`;

export const EstimatedCostInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  left: 30px;

  @media(max-width: 683px){
    left: 5px;
  }
`;

export const DollarSign = styled.span`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #4A4860;
`;

export const EstimatedCostInput = styled.input`
  padding: 10px 0px 10px 17.5px; /* Adjust padding to account for the dollar sign */
  border: 3px solid #8b50f7;
  border-radius: 8px;
  font-size: 14px;
  width: 150px;
  color: #4A4860;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(139, 80, 247, 0.3);
  }
  
  &::placeholder {
    color: #aaa;
  }

  @media(max-width: 660px){
    width: 100px;
    font-size: 12px;
  }

  @media(max-width: 391px){
    width: 80px;
  }
`;


/* ********** Accordion Styles **********
*
*
*
*
*/
export const AccordionSection = styled.div`
  width: 80%;
  margin: 20px auto;
`;

export const AccordionItem = styled.div`
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const AccordionButton = styled.button`
  background-color: #fff;
  color: #8b50f7;
  border: none;
  width: 100%;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  &:focus {
    background-color: #e0d4fd;
    box-shadow: 0 0 0 0.25px #8b50f7;
  }
`;

export const AccordionContent = styled.div`
  padding: 10px;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;


/* ********** File Input Button Styles **********
*
*
*
*
*/
export const FileInputButton = styled.label`
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 12px;
  font-weight: bold;
  color: #8b50f7;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  outline: none;

  &:hover {
    background-color: #7226f5;
  }

  & input {
    display: none;
  }

  & svg {
    margin-right: 10px;
  }
`;

export const AnimatedItemRow = styled(ItemRow)`
  animation: ${fadeIn} 0.5s ease-in;
`;

export const AnimatedCheckbox = styled(StyledCheckbox)`
  animation: ${fadeIn} 0.5s ease-in;
`;

export const RoomTitleWithCursor = styled.div`
  display: flex;
  align-items: center;
`;

export const RoomTitleText = styled.h1`
  margin-right: 2px;
  color: #8b50f7;

  @media(max-width: 880px){
    font-size: 30px;
  }
  @media(max-width: 445px){
    font-size: 18px;
  }
`;