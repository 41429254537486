import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth, db } from '../../../firebase/fireconfig';
import { doc, getDoc } from 'firebase/firestore';

const MovingCompanyContext = createContext();

export const useMovingCompanyAuth = () => useContext(MovingCompanyContext);

export const MovingCompanyProvider = ({ children }) => {
  const [isMovingCompany, setIsMovingCompany] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const companyDoc = await getDoc(doc(db, 'movingCompanies', user.uid));
          setIsMovingCompany(companyDoc.exists());
        } catch (error) {
          console.error('Error checking moving company status:', error);
          setIsMovingCompany(false);
        }
      } else {
        setIsMovingCompany(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const value = {
    isMovingCompany,
    loading
  };

  return (
    <MovingCompanyContext.Provider value={value}>
      {children}
    </MovingCompanyContext.Provider>
  );
};
