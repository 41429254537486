// LoadingScreen.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: ${fadeIn} 0.3s ease-out;
`;

const SpinnerWrapper = styled.div`
  text-align: center;
`;

const pulse = keyframes`
  0% { transform: rotate(0deg); box-shadow: 0 0 0 white; }
  50% { box-shadow: 0 0 20px white; }
  100% { transform: rotate(360deg); box-shadow: 0 0 0 white; }
`;

const Spinner = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
  border-bottom: 3px solid rgba(139, 80, 247, 0.2);
  animation: ${pulse} 1.5s linear infinite;
  margin: 0 auto 1rem;
`;

const LoadingText = styled.p`
  color: white;
  font-size: 1.1rem;
  margin-top: 10px;
  font-weight: 300;
`;

const LoadingScreen = () => (
  <LoadingOverlay>
    <SpinnerWrapper>
      <Spinner />
      <LoadingText>Loading...</LoadingText>
    </SpinnerWrapper>
  </LoadingOverlay>
);


export default LoadingScreen;