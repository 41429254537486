import React, {useState, useCallback} from 'react';
import './company_profile.css';
import Layout from '../../layout/layout';
import PinkZebra from '../../../assets/lexos_imgs/movingCos/pink-zebra.png';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationArrows from '../../layout/nav_arrows';

const PinkZebraProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [originalFrom, setOriginalFrom] = useState(() => {
    return location.state?.originalFrom || location.state?.from?.pathname || '/';
  });

  const leaveReview = () => {
    // URL that should bring the user closer to the review section
    const mapsReviewUrl = 'https://www.google.com/maps/place/Pink+Zebra+Moving/@42.3574687,-71.1321515,17z/data=!3m1!4b1!4m6!3m5!1s0x89e379f5de964ba3:0x74996e2a9955154!8m2!3d42.3574648!4d-71.1295766!16s%2Fg%2F11ldd0mf8p?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D';
  
    window.open(mapsReviewUrl, '_blank', 'noopener,noreferrer');
  };

  // Use this function for navigation after viewing company profile
  const navigateToOriginalDestination = useCallback(() => {
    navigate(originalFrom, { replace: true });
  }, [navigate, originalFrom]);

  return (
    <Layout>
      <div className="company-profile-container">
      <div className="property-info-nav">
            <NavigationArrows
              onPrevClick={navigateToOriginalDestination}
              prevLabel={'Back'}
              inverted
            />
        </div>
        <div className="company-header">
          <div className="company-info">
            <div className="company-img-placeholder">
              <img src={PinkZebra} alt="Company Logo" className="zeke" />
            </div>
            <div className="company-details">
              <h2 className="company-name">Pink Zebra Moving</h2>
              <div className="company-rating">
                <span className="rating-text">Rating: 4.9</span>
                <span className="stars">
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                </span>
                <span className="review-count">(59 reviews)</span>
              </div>
              <button onClick={leaveReview} className="share-btn">Share</button>
            </div>
          </div>
        </div>

        <div className="company-introduction">
          <h3>Introduction</h3>
          <p>
          Pink Zebra Moving of Boston is not your typical moving company. Whether moving a commercial office or a residential home, we go above and beyond to offer you the greatest experience possible. We recognize that relocating can be stressful, and we want to remove as much of that worry as possible. Our team of movers is committed to giving the best possible customer service, and you will not be dissatisfied.
          </p>
        </div>

        <div className="company-overview">
          <div className="overview-section">
            <h4>Overview</h4>
            <ul>
              <li>Serving all of Massachusetts and offering interstate moving services.</li>
              <li>Background checked</li>
              <li>20+ years serving Boston</li>
            </ul>
          </div>

          <div className="overview-section">
            <h4>Contact Information</h4>
            <ul>
            Contact with the moving company will begin once you hit 'Accept' on your preferred offer in the Offers Dashboard. The company will then reach out to confirm details and proceed with your move.
            </ul>
          </div>

          <div className="overview-section">
            <h4>Payment Methods</h4>
            <p>
            This LexPro accepts payments via Cash, Check, Credit card, and ACH.
            </p>
          </div>

          <div className="overview-section">
            <h4>LexPro Gold Status</h4>
            <p>
              LexPro Gold's are among the highest-rated, most popular professionals on Lexos.
            </p>
          </div>
        </div>

        <div className="business-hours">
          <h4>Business Hours</h4>
          <table>
            <tbody>

              <tr>
                <td>Mon</td>
                <td>7:30 AM - 5:00 PM</td>
              </tr>
              {/* Add more days as needed */}
              <tr>
                <td>Tue</td>
                <td>7:30 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>7:30 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>7:30 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>7:30 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>7:30 AM - 3:00 PM</td>
              </tr>
              <tr>
                <td>Sun</td>
                <td>Closed</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="company-reviews">
          <h3>Reviews</h3>
          <section className="reviews-section">
            <div className="row text-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center mb-4 ">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "We had a great experience with Pink Zebra Moving Co. Nick, Jayden and Jordan were incredibly friendly and professional. They all made us feel so comfortable and supported with the move and they made this an easy transition to our new place"
                </p>
                <p className="font-italic font-weight-normal mb-0">-Lisa Barone</p>
              </div>
              <div className="col-md-6 mb-0">
                <div className="d-flex justify-content-center mb-4">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  " I wanted to take a moment to express my deepest appreciation for the exceptional service you provided during my recent move. My move team Nick and Travis had very awesome professionalism, efficiency, and care made the entire process smooth and stress-free."
                </p>
                <p className="font-italic font-weight-normal mb-0">-Ebony Dominic </p>
              </div>
            </div>
          </section>
        </div>
      </div>

    </Layout>
  );
};

export default PinkZebraProfile;
