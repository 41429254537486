import React, {useState, useCallback} from 'react';
import './company_profile.css';
import Layout from '../../layout/layout';
import SRM from '../../../assets/lexos_imgs/movingCos/ove_mate.jpg';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationArrows from '../../layout/nav_arrows';

const MoveMateProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeChange = () => {
    const url = "https://www.google.com/search?q=move+mate+boston+moving+company+reviews&sca_esv=7efca204112e8f07&rlz=1C1CHBF_enUS924US924&sxsrf=ADLYWIIGwEaMs0-i6PqpeuiiRleKFBwEJQ%3A1728884259687&ei=I64MZ8LeKaCV5OMPj_7Q-A8&ved=0ahUKEwiC_J_hk42JAxWgCnkGHQ8_FP8Q4dUDCBA&uact=5&oq=move+mate+boston+moving+company+reviews&gs_lp=Egxnd3Mtd2l6LXNlcnAiJ21vdmUgbWF0ZSBib3N0b24gbW92aW5nIGNvbXBhbnkgcmV2aWV3czIIEAAYgAQYogQyCBAAGIAEGKIEMggQABiABBiiBDIIEAAYgAQYogRIxRtQ1ANY8RlwA3gAkAEAmAFgoAG2C6oBAjE3uAEDyAEA-AEBmAIPoAKZCMICCBAAGIAEGLADwgIOEAAYgAQYsAMYhgMYigXCAgsQABiABBiwAxiiBMICBRAAGIAEwgILEAAYgAQYhgMYigXCAggQIRigARjDBMICBRAhGKsCwgIKECEYoAEYwwQYCpgDAIgGAZAGCJIHBDE0LjGgB7Uz&sclient=gws-wiz-serp#lrd=0x89e37be15b9c78c3:0x899eae7877d8b316,1,,,,";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // https://www.google.com/maps/place/Move+Mate/@42.3404108,-71.0500666,17z/data=!3m1!4b1!4m6!3m5!1s0x89e37be15b9c78c3:0x899eae7877d8b316!8m2!3d42.3404069!4d-71.0474917!16s%2Fg%2F11vypcc83k?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D
  const leaveReview = () => {
    // URL that should bring the user closer to the review section
    const mapsReviewUrl = 'https://www.google.com/maps/place/Move+Mate/@42.3404108,-71.0500666,17z/data=!3m1!4b1!4m6!3m5!1s0x89e37be15b9c78c3:0x899eae7877d8b316!8m2!3d42.3404069!4d-71.0474917!16s%2Fg%2F11vypcc83k?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D';
  
    window.open(mapsReviewUrl, '_blank', 'noopener,noreferrer');
  };

  const [originalFrom, setOriginalFrom] = useState(() => {
    return location.state?.originalFrom || location.state?.from?.pathname || '/';
  });

  // Use this function for navigation after viewing company profile
  const navigateToOriginalDestination = useCallback(() => {
    navigate(originalFrom, { replace: true });
  }, [navigate, originalFrom]);

  return (
    <Layout>
      <div className="company-profile-container">
      <div className="property-info-nav">
            <NavigationArrows
              onPrevClick={navigateToOriginalDestination}
              prevLabel={'Back'}
              inverted
            />
        </div>
        <div className="company-header">
          <div className="company-info">
            <div className="company-img-placeholder">
              <img src={SRM} alt="Company Logo" className="zeke" />
            </div>
            <div className="company-details">
              <h2 className="company-name">Move Mate Boston</h2>
              <div className="company-rating">
                <span className="rating-text">Rating: 4.9</span>
                <span className="stars">
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                </span>
                <button onClick={routeChange} className="review-count">(48 reviews)</button>
              </div>
              <button onClick={leaveReview} className="share-btn">Share</button>
            </div>
          </div>
        </div>

        <div className="company-introduction">
          <h3>Introduction</h3>
          <p>
          Sit back and let our well-versed movers take care of all. The heavy lifting while you embrace your ideal moving experience. Enjoy a stress – free home relocation with the professional help of our courteous residential moving veterans.
          </p>
        </div>

        <div className="company-overview">
          <div className="overview-section">
            <h4>Overview</h4>
            <ul>
              <li>Serving all of Massachusetts and offering interstate moving services.</li>
              <li>Background checked</li>
              <li>5+ years of moving in the Boston area</li>
            </ul>
          </div>

          <div className="overview-section">
            <h4>Contact Information</h4>
            <ul>
            Contact with the moving company will begin once you hit 'Accept' on your preferred offer in the Offers Dashboard. The company will then reach out to confirm details and proceed with your move.
            </ul>
          </div>

          <div className="overview-section">
            <h4>Payment Methods</h4>
            <p>
            This LexPro accepts payments via Apple Pay, Cash, Credit card, Cash App, Venmo, and Zelle.
            </p>
          </div>

          <div className="overview-section">
            <h4>LexPro Gold Status</h4>
            <p>
              LexPro Gold's are among the highest-rated, most popular professionals on Lexos.
            </p>
          </div>
        </div>

        <div className="business-hours">
          <h4>Business Hours</h4>
          <table>
            <tbody>
              <tr>
                <td>Mon</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              {/* Add more days as needed */}
              <tr>
                <td>Tue</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Sun</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="company-reviews">
          <h3>Reviews</h3>
          <section className="reviews-section">
            <div className="row text-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center mb-4 ">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "Zach and his crew were great. Professional, helpful, and really careful with our stuff and the building hallways. They were very communicative and clear. It was a pleasure working with them and will be hiring Move Mate the next time we move.
                </p>
                <p className="font-italic font-weight-normal mb-0">-Bereke Tubekbay</p>
              </div>
              <div className="col-md-6 mb-0">
                <div className="d-flex justify-content-center mb-4">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "I’ve recently used Move Mate and can’t say enough how happy I am. Zack did a great job coordinating the whole moving process and it wasn’t stressful at all. Thank you a lot!"
                </p>
                <p className="font-italic font-weight-normal mb-0">- Dana Bektemirova</p>
              </div>
            </div>
          </section>
        </div>
      </div>

    </Layout>
  );
};

export default MoveMateProfile;
