import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout from '../../layout/layout';
import './begin_tour.css';
import Photography from '../../../assets/lexos_imgs/person-photo-taking.png';
import NavigationArrows from '../../layout/nav_arrows';
import Manual from '../../../assets/lexos_imgs/icons/manual.png';
import Bad from '../../../assets/eg_bad.png';
import Good1 from '../../../assets/eg_good1.png';
import Good2 from '../../../assets/eg_good2.png';
import {Check, X} from 'lucide-react';

const BeginTour = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('good');

  const handleNext = () => {
    navigate('/virtual-walkthrough', { state: location.state });
  };

  const handleBack = () => {
    navigate('/property-info', { 
      state: { ...location.state, step: 'access' },
      replace: true
    });
  };

  const PhotoExampleDialog = () => (
    isDialogOpen && (
      <div className="dialog-overlay">
      <div className="dialog-content">
        <button 
          className="close-button"
          onClick={() => setIsDialogOpen(false)}
        >
          <p className="close-button-text"><u >Close</u></p>
        </button>

        <div className="dialog-header">
          <h2>Photo Taking Examples</h2>
        </div>
        
        <div className="example-tabs">
          <div className="tab-list">
            <button 
              className={`tab-button ${activeTab === 'good' ? 'active' : ''}`}
              onClick={() => setActiveTab('good')}
            >
              <Check 
                size={20} 
                className="tab-icon good"
              />
              Good Examples
            </button>
            <button 
              className={`tab-button ${activeTab === 'bad' ? 'active' : ''}`}
              onClick={() => setActiveTab('bad')}
            >
              <X 
                size={20} 
                className="tab-icon bad"
              />
              Bad Example
            </button>
          </div>

          <div className="begin-tour-tab-content">
            {activeTab === 'good' && (
              <div className="good-examples">
                <div className="example-item">
                  <img 
                    src={Good1}
                    alt="Good example 1" 
                  />
                  <p>
                    Clear, well-lit photo of a single piece of furniture from a good angle
                  </p>
                </div>
                <div className="example-item">
                  <img 
                    src={Good2}
                    alt="Good example 2"
                  />
                  <p>
                    Close-up shot showing details and condition of the item
                  </p>
                </div>
              </div>
            )}
            {activeTab === 'bad' && (
              <div className="bad-examples">
                <div className="example-item">
                  <img 
                    src={Bad}
                    alt="Bad example"
                  />
                  <p>
                    Avoid taking photos of multiple items at once or in poor lighting conditions
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    )
  );

  return (
    <Layout>
      <div className="begin-tour-container">
        <h1>Virtual Walkthrough Guide <img src={Manual} alt="House" className="img-manual" /></h1>
        <div className="content-wrapper">
          <img src={Photography} alt="Augmented Reality" className="room-image" />
          <div className="instructions-box">
            <h2>Instructions for Optimal Results</h2>
            <ul>
              <li>Try to take photos of each item separately, rather than taking group shots</li>
              <li 
                className="example-link"
                onClick={() => setIsDialogOpen(true)}
              >
                <u>See example of properly captured items</u>
              </li>
              <li>For bedrooms, we recommend first taking photos of the bed for best results</li>
            </ul>
          </div>
          <div className="note-box">
            <h2>Additional Notes</h2>
            <ul>
              <li>Take photos of any items that are not specific to a certain room (e.g., art in a hallway) in the ‘Additional Items’ section</li>
              <li>Remove any items not involved in the moving process by selecting 'Edit' and clicking the trash icon next to the item</li>
            </ul>
          </div>
          <div className="begin-tour-nav">
            <NavigationArrows
              onPrevClick={handleBack}
              onNextClick={handleNext}
              prevLabel={'Previous'}
              nextLabel={"Begin Walkthrough"}
            />
          </div>
        </div>
        <PhotoExampleDialog />
      </div>
    </Layout>
  );
};

export default BeginTour;