import React, { useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';
import './contact_form.css';
import Letter from './images/img-01.png';
import Layout from '../layout';

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const navigate = useNavigate();
    const [userForm, setUserForm] = useState({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
    const formRef = useRef(null);
    const tiltRef = useRef(null);

    const handleBack = () => {
      navigate(-1);
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUserForm(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setSubmitStatus(null);
    
      try {
        const response = await fetch('https://lexos-backend-547367433733.us-central1.run.app/api/contact/', {  // Update this URL if necessary
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userForm),
        });
    
        if (response.ok) {
          const data = await response.json();
          setSubmitStatus('success');
          setUserForm({ name: '', email: '', subject: '', message: '' });
        } else {
          const errorData = await response.json();
          console.error('Error submitting form:', errorData.message);
          setSubmitStatus('error');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setSubmitStatus('error');
      }
    
      setIsSubmitting(false);
    };
  
    useEffect(() => {
      const inputs = formRef.current.querySelectorAll('.input1');
      
      inputs.forEach(input => {
        input.addEventListener('focus', function() {
          this.parentNode.classList.add('input-focused');
        });
        
        input.addEventListener('blur', function() {
          if (this.value.trim() !== "") {
            this.parentNode.classList.add('input-focused');
          } else {
            this.parentNode.classList.remove('input-focused');
          }
        });
      });
  
      // Custom tilt effect
      const tiltElement = tiltRef.current;
      const handleMouseMove = (e) => {
        const { left, top, width, height } = tiltElement.getBoundingClientRect();
        const x = (e.clientX - left) / width;
        const y = (e.clientY - top) / height;
        const tiltX = (y - 0.5) * 30; // Tilt range: -15 to 15 degrees
        const tiltY = (x - 0.5) * -30;
        tiltElement.style.transform = `perspective(1000px) rotateX(${tiltX}deg) rotateY(${tiltY}deg) scale(1.1)`;
      };
      const handleMouseLeave = () => {
        tiltElement.style.transform = 'perspective(1000px) rotateX(0) rotateY(0) scale(1)';
      };
      tiltElement.addEventListener('mousemove', handleMouseMove);
      tiltElement.addEventListener('mouseleave', handleMouseLeave);
  
      return () => {
        tiltElement.removeEventListener('mousemove', handleMouseMove);
        tiltElement.removeEventListener('mouseleave', handleMouseLeave);
      };
    }, []);
  

  return (
    <Layout>
    <div className="contact1">
    
      <div className="container-contact1">
      <button onClick={handleBack} className="contact-back-button">
          Back
        </button>
      <div className="text-center w-100 mb-4">
        
          <h4>Contact the founders directly at:</h4>
          <h4><strong>617-798-4863</strong></h4>
        </div>
        <div className="contact1-pic js-tilt" ref={tiltRef}>
          <img src={Letter} alt="IMG" className="can-i-edit-letter"/>
        </div>


        <form className="contact1-form validate-form" onSubmit={handleSubmit} ref={formRef}>
          
          <span className="contact1-form-title">
            Get In Touch
          </span>

          <div className="wrap-input1 validate-input" data-validate="Name is required">
            <input 
              className="input1" 
              type="text" 
              name="name" 
              placeholder="Name"
              value={userForm.name}
              onChange={handleChange}
            />
            <span className="shadow-input1"></span>
          </div>

          <div className="wrap-input1 validate-input" data-validate="Valid email is required: ex@abc.xyz">
            <input 
              className="input1" 
              type="text" 
              name="email" 
              placeholder="Email"
              value={userForm.email}
              onChange={handleChange}
            />
            <span className="shadow-input1"></span>
          </div>

          <div className="wrap-input1 validate-input" data-validate="Subject is required">
            <input 
              className="input1" 
              type="text" 
              name="subject" 
              placeholder="Subject"
              value={userForm.subject}
              onChange={handleChange}
            />
            <span className="shadow-input1"></span>
          </div>

          <div className="wrap-input1 validate-input" data-validate="Message is required">
            <textarea 
              className="input1" 
              name="message" 
              placeholder="Message"
              value={userForm.message}
              onChange={handleChange}
            ></textarea>
            <span className="shadow-input1"></span>
          </div>

          <div className="container-contact1-form-btn">
            <button className="contact1-form-btn">
            <span>
              {isSubmitting ? 'Sending...' : 'Send Email'}
              <i>
                <FontAwesomeIcon icon={faLongArrowAltRight} className="i"/>
              </i>
            </span>
          </button>
              </div>
              {submitStatus === 'success' && (
                <div className="submit-success">Message sent successfully!</div>
              )}
              {submitStatus === 'error' && (
                <div className="submit-error">Failed to send message. Please try again.</div>
              )}
        </form>

      </div>
    </div>
    </Layout>
  );
};

export default ContactForm;