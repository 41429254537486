import React from 'react';
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";
import './nav_arrows.css';
import BidIcon from '../../assets/lexos_imgs/new-offer.svg';
const handleTouchEnd = (e) => {
  e.target.blur();
};


const NavigationArrows = ({
  onPrevClick,
  onNextClick,
  prevLabel = 'Previous',
  nextLabel = 'Next',
  inverted,
  bid,
  isNextDisabled,
}) => {
  const handlePressStart = (e) => {
    e.currentTarget.classList.add('active');
  };

  const handlePressEnd = (e) => {
    e.currentTarget.classList.remove('active');
    e.currentTarget.blur();
  };
  return (
    <div className={`navigation-container ${inverted ? 'inverted' : ''}`}>
      <button 
        onClick={onPrevClick}
        onMouseDown={handlePressStart}
        onMouseUp={handlePressEnd}
        onMouseLeave={handlePressEnd}
        onTouchStart={handlePressStart}
        onTouchEnd={handlePressEnd}
        className={`navigation-button navigation-button-prev ${inverted ? 'inverted-button' : ''}`}
      >
        <FaArrowCircleLeft />
        <span className="navigation-button-text">{prevLabel}</span>
      </button>
      <button 
        onClick={onNextClick}
        onMouseDown={handlePressStart}
        onMouseUp={handlePressEnd}
        onMouseLeave={handlePressEnd}
        onTouchStart={handlePressStart}
        onTouchEnd={handlePressEnd}
        className={`navigation-button navigation-button-next ${isNextDisabled ? 'disabled' : ''} ${inverted ? 'inverted-button' : ''}`}
        disabled={isNextDisabled}
      >
        <span className="navigation-button-text">{nextLabel}</span>
        {!bid ? 
        <FaArrowCircleRight />
        :
        <img className="submit-bid-button-icon" src= {BidIcon}></img>
        }
      </button>
    </div>
  );
};

export default NavigationArrows;