import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './components/homeOwner/context/auth_context';
import { useMovingCompanyAuth } from './components/movingCompany/auth/movers_context';
import { useUser } from './components/homeOwner/context/user_context';
import LandingPage from './components/landing_page/landing_page';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase/fireconfig';

const HomeRoute = () => {
  const { user, loading } = useAuth();
  const { isMovingCompany, loading: companyLoading } = useMovingCompanyAuth();
  const { userForm } = useUser();
  const [hasMoveSubmitted, setHasMoveSubmitted] = useState(false);
  const [checkingMove, setCheckingMove] = useState(true);

  useEffect(() => {
    const checkForMove = async () => {
      if (user && !isMovingCompany) {
        try {
          const moveDetailsRef = collection(db, 'moveDetails');
          const q = query(moveDetailsRef, where('userId', '==', user.uid));
          const querySnapshot = await getDocs(q);
          setHasMoveSubmitted(!querySnapshot.empty);
        } catch (error) {
          console.error('Error checking move details:', error);
          setHasMoveSubmitted(false);
        }
      }
      setCheckingMove(false);
    };

    if (!loading && !companyLoading) {
      checkForMove();
    }
  }, [user, isMovingCompany, loading, companyLoading]);

  // Show nothing while checking authentication and move status
  if (loading || companyLoading || checkingMove) {
    return null; // Or return a loading spinner component if you have one
  }

  // If not authenticated at all, show landing page
  if (!user) {
    return <LandingPage />;
  }

  // If authenticated as moving company, redirect to movers dashboard
  if (isMovingCompany) {
    return <Navigate to="/movers-dashboard" replace />;
  }

  // For homeowners: check if they have submitted a move
  if (hasMoveSubmitted) {
    return <Navigate to="/dashboard" replace />;
  }

  // If authenticated as homeowner but no move submitted,
  // show landing page (or you could redirect to begin-tour)
  return <LandingPage />;
};

export default HomeRoute;