import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../homeOwner/context/auth_context';
import AuthOverlay from './auth_overlay';

export const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const [showOverlay, setShowOverlay] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!loading && !user) {
      setShowOverlay(true);
    }
  }, [user, loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return (
      <>
        <AuthOverlay returnTo={location.pathname}/>
        <div style={{ filter: 'blur(4px)' }}>
          {children}
        </div>
      </>
    );
  }

  return children;
};