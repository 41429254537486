import React from 'react';
import { Link,useNavigate, useLocation } from 'react-router-dom';
import Layout from '../layout/layout';
import './checklist.css';
import Checklist from '../../assets/lexos_imgs/checklist.png';
import NavigationArrows from '../layout/nav_arrows';
import Check from '../../assets/lexos_imgs/icons/check.png';


const BeginTour = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNext = () => {
    // Forward the state received from the previous page when navigating to Virtual Walkthrough
    navigate('/virtual-walkthrough', { state: location.state });
  };

  const handleBack = () => {
    navigate('/property-info', { state: location.state });
  };

  return (
    <Layout>
      <div className="checklist-container">
        <h1>Moving Checklist <img src={Check} alt="Checkmark" className="checkmark" /></h1>
        <div className="checklist-wrapper">
          <img src={Checklist} alt="Augmented Reality" className="checklist-image" style={{ maxHeight: '300px' }} />
          <div className = "list-wrapper">
                <div className="checklist-box">
                <h2>Step 1: Plan Your Move in Advance</h2>
                <ul>
                    <li><strong>Set Your Moving Date:</strong> Decide on a firm moving date at least 4 weeks in advance and confirm it with Lexos.</li>
                    <li><strong>Declutter:</strong> Donate, sell, or discard items you don’t need to move. Additionally, moving companies on Lexos (LexPro's) are unable to transport hazardous items. Please ensure that these items are either safely disposed of or arrange alternative transportation.</li>
                    </ul>
                </div>
                <div className="checklist-box">
                <h2>Step 2: Pack Smart (if handling on your own)</h2>
                <div className="privacy-note">
                <strong>REMINDER:</strong> If you want packing materials provided from LexPro’s, you can indicate so on the “My Inventory” page after completing your virtual walkthrough.
                </div>
                <ul>
                    <li><strong>Gather Supplies:</strong> Ensure you have boxes, tape, markers, and packing materials <u>if you plan to use your own.</u> </li>
                    <li><strong>Label Boxes:</strong> Clearly mark all boxes with room names and a brief description of contents.</li>
                    <li><strong>Pack Essentials Separately:</strong> Set aside important documents, chargers, and medications in a separate bag.</li>
                </ul>
                </div>
                <div className="checklist-box">
                <h2>Step 3: Inventory Your Items with Lexos</h2>
                <ul>
                    <li>Use the <strong>Lexos platform </strong>to document your items</li>
                    <ul>
                    <li><strong>Capture Photos:</strong> Take clear photos of each room, including inside cabinets and drawers, so the AI can generate an accurate inventory.</li>
                    <li><strong>High-Value Items:</strong> Be sure to highlight any high-value items requiring special care to help moving companies provide you with precise insurance coverage options.</li>
                    </ul>
                </ul>
                </div>
                <div className="checklist-box">
                <h2>Step 4: Submission of Move Information</h2>
                <ul>
                    <li><strong>Once you have reviewed your information,</strong> submit your move details for moving companies in your area to review. LexPro’s will diligently assess the inventory list and move information provided in order to offer the most accurate quote to you.</li>
                <ul> 
                    <li><strong>You will begin receiving offers </strong>shortly after your move information is submitted. To ensure you receive prompt responses, Lexos recommends submitting your move information during regular business hours when LexPro’s are readily available to review and respond to your request.</li>
                </ul>
                </ul>
                </div>
                <div className="checklist-box">
                <h2>Step 5: Contact with the Moving Company</h2>
                <ul>
                    <li>Once your information is submitted, you’ll begin receiving moving company bids. On your <Link to="/dashboard" className="dashboard-checklist-link"><strong>Offers Dashboard</strong></Link> you will be able to <strong>‘Accept’</strong> the services of a LexPro. Following this, the LexPro will contact you to confirm your details, and later proceed with your move.</li>
                    <li><strong>Important note:</strong> Lexos has no fees on its platform, and will never ask you for your payment information.</li>
                </ul>
                </div>
                <div className="checklist-box">
                <h2>Step 6: Stay Connected During the Move</h2>
                <ul>
                    <li>You will receive a notification one week before your move, as well as the day prior, with the scheduled arrival time of the moving company.</li>
                    <li><strong>Communicate Directly:</strong> Call the movers if you have any additional instructions or concerns on your moving day by finding their contact information online.</li>
                </ul>
                </div>
          </div>
          <div className="checklist-note">
                <h2>Additional Support</h2>
                <ul>
                    <li><strong>Problems with Your Move?</strong> If any issues arise during your move or if you have questions, please contact the founders directly at <strong>617-798-4863.</strong> We’re here to assist you and ensure a smooth, hassle-free experience.</li>
                    <li><strong>Insurance & Licensing:</strong> All moving companies (LexPro’s) on Lexos are licensed and insured, so you can be confident in the service and care they have for your belongings.</li>
                </ul>
            </div>
          <div className="checklist-nav">
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BeginTour;