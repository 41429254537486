import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMovingCompanyAuth } from './movers_context';
import CompanyAuthOverlay from './company_auth_overlay';

export const ProtectedCompanyRoute = ({ children }) => {
  const { isMovingCompany, loading } = useMovingCompanyAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isMovingCompany) {
    return (
      <>
        <CompanyAuthOverlay returnTo={location.pathname} />
        <div style={{ filter: 'blur(4px)' }}>
          {children}
        </div>
      </>
    );
  }

  return children;
};