import React, {useState, useCallback} from 'react';
import './company_profile.css';
import Layout from '../../layout/layout';
import SRM from '../../../assets/lexos_imgs/movingCos/srm.png';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationArrows from '../../layout/nav_arrows';

const SRMProfile = () => {
  const navigate = useNavigate();
  const routeChange = () => {
    const url = "https://www.google.com/search?q=safe+responsible+movers+moving+company+boston&sca_esv=a531d7c2178cf6c1&rlz=1C1CHBF_enUS924US924&sxsrf=ADLYWIIe9eqvoX3tWcAapMIaL7Jxkw8ahg%3A1728881280488&ei=gKIMZ-XHHcWX5OMPn6qFyA8&ved=0ahUKEwjlh9TUiI2JAxXFC3kGHR9VAfkQ4dUDCBA&uact=5&oq=safe+responsible+movers+moving+company+boston&gs_lp=Egxnd3Mtd2l6LXNlcnAiLXNhZmUgcmVzcG9uc2libGUgbW92ZXJzIG1vdmluZyBjb21wYW55IGJvc3RvbjIIEAAYgAQYogQyCBAAGIAEGKIEMggQABiABBiiBDIIEAAYgAQYogRIySNQiAJYuBtwAngBkAEAmAFtoAHrEKoBBDIyLjK4AQPIAQD4AQGYAhegAqYPwgIKEAAYsAMY1gQYR8ICBxAAGIAEGA3CAgYQABgHGB7CAgYQABgNGB7CAggQABgNGB4YD8ICCBAAGAUYDRgewgIEECEYCsICChAhGKABGMMEGAqYAwCIBgGQBgiSBwQyMS4yoAfgWQ&sclient=gws-wiz-serp#lrd=0x89e3793bbba9788f:0x8f712cc539563691,1,,,,";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const leaveReview = () => {
    // URL that should bring the user closer to the review section
    const mapsReviewUrl = 'https://www.google.com/maps/place/Safe+Responsible+Movers/@42.3573503,-71.1337063,17z/data=!3m1!4b1!4m6!3m5!1s0x89e3793bbba9788f:0x8f712cc539563691!8m2!3d42.3573503!4d-71.1337063!16s%2Fg%2F11c5q8s324/reviews?entry=ttu';
  
    window.open(mapsReviewUrl, '_blank', 'noopener,noreferrer');
  };
  
  
  const location = useLocation();
  const [originalFrom, setOriginalFrom] = useState(() => {
    return location.state?.originalFrom || location.state?.from?.pathname || '/';
  });

  // Use this function for navigation after viewing company profile
  const navigateToOriginalDestination = useCallback(() => {
    navigate(originalFrom, { replace: true });
  }, [navigate, originalFrom]);

  return (
    <Layout>
      <div className="company-profile-container">
      <div className="property-info-nav">
            <NavigationArrows
              onPrevClick={navigateToOriginalDestination}
              prevLabel={'Back'}
              inverted
            />
        </div>
        <div className="company-header">
          <div className="company-info">
            <div className="company-img-placeholder">
              <img src={SRM} alt="Company Logo" className="zeke" />
            </div>
            <div className="company-details">
              <h2 className="company-name">Safe Responsible Movers</h2>
              <div className="company-rating">
                <span className="rating-text">Rating: 4.9</span>
                <span className="stars">
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                </span>
                <button onClick={routeChange} className="review-count">(151 reviews)</button>
              </div>
              <button onClick={leaveReview} className="share-btn">Share</button>
            </div>
          </div>
        </div>

        <div className="company-introduction">
          <h3>Introduction</h3>
          <p>
          We’re the best movers in Boston, plain and simple. In the whole of Massachusetts, really. Sure, we’re musicians. We’re artists. We’re writers. And we’re all very stylish dressers.
And we’re fantastic local movers for residential and commercial moves covering the Greater Boston area. More than anything, that’s the most common feedback we get — customers are satisfied because we’re really good at moving. 
          </p>
        </div>

        <div className="company-overview">
          <div className="overview-section">
            <h4>Overview</h4>
            <ul>
              <li>Serving all of Massachusetts and offering interstate moving services.</li>
              <li>Background checked</li>
              <li>A+ Local Movers via Better Business Bureau</li>
            </ul>
          </div>

          <div className="overview-section">
            <h4>Contact Information</h4>
            <ul>
            Contact with the moving company will begin once you hit 'Accept' on your preferred offer in the Offers Dashboard. The company will then reach out to confirm details and proceed with your move.
            </ul>
          </div>

          <div className="overview-section">
            <h4>Payment Methods</h4>
            <p>
            This LexPro accepts payments via Cash, Check and Venmo.
            </p>
          </div>

          <div className="overview-section">
            <h4>LexPro Gold Status</h4>
            <p>
              LexPro Gold's are among the highest-rated, most popular professionals on Lexos.
            </p>
          </div>
        </div>

        <div className="business-hours">
          <h4>Business Hours</h4>
          <table>
            <tbody>
              <tr>
                <td>Mon</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              {/* Add more days as needed */}
              <tr>
                <td>Tue</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Sun</td>
                <td>8:00 AM - 6:00 PM</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="company-reviews">
          <h3>Reviews</h3>
          <section className="reviews-section">
            <div className="row text-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center mb-4 ">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "This company did an amazing job moving my heavy furniture to my new house. They were true professionals, and knew exactly what they were doing. The prices are very reasonable, considering how efficient this group is. I would recommend this company to anyone who needs movers!"
                </p>
                <p className="font-italic font-weight-normal mb-0">-Joseph Wilder</p>
              </div>
              <div className="col-md-6 mb-0">
                <div className="d-flex justify-content-center mb-4">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "I've moved many times and SRM is the best moving company I've ever worked with.  They were very fast, nothing was damaged and the final bill was much lower than what I expected.  Their guy Adam was the point man and he was very nice and super professional.  I would highly recommend this company to anyone about to move."
                </p>
                <p className="font-italic font-weight-normal mb-0">- Michael Coyle</p>
              </div>
            </div>
          </section>
        </div>
      </div>

    </Layout>
  );
};

export default SRMProfile;
