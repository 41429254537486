import React, { useState, useEffect, useRef } from 'react';
import { Datepicker, setOptions, createCustomTheme} from '@mobiscroll/react';
import { createGlobalStyle } from 'styled-components';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import styled from 'styled-components';
import './destination.css';
import './custom_time.css';

setOptions({
    theme: 'ios',
    themeVariant: 'light'
  });

  createCustomTheme('my-theme', 'ios');

  const GlobalStyles = createGlobalStyle`
  .custom-time-picker .mbsc-timegrid-item {
    padding: 5px 0 !important;
  }
  .custom-time-picker .mbsc-datepicker-tab {
    padding-bottom: 0 !important;
  }
  .custom-time-picker .mbsc-timegrid-container {
    margin-bottom: 0 !important;
  }
  .custom-time-picker .mbsc-range-labels {
    margin-bottom: 0 !important;
    color: var(--primary-color) !important;
  }
  .custom-time-picker .mbsc-range-control-value.mbsc-my-theme.mbsc-ios.mbsc-ltr.active{
    color: var(--primary-color) !important;
  }
  .custom-time-picker .mbsc-timegrid-wrapper {
    margin-bottom: 0 !important;
  }

  @media (max-width: 768px) {
    // .custom-time-picker {
    //   font-size: 14px;
    // }
    .custom-time-picker .mbsc-timegrid-item {
      padding: 3px 0 !important;
    }
  }
`;
  
const TimePickerContainer = styled.div`
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8);
  max-width: 100%;
  width: 300px;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;

`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
  width: 300px;
`;

const Title = styled.h3`
  margin: 0 0 10px 0;
  font-size: 16px;
  color: var(--primary-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  
  width: 100%;
`;

const Button = styled.button`
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 14px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
const SetTimeButton = styled.button`
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 14px;
  cursor: pointer;
  &:disabled{
    opacity: 0.5;
    color: grey;
  }

`;

const StyledDatepicker = styled(Datepicker)`
  theme = "my-theme"
`;

/* Function to handle the x buttons */


const CustomTime = ({ selectedDate, onClose, onSave, calendarRef }) => {
  const initialTimeRange = [
      new Date(selectedDate),
      new Date(selectedDate),
    ];
  const [selectedTime, setSelectedTime] = useState(initialTimeRange);
  const containerRef = useRef(null);

  useEffect(() => {
    setSelectedTime([new Date(selectedDate), new Date(selectedDate)]);
  }, [selectedDate]);


  const handleTimeChange = (event) => {
    setSelectedTime(event.value);
  };

  const handleSave = () => {
    onSave({
        date: selectedDate,
        startTime: selectedTime[0],
        endTime: selectedTime[1]
      });
  };

  return (
    <>
    <GlobalStyles />
    <TimePickerContainer ref={containerRef}>
      <Header>
      <Title>Time Window for Movers Arrival</Title>
      <ButtonContainer>
        <Button onClick={onClose}><strong>Cancel</strong></Button>
        <SetTimeButton  onClick={handleSave} disabled={!(selectedTime[0] && selectedTime[1])}>
        <strong>Set</strong>
        </SetTimeButton>
        </ButtonContainer>
      </Header>
      <Datepicker
        controls={['time']}
        theme="my-theme"
        select="range"
        touchUi={true}
        display="inline"
        onChange={handleTimeChange}
        themeVariant="light"
        cssClass="custom-time-picker"
        value={selectedTime}
        // Ensure defaultSelection is set if value is not enough
        defaultSelection={selectedTime}
        clearIcon={false}
        rangeStartLabel="Earliest"  
        rangeEndLabel="Latest"      
        />
    </TimePickerContainer>
    </>
  );
};

export default CustomTime;