import React, {useState, useCallback} from 'react';
import './company_profile.css';
import Layout from '../../layout/layout';
import SRM from '../../../assets/lexos_imgs/movingCos/rexton.png';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationArrows from '../../layout/nav_arrows';

const MoveMateProfile = () => {
  const navigate = useNavigate();
  const routeChange = () => {
    const url = "https://www.google.com/search?q=rexton+moving+%26+storage&rlz=1C1CHBF_enUS924US924&oq=Rexton+Moving+%26+Storage&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyDAgCEAAYFBiHAhiABDIKCAMQABiABBiiBDIKCAQQABiABBiiBDIKCAUQABiiBBiJBdIBBzMxMmowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x89e383054ba15e87:0xc320bd968df27f3f,1,,,,";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const leaveReview = () => {
    // URL that should bring the user closer to the review section
    const mapsReviewUrl = 'https://www.google.com/maps/place/Rexton+Moving+%26+Storage/@42.3657492,-71.1817169,17z/data=!3m1!4b1!4m6!3m5!1s0x89e383054ba15e87:0xc320bd968df27f3f!8m2!3d42.3657453!4d-71.179142!16s%2Fg%2F11f689wxtk?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D';
  
    window.open(mapsReviewUrl, '_blank', 'noopener,noreferrer');
  };

  const location = useLocation();
  const [originalFrom, setOriginalFrom] = useState(() => {
    return location.state?.originalFrom || location.state?.from?.pathname || '/';
  });

  // Use this function for navigation after viewing company profile
  const navigateToOriginalDestination = useCallback(() => {
    navigate(originalFrom, { replace: true });
  }, [navigate, originalFrom]);

  return (
    <Layout>
      <div className="company-profile-container">
      <div className="property-info-nav">
            <NavigationArrows
              onPrevClick={navigateToOriginalDestination}
              prevLabel={'Back'}
              inverted
            />
        </div>
        <div className="company-header">
          <div className="company-info">
            <div className="company-img-placeholder">
              <img src={SRM} alt="Company Logo" className="zeke" />
            </div>
            <div className="company-details">
              <h2 className="company-name">Rexton Moving & Storage</h2>
              <div className="company-rating">
                <span className="rating-text">Rating: 5.0</span>
                <span className="stars">
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStarHalfAlt color="var(--primary-color)" />
                </span>
                <button onClick={routeChange} className="review-count">(39 reviews)</button>
              </div>
              <button onClick={leaveReview} className="share-btn">Share</button>
            </div>
          </div>
        </div>

        <div className="company-introduction">
          <h3>Introduction</h3>
          <p>
          We offer a stress-free move – from packing to unloading, we handle it all, letting you relax while we manage the process. We offer quality service at a fair price, making moving affordable. Expect transparent pricing, exceptional customer service, and a hassle-free experience. 
          </p>
        </div>

        <div className="company-overview">
          <div className="overview-section">
            <h4>Overview</h4>
            <ul>
              <li>Serving all of Massachusetts and offering interstate moving services.</li>
              <li>Background checked</li>
              <li>In business for 10+ years</li>
            </ul>
          </div>

          <div className="overview-section">
            <h4>Contact Information</h4>
            <ul>
            Contact with the moving company will begin once you hit 'Accept' on your preferred offer in the Offers Dashboard. The company will then reach out to confirm details and proceed with your move.
            </ul>
          </div>

          <div className="overview-section">
            <h4>Payment Methods</h4>
            <p>
            This LexPro accepts payments via Cash, Credit Card, Check, ACH, and Zelle.
            </p>
          </div>

          <div className="overview-section">
            <h4>LexPro Gold Status</h4>
            <p>
              LexPro Gold's are among the highest-rated, most popular professionals on Lexos.
            </p>
          </div>
        </div>

        <div className="business-hours">
          <h4>Business Hours</h4>
          <table>
            <tbody>
            <tr>
                <td>Mon</td>
                <td>6:00 AM - 8:00 PM</td>
              </tr>
              {/* Add more days as needed */}
              <tr>
                <td>Tue</td>
                <td>6:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>6:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>6:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>6:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>6:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Sun</td>
                <td>6:00 AM - 8:00 PM</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="company-reviews">
          <h3>Reviews</h3>
          <section className="reviews-section">
            <div className="row text-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center mb-4 ">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "Very happy with our experience with Rexton Moving. They arrived right on time and were extremely efficient and communicative throughout the move. Definitely exceeded expectations and would recommend to anyone moving in & around Boston."
                </p>
                <p className="font-italic font-weight-normal mb-0">-Matt Perry</p>
              </div>
              <div className="col-md-6 mb-0">
                <div className="d-flex justify-content-center mb-4">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  " We have used Rexton on three separate occasions. They are excellent. Small to large moves are no problem. They are the best we have ever hired."
                </p>
                <p className="font-italic font-weight-normal mb-0">-John Stellberger</p>
              </div>
            </div>
          </section>
        </div>
      </div>

    </Layout>
  );
};

export default MoveMateProfile;
