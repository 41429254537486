import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';


const UserContext = React.createContext();

// Add this hook to use the context
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

const UserProvider = ({ children }) => {
    const [userForm, setUserForm] = useState({
        fullName: "",
        username: "",
        password: "",
        email: "",
        phone: '',
    });

    const setUserFormCallback = useCallback((newData) => {
        setUserForm(prevData => {
          const updatedData = typeof newData === 'function' ? newData(prevData) : newData;
          return { ...prevData, ...updatedData };
        });
      }, []);

    const contextValue = useMemo(() => ({
        userForm,
        setUserForm: setUserFormCallback
    }), [userForm,  setUserForm])

    return (
        <UserContext.Provider value={contextValue}>
          {children}
        </UserContext.Provider>
      );
};

export {UserContext, UserProvider}

