import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout from '../../layout/layout.js';
import '../../movingCompany/listings/lead_analysis.css';
import { ArrowLeft } from 'lucide-react';
import { getStorage, ref, getBlob } from 'firebase/storage';

const ViewBreakdown = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pdfContainerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    
    const breakdownUrl = location.state?.breakdownUrl;

    useEffect(() => {
        if (!breakdownUrl) {
            console.error('No PDF URL provided');
            navigate(-1);
            return;
        }

        let isCancelled = false;
        let renderTasks = [];
        let blobUrl = null;

        const loadAndRenderPDF = async () => {
            try {
                setIsLoading(true);
                const pdfjsLib = window['pdfjsLib'];
                pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';

                // Get the storage reference and blob using Firebase Storage
                const storage = getStorage();
                const fileRef = ref(storage, breakdownUrl);
                const blob = await getBlob(fileRef);
                
                // Create blob URL from the downloaded blob
                blobUrl = URL.createObjectURL(blob);

                if (pdfContainerRef.current) {
                    pdfContainerRef.current.innerHTML = '';
                }

                const loadingTask = pdfjsLib.getDocument(blobUrl);
                const pdf = await loadingTask.promise;
                const numPages = pdf.numPages;

                const renderPage = async (pageNum) => {
                    if (isCancelled) return;

                    const page = await pdf.getPage(pageNum);
                    if (isCancelled) return;

                    const containerWidth = pdfContainerRef.current.offsetWidth;
                    const baseScale = containerWidth / page.getViewport({ scale: 1 }).width;
                    const scale = baseScale * 2;

                    const viewport = page.getViewport({ scale });
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d', { alpha: false });

                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    canvas.style.width = '100%';
                    canvas.style.height = 'auto';
                    canvas.style.marginBottom = '20px';

                    context.imageSmoothingEnabled = true;
                    context.imageSmoothingQuality = 'high';

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                        enableWebGL: true,
                        renderInteractiveForms: true,
                    };

                    const renderTask = page.render(renderContext);
                    renderTasks.push(renderTask);

                    await renderTask.promise;
                    renderTasks = renderTasks.filter(task => task !== renderTask);

                    if (isCancelled) return;
                    pdfContainerRef.current.appendChild(canvas);

                    if (pageNum < numPages) {
                        await renderPage(pageNum + 1);
                    }
                };

                await renderPage(1);
                setIsLoading(false);

            } catch (error) {
                console.error('Error loading PDF: ', error);
                setIsLoading(false);
                if (pdfContainerRef.current) {
                    pdfContainerRef.current.innerHTML = `
                        <div class="pdf-error">
                            <p>Error loading PDF. Please try again.</p>
                            <p class="error-details">${error.message}</p>
                        </div>
                    `;
                }
            }
        };

        loadAndRenderPDF();

        return () => {
            isCancelled = true;
            renderTasks.forEach(task => task.cancel());
            if (pdfContainerRef.current) {
                pdfContainerRef.current.innerHTML = '';
            }
            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);
            }
        };
    }, [breakdownUrl, navigate]);

    return (
        <Layout>
            <div className="analysis-container">
                <button 
                    onClick={() => navigate(-1)}
                    className="submitOffer-back-button"
                >
                    <ArrowLeft className="mr-2" size={20} />
                    Back
                </button>
                <div className="pdf-viewer-container">
                    {isLoading && (
                        <div className="loading-indicator">
                            Loading PDF...
                        </div>
                    )}
                    <div ref={pdfContainerRef} className="pdf-container">
                        {/* PDF pages will be rendered here */}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ViewBreakdown;