import React, {useState, useCallback} from 'react';
import './company_profile.css';
import Layout from '../../layout/layout';
import SRM from '../../../assets/lexos_imgs/movingCos/best_rate.jpg';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationArrows from '../../layout/nav_arrows';

const MoveMateProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeChange = () => {
    const url = "https://www.google.com/search?q=boston+best+rate+movers&rlz=1C1CHBF_enUS924US924&oq=boston+best+rate+movers+&gs_lcrp=EgZjaHJvbWUqDAgAECMYJxiABBiKBTIMCAAQIxgnGIAEGIoFMhMIARAuGK8BGMcBGJECGIAEGIoFMgYIAhBFGEAyBwgDEAAYgAQyDQgEEAAYkQIYgAQYigUyCAgFEAAYFhgeMggIBhAAGBYYHjINCAcQABiGAxiABBiKBdIBCDI4MDRqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x89e379cfd6a55ad3:0x30dd9bcf3210c5e9,1,,,,";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const leaveReview = () => {
    // URL that should bring the user closer to the review section
    const mapsReviewUrl = `https://www.google.com/maps/place/Boston+Best+Rate+Movers/@42.3667305,-71.2220668,17z/data=!3m1!4b1!4m6!3m5!1s0x89e379cfd6a55ad3:0x30dd9bcf3210c5e9!8m2!3d42.3667266!4d-71.2194919!16s%2Fg%2F1tfmd7hp?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D`;
  
    window.open(mapsReviewUrl, '_blank', 'noopener,noreferrer');
  };


  const [originalFrom, setOriginalFrom] = useState(() => {
    return location.state?.originalFrom || location.state?.from?.pathname || '/';
  });

  // Use this function for navigation after viewing company profile
  const navigateToOriginalDestination = useCallback(() => {
    navigate(originalFrom, { replace: true });
  }, [navigate, originalFrom]);

  return (
    <Layout>
      <div className="company-profile-container">
        <div className="property-info-nav">
              <NavigationArrows
                onPrevClick={navigateToOriginalDestination}
                prevLabel={'Back'}
                inverted
              />
          </div>
        <div className="company-header">
          <div className="company-info">
            <div className="company-img-placeholder">
              <img src={SRM} alt="Company Logo" className="zeke" />
            </div>
            <div className="company-details">
              <h2 className="company-name">Boston Best Rate Movers</h2>
              <div className="company-rating">
                <span className="rating-text">Rating: 4.7</span>
                <span className="stars">
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStarHalfAlt color="var(--primary-color)" />
                </span>
                <button onClick={routeChange} className="review-count">(579 reviews)</button>
              </div>
              <button onClick = {leaveReview} className="share-btn">Share</button>
            </div>
          </div>
        </div>

        <div className="company-introduction">
          <h3>Introduction</h3>
          <p>
          Welcome to Boston Best Rate Movers, where we have been providing top-quality moving services since 2002. With a focus on professionalism, courtesy, and customer satisfaction, we strive to make your moving experience seamless and stress-free. Discover why our repeat customers and referrals speak volumes about our exceptional service.
          </p>
        </div>

        <div className="company-overview">
          <div className="overview-section">
            <h4>Overview</h4>
            <ul>
              <li>Serving all of Massachusetts and offering interstate moving services.</li>
              <li>Background checked</li>
              <li>In business for 20+ years</li>
            </ul>
          </div>

          <div className="overview-section">
            <h4>Contact Information</h4>
            <ul>
            Contact with the moving company will begin once you hit 'Accept' on your preferred offer in the Offers Dashboard. The company will then reach out to confirm details and proceed with your move.
            </ul>
          </div>

          <div className="overview-section">
            <h4>Payment Methods</h4>
            <p>
            This LexPro accepts payments via Cash and Credit card. 
            </p>
          </div>

          <div className="overview-section">
            <h4>LexPro Gold Status</h4>
            <p>
              LexPro Gold's are among the highest-rated, most popular professionals on Lexos.
            </p>
          </div>
        </div>

        <div className="business-hours">
          <h4>Business Hours</h4>
          <table>
            <tbody>
              <tr>
                <td>Mon</td>
                <td>8:30 AM - 5:30 PM</td>
              </tr>
              {/* Add more days as needed */}
              <tr>
                <td>Tue</td>
                <td>8:30 AM - 5:30 PM</td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>8:30 AM - 5:30 PM</td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>8:30 AM - 5:30 PM</td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>8:30 AM - 5:30 PM</td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>9:00 AM - 3:00 PM</td>
              </tr>
              <tr>
                <td>Sun</td>
                <td>Closed</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="company-reviews">
          <h3>Reviews</h3>
          <section className="reviews-section">
            <div className="row text-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center mb-4 ">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "Walter and his team were great! We had no problems or issues with our move. The movers were on-time, worked hard and efficiently. The communication with the moving coordinator was also easy and straightforward when scheduling our move. We would absolutely use Boston Best Rate again in the future."
                </p>
                <p className="font-italic font-weight-normal mb-0">-Ted Hannigan</p>
              </div>
              <div className="col-md-6 mb-0">
                <div className="d-flex justify-content-center mb-4">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "Alex and his team were fantastic! On time, efficient and so helpful. Took great care of our furniture and moved it quickly! Recommend to others!
                  "
                </p>
                <p className="font-italic font-weight-normal mb-0">- Mary Matell</p>
              </div>
            </div>
          </section>
        </div>
      </div>

    </Layout>
  );
};

export default MoveMateProfile;
