import styled from 'styled-components';
import React, { useState } from 'react';

// Copy icon component
const CopyIcon = ({ color }) => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// Styled button component
const CopyButton = styled.button`
  top: 10px;
  right: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #8b50f7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  // width: 260px; // Fixed width to prevent shifting
  padding: 0;
`;

const CopyComponent = ({ inventoryItems }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const formattedInventory = formatInventory(inventoryItems);
    navigator.clipboard.writeText(formattedInventory);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  // Function to format inventory into the desired output format
  const formatInventory = (inventory) => {
    if (!inventory || typeof inventory !== 'object') return ''; // Ensure inventory is valid

    let formattedText = '';
    Object.entries(inventory).forEach(([room, items]) => {
      formattedText += `${room.toUpperCase()}\n`; // Room title in uppercase
      items.forEach((item) => {
        formattedText += `${item.name}\n`; // Item name
        item.details.forEach((detail) => {
          formattedText += `- ${detail}\n`; // Item details with a dash
        });
      });
      formattedText += '\n'; // Add a line break between rooms
    });
    return formattedText;
  };

  return (
    <CopyButton onClick={handleCopy}>
      {copied ? (
        <>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 6L9 17L4 12"
              stroke="#8b50f7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Copied!
        </>
      ) : (
        <>
          <CopyIcon color="#8b50f7" />
          Copy
        </>
      )}
    </CopyButton>
  );
};

export default CopyComponent;
