import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
// import Avatar from 'react-avatar';
import ProfileImage from '../homeOwner/register/profile_image';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaUserCircle, FaBars } from 'react-icons/fa';
import { signOut, onAuthStateChanged } from 'firebase/auth'; // Import Firebase auth state listener
import { auth } from '../../firebase/fireconfig';
import { useAuth } from '../homeOwner/context/auth_context';
import {getUserInfo} from '../../firebase/firestoreUtil';
import '../layout/layout.css'; // Adjust the path if necessary
import './user_dropdown.css'

const UserAccountDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, isMovingCompany } = useAuth();
  const [fullName, setFullName] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, right: 0 });
  const containerRef = useRef(null);

  // Combined click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  // Position calculation
  useEffect(() => {
    const updateDropdownPosition = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const isDesktop = window.innerWidth > 576;
        
        setDropdownPosition({
          top: rect.bottom + window.scrollY - 5,
          right: isDesktop ? (window.innerWidth - rect.right - 15) : 30
        });
      }
    };

    if (dropdownOpen) {
      updateDropdownPosition();
    }

    window.addEventListener('resize', updateDropdownPosition);
    window.addEventListener('scroll', updateDropdownPosition);

    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
      window.removeEventListener('scroll', updateDropdownPosition);
    };
  }, [dropdownOpen]);

  // Fetch user info
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (user) {
        try {
          const userInfo = await getUserInfo();
          if (userInfo?.fullName) {
            setFullName(userInfo.fullName);
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      }
    };
    fetchUserInfo();
  }, [user]);

  const handleProfileClick = (e) => {
    e.stopPropagation(); // Prevent the click from immediately triggering the outside click handler
    if (user) {
      setDropdownOpen(!dropdownOpen);
    } else {
      navigate('/login', { state: { from: location } });
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const getMenuItems = () => {
    if (isMovingCompany) {
      return [
        { to: '/movers-dashboard', label: 'Movers Dashboard', important: true },
        { to: '/movers-reviews', label: 'Reviews', important: true }, 
        { divider: true },
        { to: '/mover-FAQ', label: 'FAQs', important: false },
        { to: '/edit-profile', label: 'Edit Profile Information', important: false },
        { divider: true },
        { to: '/forgot-password', label: 'Forgot Password', important: false }
      ];
    }
    return [
      { to: '/dashboard', label: 'Offers Dashboard', important: true },
      { to: '/checklist', label: 'Moving Checklist', important: true },
      { divider: true },
      { to: '/FAQ-user', label: 'FAQs', important: false },
      { to: '/edit-move-info', label: 'Edit Move Information', important: false },
      { divider: true },
      { to: '/forgot-password', label: 'Forgot Password', important: false }
    ];
  };

  return (
    <div className="dropdown-container" ref={containerRef}>
      <button 
        className="profile-menu-button"
        onClick={handleProfileClick}
      >
        <FaBars className="menu-icon" />
        {user ? (
          fullName ? 
            <ProfileImage name={fullName} className="user-icon"/> : 
            <FaUserCircle className="user-icon" />
        ) : (
          <FaUserCircle className="user-icon" />
        )}
      </button>

      {user && dropdownOpen && ReactDOM.createPortal(
        <ul className="dropdown-menu" style={{ top: `${dropdownPosition.top}px`, right: `${dropdownPosition.right}px` }}>
          {getMenuItems().map((item, index) => (
            <React.Fragment key={item.to || index}>
              {item.divider ? (
                <li className="dropdown-divider"></li>
              ) : (
                <li>
                  <Link 
                    to={item.to} 
                    className={item.important ? "dropdown-item-important" : "dropdown-item"}
                  >
                    {item.label}
                  </Link>
                </li>
              )}
            </React.Fragment>
          ))}
          <li>
            <button className="dropdown-item" onClick={handleLogout}>Log Out</button>
          </li>
        </ul>,
        document.body
      )}
    </div>
  );
};

export default UserAccountDropdown;
