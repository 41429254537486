import React from 'react';
import { useNavigate } from 'react-router-dom';
import './auth_overlay.css';

const AuthOverlay = ( { returnTo }) => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate('/login', { 
      state: { 
        from: { pathname: returnTo || '/' }
      }
    });
  };

  return (
    <div className="auth-overlay">
      <div className="auth-modal">
        <h2 className="auth-title">You must be signed in to view this page</h2>
        <button 
          className="auth-signin-btn"
          onClick={handleSignIn}
        >
          Sign In
        </button>
      </div>
    </div>
  );
};

export default AuthOverlay;