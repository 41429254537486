import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider
} from 'firebase/auth';
import { auth, db } from '../../../firebase/fireconfig';
import { UserContext} from '../context/user_context';
import '../../../styles/bootstrap_imported.css';
import './signup_login.css';
import { faXTwitter, faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../../layout/layout';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

function SignupLogin() {
  const { userForm, setUserForm} = useContext(UserContext);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('login');
  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [signupData, setSignupData] = useState(() => {
    const savedData = sessionStorage.getItem('signupFormData');
    return savedData ? JSON.parse(savedData) : { ...userForm, password: password };
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  const [signupPasswordVisible, setSignupPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const [originalFrom, setOriginalFrom] = useState(() => {
    return location.state?.originalFrom || location.state?.from?.pathname || '/review';
  });
  const from = location.state?.from?.pathname || '/review';

  const handleSignupChange = (field, value) => {
    setSignupData(prevData => ({...prevData, [field]: value}));
  };

  // Use this function for final navigation after successful login/signup
  const navigateToOriginalDestination = useCallback(() => {
    navigate(originalFrom, { replace: true });
  }, [navigate, originalFrom]);

  useEffect(() => {
    // Save form data to sessionStorage when signupData changes
    sessionStorage.setItem('signupFormData', JSON.stringify(signupData));
  }, [signupData]);

  useEffect(() => {
    return () => {
      // Save form data to sessionStorage when component unmounts
      if (location.state?.from === 'terms') {
        sessionStorage.getItem('signupFormData', JSON.stringify(signupData));
      };
    }
  }, [signupData]);

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
      // Clear the state to avoid persisting across refreshes
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, redirect them to the dashboard or which page they came from
        navigateToOriginalDestination();
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, [navigateToOriginalDestination, from]);

  useEffect(() => {
    // Attach the event listener to the input fields
    const emailInput = document.querySelector('input[type="text"]');
    const passwordInput = document.querySelector('input[type="password"]');

    const clearError = () => setErrorMessage('');

    if (emailInput) emailInput.addEventListener('input', clearError);
    if (passwordInput) passwordInput.addEventListener('input', clearError);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      if (emailInput) emailInput.removeEventListener('input', clearError);
      if (passwordInput) passwordInput.removeEventListener('input', clearError);
    };
  }, []);

  const handleTermsClick = (e) => {
    e.preventDefault();
    // Pass the original 'from' path when navigating to terms
    navigate('/terms', { state: { originalFrom: originalFrom } });
  };



  useEffect(() => {
    if (location.state?.from === 'terms') {
      setActiveTab('register');
      // Preserve originalFrom when coming back from terms
      if (location.state?.originalFrom) {
        setOriginalFrom(location.state.originalFrom);
      }
    } else if (location.state?.from) {
      // Coming from somewhere else in the app, update the preserved path
      setOriginalFrom(location.state.from.pathname);
    }
  }, [location]);

  const validateForm = () => {
    if (activeTab === 'register') {
      if(signupData.password.length < 8){
        setErrorMessage('Password should be at least 8 characters.');
        return false;
      }
      
      if (
        !/[A-Z]/.test(signupData.password) ||
        !/[a-z]/.test(signupData.password) ||
        !/[0-9]/.test(signupData.password) ||
        !/[^A-Za-z0-9]/.test(signupData.password)
      ) {
        setErrorMessage(
          'Password should contain uppercase, lowercase, number, and special character.'
        );
        return false;
      }
      if (!signupData.fullName.trim()) {
        setErrorMessage('Please enter your full name.');
        return false;
      }
      // Check username is not an email
      if (signupData.username.includes('@')) {
        setErrorMessage('Username cannot be an email address');
        return false;
      }
      if (signupData.phone) {
        // Remove all non-digit characters
        const digitsOnly = signupData.phone.replace(/\D/g, '');
        
        // Check if the resulting string has 10 digits
        if (digitsOnly.length !== 11) {
          setErrorMessage('Please enter a valid phone number with 10 digits.');
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
    try {
      if (activeTab === 'register') {
        const userCredential = await createUserWithEmailAndPassword(auth, signupData.email, signupData.password);
        const user = userCredential.user;
        await addUserToFirestore(user);
        sendWelcomeEmail(user);
        // 

      } else {
        const userCredential = await signInWithEmailAndPassword(auth, userForm.email, password);
      }
      
      setPassword('');
      sessionStorage.removeItem('signupFormData');

      navigate(originalFrom, { replace: true });
    } catch (error) {
      console.error('Error with authentication:', error.message);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }

  };

  const sendWelcomeEmail = async(user) => { 

    try {
      const response = await fetch('https://lexos-backend-547367433733.us-central1.run.app/api/contact/', {  // Update this URL if necessary
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...signupData,
          email: user.email, // Include the user's email
          sendWelcomeEmail: true // Flag to indicate that a welcome email should be sent
        }),
      });
    }catch (error) {
      console.error('Error sending:', error.message);
      setErrorMessage(error.message);
    }
  };

  const handleSocialSignIn = async (provider) => {
    try {
      let authProvider;
      switch (provider) {
        case 'google':
          authProvider = new GoogleAuthProvider();
          break;
        case 'facebook':
          authProvider = new FacebookAuthProvider();
          break;
        case 'twitter':
          authProvider = new TwitterAuthProvider();
          break;
        default:
          throw new Error('Unsupported provider');
      }
      const result = await signInWithPopup(auth, authProvider);
      navigate(from, { replace: true });
    } catch (error) {
      console.error('Error with social sign-in:', error.message);
      setErrorMessage(error.message);
    }
    
  };

  const addUserToFirestore = async (user) => {
    try {
      const userDocRef = doc(db, 'homeOwners', user.uid);
      await setDoc(userDocRef, {
        created_at: serverTimestamp(),
        email: signupData.email,
        fullName: signupData.fullName,
        phone: signupData.phone,
        userId: user.uid,
        username: signupData.username
      });
    } catch (error) {
      console.error("Error adding user to Firestore: ", error);
      // You might want to handle this error, perhaps by showing a message to the user
    }
  };


  return (
    <Layout>
      <h1 className="register-header">Get Started</h1>
      <div className="auth-container" style={{ minHeight: 'calc(100vh)' }}>
        <div className="auth-tabs">
          <button
            className={`auth-tab ${activeTab === 'login' ? 'active' : ''}`}
            onClick={() => setActiveTab('login')}
          >
            Login
          </button>
          <button
            className={`auth-tab ${activeTab === 'register' ? 'active' : ''}`}
            onClick={() => setActiveTab('register')}
          >
            Register
          </button>
        </div>

        <div className="auth-content">
          {errorMessage && errorMessage !== 'Firebase: Error (auth/popup-closed-by-user).' && <p className="error-message">{errorMessage}</p>}
          <div className="social-buttons">
            <button
              className="social-button facebook"
              aria-label="Sign in with Facebook"
              onClick={() => handleSocialSignIn('facebook')}
              
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </button>
            <button
              className="social-button google"
              aria-label="Sign in with Google"
              onClick={() => handleSocialSignIn('google')}
            >
              <FontAwesomeIcon icon={faGoogle} />
            </button>
            <button 
              className="social-button x" 
              aria-label="Sign in with X"
              onClick={() => handleSocialSignIn('twitter')}  
              >
              <FontAwesomeIcon icon={faXTwitter} />
            </button>
          </div>

          {activeTab === 'login' ? (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Email"
                value={userForm.email}
                onChange={(e) => setUserForm(prevData => ({...prevData, email: e.target.value }))}
                autoComplete="email"
              />
              <div className="password-input-wrapper">
                <input
                  type={loginPasswordVisible ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                />
                <button
                  type="button"
                  className="password-toggle-button"
                  onClick={() => setLoginPasswordVisible(!loginPasswordVisible)}
                  aria-label={loginPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  {loginPasswordVisible ? 'Hide' : 'Show'}
                </button>
              </div>
              <div className="form-footer">
                <label>
                  <input type="checkbox" /> Remember me
                </label>
                {/* Redirect to new password reset page */}
                <button
                  className="forgot-password"
                  type="button"
                  onClick={() => navigate('/forgot-password')}
                >
                  Forgot password?
                </button>
              </div>
              <button type="submit" className="submit-button-login" disabled={isLoading}>
                {isLoading ? 'Signing In...' : 'Sign In'}
              </button>
            </form>
          ) : (
            <form type = "submit" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Full Name"
                  value={signupData.fullName}
                  onChange={(e) => handleSignupChange('fullName', e.target.value)}
                  autoComplete="name"
                />
                <input
                  type="text"
                  placeholder="Username"
                  value={signupData.username}
                  onChange={(e) => handleSignupChange('username', e.target.value)}
                  autoComplete="username"
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={signupData.email}
                  onChange={(e) => handleSignupChange('email', e.target.value)}
                  autoComplete="email"
                />
                <PatternFormat 
                  type="tel"
                  placeholder="Phone number"
                  format={'+1 (###) ###-####'}
                  mask="_" 
                  value={signupData.phone}
                  onValueChange={(values) => handleSignupChange('phone', values.formattedValue)}
                  required
                />
                <div className="password-input-wrapper">
                  <input
                    type={signupPasswordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    value={signupData.password}
                    onChange={(e) => handleSignupChange('password', e.target.value)}
                    autoComplete="new-password"
                  />
                <button
                  type="button"
                  className="password-toggle-button"
                  onClick={() => setSignupPasswordVisible(!signupPasswordVisible)}
                  aria-label={signupPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  {signupPasswordVisible ? 'Hide' : 'Show'}
                </button>
              </div>
              <label className="terms-label">
                <input type="checkbox" required /> I have read and agree to the
                <span className="terms-link" onClick={handleTermsClick}>
                  {' '}
                  Terms and Conditions
                </span>
              </label>
              <button type="submit" className="submit-button-signup" disabled={isLoading}>
                {isLoading ? 'Signing Up...' : 'Sign Up'}
              </button>
            </form>
          )}

          {activeTab === 'login' ? (
            <p className="register-link">
              Not a member?{' '}
              <a href="#" onClick={() => setActiveTab('register')}>
                Register
              </a>
            </p>
          ) : (
            <p className="register-link">
              Already a member?{' '}
              <a href="#" onClick={() => setActiveTab('login')}>
                Login
              </a>
            </p>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default SignupLogin;
