import React, {useState, useCallback} from 'react';
import './company_profile.css';
import Layout from '../../layout/layout';
import Armstrong from '../../../assets/lexos_imgs/movingCos/armstrong.png';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationArrows from '../../layout/nav_arrows';

const ArmstrongProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeChange = () => {
    const url = "https://www.google.com/search?q=armstrong+moving+company+boston&sca_esv=a531d7c2178cf6c1&rlz=1C1CHBF_enUS924US924&sxsrf=ADLYWIJiskaeM3hfndp6ztW2SxVLgobHjQ%3A1728881127516&ei=56EMZ-enH--Y5OMP68CkqAE&ved=0ahUKEwint9uLiI2JAxVvDHkGHWsgCRUQ4dUDCBA&uact=5&oq=armstrong+moving+company+boston&gs_lp=Egxnd3Mtd2l6LXNlcnAiH2FybXN0cm9uZyBtb3ZpbmcgY29tcGFueSBib3N0b24yBRAhGKABMgUQIRigATIFECEYoAEyBRAhGKsCSNAbUIAQWOwZcAJ4AZABAJgBZKABkgWqAQM2LjG4AQPIAQD4AQGYAgmgAsIFwgIKEAAYsAMY1gQYR8ICDRAAGIAEGLADGEMYigXCAhMQLhiABBiwAxhDGMcBGIoFGK8BwgILEC4YgAQYxwEYrwHCAgUQABiABMICBhAAGBYYHsICDBAuGNEDGBYYxwEYHsICGhAuGIAEGMcBGK8BGJcFGNwEGN4EGOAE2AEBwgILEAAYgAQYhgMYigXCAggQABiABBiiBMICCBAAGKIEGIkFmAMAiAYBkAYKugYGCAEQARgUkgcDNi4zoAfKKQ&sclient=gws-wiz-serp#lrd=0x89e48142bafbf077:0xc26638644f92e155,1,,,";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // https://www.google.com/maps/place/The+Armstrong+Company/@42.1518497,-71.1150005,16z/data=!3m1!4b1!4m6!3m5!1s0x89e48142bafbf077:0xc26638644f92e155!8m2!3d42.1518458!4d-71.1101296!16s%2Fg%2F1vkxn8sm?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D
  const leaveReview = () => {
    // URL that should bring the user closer to the review section
    const mapsReviewUrl = `https://www.google.com/maps/place/The+Armstrong+Company/@42.1518497,-71.1150005,16z/data=!3m1!4b1!4m6!3m5!1s0x89e48142bafbf077:0xc26638644f92e155!8m2!3d42.1518458!4d-71.1101296!16s%2Fg%2F1vkxn8sm?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D`;
  
    window.open(mapsReviewUrl, '_blank', 'noopener,noreferrer');
  };

  const [originalFrom, setOriginalFrom] = useState(() => {
    return location.state?.originalFrom || location.state?.from?.pathname || '/';
  });

  // Use this function for navigation after viewing company profile
  const navigateToOriginalDestination = useCallback(() => {
    navigate(originalFrom, { replace: true });
  }, [navigate, originalFrom]);

  return (
    <Layout>
      <div className="company-profile-container">
        <div className="property-info-nav">
              <NavigationArrows
                onPrevClick={navigateToOriginalDestination}
                prevLabel={'Back'}
                inverted
              />
          </div>
        <div className="company-header">
          <div className="company-info">
            <div className="company-img-placeholder">
              <img src={Armstrong} alt="Company Logo" className="zeke" />
            </div>
            <div className="company-details">
              <h2 className="company-name">The Armstrong Company</h2>
              <div className="company-rating">
                <span className="rating-text">Rating: 4.6</span>
                <span className="stars">
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStarHalfAlt color="var(--primary-color)" />
                </span>
                <button onClick={routeChange} className="review-count">(217 reviews)</button>
              </div>
              <button onClick={leaveReview} className="share-btn">Share</button>
            </div>
          </div>
        </div>

        <div className="company-introduction">
          <h3>Introduction</h3>
          <p>
          The Armstrong Company formerly Humboldt Storage & Moving is your trusted Boston local and long-distance moving company since 1905.  In addition to our moving services, we offer state of the art warehousing and storage that includes climate control options. We are also part of United Van Lines, the most well-known national brand for interstate moving.  Let us help you relax and get moving!
          </p>
        </div>

        <div className="company-overview">
          <div className="overview-section">
            <h4>Overview</h4>
            <ul>
              <li>Serving since 1905</li>
              <li>Family-owned business</li>
              <li>Experts in moving & storage</li>
              <li>Working to reduce stress</li>
            </ul>
          </div>

          <div className="overview-section">
            <h4>Contact Information</h4>
            <ul>
            Contact with the moving company will begin once you hit 'Accept' on your preferred offer in the Offers Dashboard. The company will then reach out to confirm details and proceed with your move.
            </ul>
          </div>

          <div className="overview-section">
            <h4>Payment Methods</h4>
            <p>
            This LexPro accepts payments via Cash, Check, Credit Card, and ACH.
            </p>
          </div>

          <div className="overview-section">
            <h4>LexPro Gold Status</h4>
            <p>
              LexPro Gold's are among the highest-rated, most popular professionals on Lexos.
            </p>
          </div>
        </div>

        <div className="business-hours">
          <h4>Business Hours</h4>
          <table>
            <tbody>
              <tr>
                <td>Mon</td>
                <td>8:00 AM - 5:00 PM</td>
              </tr>
              {/* Add more days as needed */}
              <tr>
                <td>Tue</td>
                <td>8:00 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>8:00 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>8:00 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>8:00 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>8:00 AM - 12:00 PM</td>
              </tr>
              <tr>
                <td>Sun</td>
                <td>Closed</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="company-reviews">
          <h3>Reviews</h3>
          <section className="reviews-section">
            <div className="row text-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center mb-4 ">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "Incredibly attentive service in preparation and throughout the moving process.  The movers were efficient, polite and gentle with my furniture.  Although I never ever want to move again I would use Humboldt again, and definitely recommend them to anyone looking for a reputable company."
                </p>
                <p className="font-italic font-weight-normal mb-0">- Joan Laws</p>
              </div>
              <div className="col-md-6 mb-0">
                <div className="d-flex justify-content-center mb-4">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "I had the best experience with Armstrong/Humboldt Storage & Moving. The communication about my move was excellent; I was fully informed on everything at all times! The movers were fast and efficient! Everyone I had contact with within the company (from the staff to the mover’s) was friendly yet professional. 10/10 would recommend!"
                </p>
                <p className="font-italic font-weight-normal mb-0">- Sydnee Pennie</p>
              </div>
            </div>
          </section>
        </div>
      </div>

    </Layout>
  );
};

export default ArmstrongProfile;
