import React, {useState, useEffect} from "react";
import Layout from '../../layout/layout';
import { useNavigate, useLocation } from 'react-router-dom';
import Selector from '../../../assets/lexos_imgs/best_selection.png';
import { useConfirm   } from '../../homeOwner/context/alert';
import { getFunctions, httpsCallable } from 'firebase/functions';
// TODO: Map each possible moving company in moverSrcInfo
// TODOF: Each moving company profile picture should come from movingCompany table
import Armstrong from '../../../assets/lexos_imgs/movingCos/armstrong_logo_only.png';
import BestRate from '../../../assets/lexos_imgs/movingCos/best_rate.jpg';
import Flash from '../../../assets/lexos_imgs/movingCos/flash.png';
import Michaels from '../../../assets/lexos_imgs/movingCos/michaels.png';
import MoveMate from '../../../assets/lexos_imgs/movingCos/ove_mate.jpg';
import PinkZebra from '../../../assets/lexos_imgs/movingCos/Pink-Zebra-Logo.jpg';
import Rexton from '../../../assets/lexos_imgs/movingCos/RExton-logo-square.jpg';
import Amanat from '../../../assets/lexos_imgs/movingCos/amanat.png';
import SRM from '../../../assets/lexos_imgs/movingCos/srm.webp';
import Lexos from '../../../assets/lexos_imgs/logos/og-image.png';
import '../../landing_page/landing_page.css';
import { getAuth } from 'firebase/auth';



import './dashboard.css';
import { collection, query, orderBy, getDocs, where, limit, getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/fireconfig';
import OfferModal from './offer_modal';
import Relax from '../../../assets/lexos_imgs/mirror_relax.png';
import getOffers from "../../../firebase/use_offers";
import '../../landing_page/landing_page.css';
import { ToastContainer, toast } from 'react-toastify';

/* ******** USER DASHBOARD *******
*  Pulls from getOffers
*  handleAcceptOffer- updates status of offer, which is then reflected in getOffers
*/
const Dashboard = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState("");
    const { acceptedOffer, offers, loading, error } = getOffers();
    const [currentUser, setCurrentUser] = useState(null);
    const location = useLocation();



    // Add this useEffect to get the current user and their info
    useEffect(() => {
        const auth = getAuth();
        
        // Set up auth state listener
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setCurrentUser(user);
            } else {
                setCurrentUser(null);
                // Optionally redirect to login page
                // navigate('/login');
            }
        });

        // Cleanup subscription
        return () => unsubscribe();
    }, []);



    const moverSrcInfo = {
        'The Armstrong Company' : {link: "/armstrong-profile", icon: Armstrong},
        'Safe Responsible Movers' : {link: "/safe-responsible-movers-profile", icon: SRM},
        'Flash Moving & Storage' : {link: "/flash-profile", icon: Flash},
        'Pink Zebra Moving' : {link: "/pink-zebra-profile", icon: PinkZebra},
        "Michael's Moving & Storage" : {link: "/michaels-profile", icon: Michaels},
        'Move Mate Boston' : {link: "/move-mate-profile", icon: MoveMate},
        'Rexton Moving & Storage' : {link: "/rexton-profile", icon: Rexton},
        'Amanat Moving & Storage' : {link: "/amanat-profile", icon: Amanat},
        'Boston Best Rate Movers' : {link: "/boston-best-rate-profile", icon: BestRate},
        'Lexos Admin' : {link: "/armstrong-profile", icon: Lexos}
    }
    const confirm = useConfirm();
    const partnerLogos = [
        { src: Armstrong, path: '/armstrong-profile', alt: 'Armstrong' },
        { src: PinkZebra, path: '/pink-zebra-profile', alt: 'Pink Zebra' },
        { src: SRM, path: '/safe-responsible-movers-profile', alt: 'Safe Responsible Movers' },
        { src: Flash, path: '/flash-profile', alt: 'Flash' },
        { src: Michaels, path: '/michaels-profile', alt: 'Michaels' },
        { src: MoveMate, path: '/move-mate-profile', alt: 'Move Mate' },
        { src: Rexton, path: '/rexton-profile', alt: 'Rexton' },
        { src: Amanat, path: '/amanat-profile', alt: 'Amanat' },
        { src: BestRate, path: '/boston-best-rate-profile', alt: 'Boston Best Rate' }
      ];
      const logoClassMap = {
        [PinkZebra]: "pz",
        [BestRate]: "br",
        [MoveMate]: "mm",
        [Rexton]: "rr",
        [Amanat]: "am",
        [Michaels]: "mi"
      };

    const handleLogoClick = (path) => {
    navigate(path, { state: { from: location } });
    };

    // Extract Lexos Deposit Amt
    const fetchDepositAmount = (quoteValue) => {
        if(typeof(quoteValue) === 'object'){
            return ((quoteValue[0] + quoteValue[1]) / 2) * 0.1 * 100;
        }
        // IMPORTANT: else assumes the quoteValue is one number
        else{
            return quoteValue * 0.1 * 100;
        }
    }


    /*
    *
    *  Confirmation Emails
    *
    */
    const confirmUserOffer = async(movingName, movingEmail, moveDetailsId) => { 

            try {
            const response = await fetch('https://lexos-backend-547367433733.us-central1.run.app/api/contact/', {  // Update this URL if necessary
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                email: currentUser.email,
                moverEmail: movingEmail,
                moveDetailsId: moveDetailsId,
                fullName: movingName,
                confirmUserOffer: true // Flag to indicate that a welcome email should be sent
                }),
            });
            }catch (error) {
            console.error('Error sending user move confirmation email');
            }
        };
    
    //  Update the status of the offer after confirmation and process payment
    // @param key: the literal name of the moving company
    // @param value: 
    const handleAcceptOffer = async (key, value) => {
        try {
           
            const isConfirmed = await confirm(
                `By clicking 'Accept', you confirm that you want to proceed with ${key}. 
                They'll receive your contact information and reach out to confirm your move. 
                All other offers will be closed.`,
                "Accept",
                "Cancel",
                { title: "Accept This Offer?" }
            );
            
            if (isConfirmed) {
                if (!value.offerId) {
                    throw new Error('Invalid offer ID');
                }
                // Update other offers as 'lost'
                const offersRef = collection(db, 'offers');
                const otherOffersQuery = query(
                    offersRef, 
                    where("moveDetailsId", "==", value.moveDetailsId), 
                    where("movingCompany", "!=", key)
                );
                const otherOffersSnapshot = await getDocs(otherOffersQuery);
                const updatePromises = otherOffersSnapshot.docs.map(doc => 
                    updateDoc(doc.ref, { status: 'lost' })
                );
                await Promise.all(updatePromises);
                
                // Get the offer details first
                const q = query(offersRef, where("offerId", "==", value.offerId), orderBy('timestamp', 'desc'), limit(1));
                const querySnapshot = await getDocs(q);
                
                if (querySnapshot.empty) {
                    throw new Error('Offer not found');
                }
                
                const offerDoc = querySnapshot.docs[0];
                const offerData = offerDoc.data();
                
                await updateDoc(offerDoc.ref, { status: 'booked'});

                // Get moving company details
                const movingCompanyRef = doc(db, 'movingCompanies', offerData.movingCompanyId);
                const movingCompanyDoc = await getDoc(movingCompanyRef);
                
                if (!movingCompanyDoc.exists()) {
                    throw new Error('Moving company not found');
                }
                
                const movingCompanyData = movingCompanyDoc.data();
                const movingCompanyEmail = movingCompanyData.email;

                // Send confirmation emails - comment in dev and UNCOMMENT FOR PROD
                confirmUserOffer(key, movingCompanyEmail, value.moveDetailsId);



                // All payment related logic 
                if (!offerData.quoteValue) {
                    throw new Error('Quote amount not found in offer');
                }
                
                // Calculate 10% of the quote amount and convert to cents for Stripe
                // 2 substrings, cast to numbers, added and /2 , * .10 * 100
                const depositAmount = Math.round(fetchDepositAmount(offerData.quoteValue));
                // Call extract function to grab the to LexosAmt 
                // const numbers = quoteStr.match(/\d+/g);
           
                // if (!numbers || numbers.length !== 2) {
                //     throw new Error('Invalid quote amount format');
                // }
                
                // const minAmount = parseInt(numbers[0]);
                // const maxAmount = parseInt(numbers[1]);
                
                // const toLexosAmt = (minAmount + maxAmount) / 2;
                // //
                // const depositAmount = Math.round(toLexosAmt * 0.10 * 100);
                
                // Verify we have all required data
                if (!offerData.movingCompanyId) {
                    throw new Error('Moving company ID not found in offer');
                }
                const paymentData = {
                    amount: depositAmount,
                    offerId: value.offerId,
                    moveDetailsId: value.moveDetailsId,
                    companyId: offerData.movingCompanyId
                };
                if (!paymentData.amount || !paymentData.offerId || !paymentData.moveDetailsId || !paymentData.companyId) {
                    console.error('Missing required fields:', {
                        hasAmount: !!paymentData.amount,
                        hasOfferId: !!paymentData.offerId,
                        hasMoveDetailsId: !!paymentData.moveDetailsId,
                        hasCompanyId: !!paymentData.companyId
                    });
                    throw new Error('Missing required payment data fields');
                }
    
                
                // Create payment intent using Firebase Function
                try {
                    const functions = getFunctions();
                    const createPaymentIntentFn = httpsCallable(functions, 'createPaymentIntent');
                    
                    
                    const result = await createPaymentIntentFn({data: paymentData});
                    
                    
                    if (!result.data.clientSecret) {
                        console.error("Don't have clientSecret");
                        throw new Error('Payment intent creation failed');
                    }
                    
                    // Payment intent created successfully, now update the offer status
                    await updateDoc(offerDoc.ref, { 
                        paymentStatus: 'processing',
                        depositAmount: depositAmount / 100,
                        paymentIntentId: result.data.paymentIntentId
                    });

                    // Update moveDetails document
                    if (!value.moveDetailsId) {
                        console.error("Don't have moveDetailsId");
                        throw new Error('Move details ID not found');
                    }
                    
                    const moveDetailsRef = collection(db, 'moveDetails');
                    const moveDetailsQuery = query(
                        moveDetailsRef,
                        where('moveDetailsId', '==', value.moveDetailsId)
                    );
                    const moveDetailsSnapshot = await getDocs(moveDetailsQuery);
                    
                    if (!moveDetailsSnapshot.empty) {
                        const moveDetailsDoc = moveDetailsSnapshot.docs[0];
                        await updateDoc(moveDetailsDoc.ref, { 
                            acceptedOfferId: value.offerId,
                            paymentStatus: 'processing'
                        });
                    } else {
                        console.error("Don't have moveDetails for USER");
                        throw new Error("No moveDetails document found for the user");
                    }
            
                  
                    
                } catch (paymentError) {
                    console.error("Payment processing error:", paymentError);
                    await updateDoc(offerDoc.ref, { 
                        status: 'payment_failed',
                        paymentError: paymentError.message
                    });
                    
                    toast.error('Payment processing failed. Please try again or contact support.');
                    throw paymentError;
                }
            }
        } catch (error) {
            console.error("Error accepting offer:", error);
            toast.error(error.message || 'An error occurred while processing your request');
        }
    };

    //   Shows Modal based on the key and value parameters passed
    const handleViewOffer = (key, value) => {
      setSelectedOffer({ key, value, name: key });
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
    const navigate = useNavigate();



    // TODO: how to pull from firebase with format is on the view_listing file
    const handleInventoryClick = () => {
        navigate('/generate-inventory'); // This will navigate to a new inventory page
    };

    // Handles navigation to movers profiles of offers they received
    // Note: from: location is used such that navigation back to the dashboard page is possible
    const handleProfileClick = (key) => {
      navigate(`${moverSrcInfo[key].link}`, {state: {from: location}}); // This will navigate to the company profile page
    };
    

    // Render Offers
    // 1. Check if we have an acceptedOffer (from use_offers) and only present this offer
    // 2. Else display all offers from userOfferMap
    const renderOffers = () => {
        if (acceptedOffer.size > 0) {
            // PICKUP 10/21; use example of array from map to map key value, then pull value.moveDetailsId
            return (Array.from(acceptedOffer.entries()).map(([key, value]) => (
                <div className="col-md-4 mb-5" key={key}>
                <div className="card h-100">
                  <div className="card-body">
                    <img 
                      src={moverSrcInfo[key].icon} 
                      alt="Profile" 
                      className="profile-img" 
                      onClick={() => handleProfileClick(key)}
                      style={{ cursor: 'pointer' }}
                    />
                    <h3 className="card-title">{key}</h3>
                    <p className="card-text">
                      Estimate: {value.displayQuote}
                    </p>
                  </div>
                  <div className="card-footer">
                    {/* TODO: First item should be the view offer, second item should be the offer accepted text */}
                    <button className="btn btn-secondary btn-sm" onClick={() => handleViewOffer(key, value)}>View Offer</button>
                    <em classsName="accepted-offer-text">Offer Accepted!</em>
                    
                  </div>
                    <p className="touch-shortly">{key} will be in touch.</p>
                </div>
              </div>
            )));
        }
        else{
            return (Array.from(offers.entries()).map(([key, value]) => (
                <div className="col-md-4 mb-5" key={key}>
                    <div className="card h-100">
                        <div className="card-body">
                        <img 
                            src={moverSrcInfo[key].icon} 
                            alt="Profile" 
                            className="profile-img" 
                            onClick={() => handleProfileClick(key)} // Navigate to profile on image click
                            style={{ cursor: 'pointer' }} // Add pointer cursor to indicate clickability
                        />
                        <h3 className="card-title">{key || "Loading..."}</h3>
                        <p className="card-text">
                            Estimate: {value.displayQuote}
                        </p>
                        </div>
                        {/* Center card footer */}
                        <div className="card-footer">
                            <button className="btn btn-secondary btn-sm" onClick={() => handleViewOffer(key, value)}>View Offer</button>
                            <button className="btn accept-offer-btn" onClick={() => handleAcceptOffer(key, value)}>Accept Offer</button>
                        </div>
    
                    </div>
    
                </div>
    
            )));
        }

    }

// Main
  return (
    <Layout> 
    <div className = "dashboard-container">
        <div className="container px-4 px-lg-5">
        {/* Heading Row */}
        <div className="row gx-4 gx-lg-5 align-items-center my-5">
            <div className="col-lg-7">
            <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={Selector}
                alt="Offer Banner"
            />
            </div>
            <div className="col-lg-5">
            <h1 className="font-weight-light">My Offers</h1>
            <p>
                Moving companies will provide you with offers based on the property information you provided.
                They will include the quote amount, insurance coverage (if applicable), estimate type, move duration, and other relavant information. 
            </p>
            <button className="btn btn-inventory" onClick = {handleInventoryClick} disabled>View My Inventory</button>
            <p className= "view-soon"> Coming Soon!</p>
            </div>
        </div>

        {/* Call to Action */}
        <div className="card text-white bg-secondary my-5 py-4 text-center">
            <div className="card-body">
            <h4>
            By accepting an offer, the moving company will reach out to confirm your move. Lexos will never require any form of payment!
            </h4>
            </div>
        </div>
        {/* Add divider */}
        <div className="grey-dash-divider"></div>

        {/* Content Row */}
        {/* No Offers Yet Section */}
        {/*  */}
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {(!loading && !error && offers.size == 0) ?
        <div className="no-offers-section">
            <hr className="offers-hr-left" />
            <h2 className="no-offers-text">No Offers Yet!</h2>
            <hr className="offers-hr-right" />
            <img src={Relax} alt = "Relax" className="relax-img" />
            <p className="offers-info">Offers will show up here when moving companies view your move details</p>
        </div>
        : 
        <div className="row gx-4 gx-lg-5">
            {renderOffers()}
        </div>
        }
        <div className="dashboard-partners-container">
            <h3><strong>Lexos Moving Partners</strong></h3>
            <div className="partner-logos">
            {partnerLogos.map((logo, index) => (
                <div key={index} className="partner-logo-circle" onClick={() => handleLogoClick(logo.path)}>
                {/* Placeholder for logo image */}
                <img src={logo.src} className={logoClassMap[logo.src] || "logo-img"}alt={`Partner logo ${index + 1}`} />
                </div>
            ))}
            </div>
        </div>
        </div>
        {selectedOffer && (
            <OfferModal
                show={showModal}
                onClose={handleCloseModal}
                name={selectedOffer.name}
                offerDetails={selectedOffer.value}
                acceptOffer={handleAcceptOffer}
            />
        )}
    </div> 

    </Layout>
  );
};

export default Dashboard;