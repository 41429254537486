import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import '../../homeOwner/register/signup_login.css';
import './lexpro_login.css';

// Add this new CSS
const paymentStyles = {
  container: {
    maxWidth: '500px',
    margin: '0 auto',
    padding: '20px',
  },
  tabs: {
    display: 'flex',
    marginBottom: '20px',
    borderBottom: '2px solid rgba(255, 255, 255, 0.2)',
  },
  tab: {
    flex: 1,
    border: 'none',
    backgroundColor: 'transparent',
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'color 0.3s',
    padding: '10px 0',
  },
  activeTab: {
    color: '#fff',
    borderBottom: '2px solid #fff',
  },
  formContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    padding: '30px',
    borderRadius: '12px',
    marginTop: '20px',
  },
  input: {
    width: '100%',
    padding: '12px',
    marginBottom: '16px',
    borderRadius: '6px',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    backgroundColor: '#fff',
    color: '#fff',
    fontSize: '16px',
  },
  button: {
    width: '100%',
    padding: '14px',
    backgroundColor: '#fff',
    color: '#8851f7',
    border: 'none',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '600',
    cursor: 'pointer',
    transition: 'opacity 0.2s',
    marginTop: '20px',
  },
};

const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
    //   color: '#424770',
    //   '::placeholder': {
    //     color: '#aab7c4',
    //   },
      backgroundColor: '#fff',
      padding: '10px 12px',
      borderRadius: '4px',
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const BankAccountForm = ({ onSubmit, processing }) => (
  <form onSubmit={(e) => onSubmit(e, 'bank')} className="space-y-4">
    <h2>Coming Soon!</h2>
    {/* <div className="space-y-2">
      <input
        type="text"
        placeholder="Account Holder Name"
        required
        style={paymentStyles.input}
      />
    </div>
    <div className="space-y-2">
      <input
        type="text"
        placeholder="Routing Number"
        required
        style={paymentStyles.input}
      />
    </div>
    <div className="space-y-2">
      <input
        type="text"
        placeholder="Account Number"
        required
        style={paymentStyles.input}
      />
    </div>
    <button
      type="submit"
      disabled={processing}
      style={paymentStyles.button}
    >
      {processing ? 'Setting up...' : 'Use Bank Account'}
    </button> */}
  </form>
);

const PaymentSetupForm = ({ companyId, onComplete }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [activeTab, setActiveTab] = useState('card');
  const [zipCode, setZipCode] = useState('');

  const handleSubmit = async (e, type = 'card') => {
    e.preventDefault();
    setProcessing(true);
    setError(null);

    try {
      if (type === 'card') {
        if (!stripe || !elements) {
          throw new Error('Stripe not initialized');
        }

        const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            address: {
              postal_code: zipCode,
            },
          },
        });

        if (stripeError) {
          throw stripeError;
        }

        await setupPaymentMethod(paymentMethod.id, 'card');
      } else {
        // Handle bank account submission
        const formData = new FormData(e.target);
        const bankData = {
          account_holder_name: formData.get('account_holder_name'),
          routing_number: formData.get('routing_number'),
          account_number: formData.get('account_number'),
        };

        const { token, error: bankError } = await stripe.createToken('bank_account', {
          country: 'US',
          currency: 'usd',
          routing_number: bankData.routing_number,
          account_number: bankData.account_number,
          account_holder_name: bankData.account_holder_name,
          account_holder_type: 'individual',
        });

        if (bankError) {
          throw bankError;
        }

        await setupPaymentMethod(token.id, 'bank');
      }

      onComplete();
    } catch (err) {
      // catching api invalid key error here
      setError(err.message);
    } finally {
      setProcessing(false);
    }
  };

  const setupPaymentMethod = async (paymentMethodId, type) => {
    const response = await fetch(
      'https://us-central1-lexos-inc.cloudfunctions.net/setupPaymentMethod',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          paymentMethodId,
          companyId,
          type
        }),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to setup payment method');
    }
  };

  return (
    <div style={paymentStyles.container}>
    
      <div style={paymentStyles.tabs}>
        <button
          style={{
            ...paymentStyles.tab,
            ...(activeTab === 'card' && paymentStyles.activeTab),
          }}
          onClick={() => setActiveTab('card')}
        >
          Card
        </button>
        <button
          style={{
            ...paymentStyles.tab,
            ...(activeTab === 'bank' && paymentStyles.activeTab),
          }}
          onClick={() => setActiveTab('bank')}
        >
          Bank Account
        </button>
      </div>

      <div style={paymentStyles.formContainer}>
        {error && (
          <div className="error-message" style={{ marginBottom: '20px' }}>
            {error}
          </div>
        )}

        {activeTab === 'card' ? (
          <form onSubmit={(e) => handleSubmit(e, 'card')}>
            <div style={{
              backgroundColor: '#fff',
              padding: '15px',
              borderRadius: '6px',
              marginBottom: '16px',
            }}>
              <CardElement options={cardElementOptions} />
            </div>
            <button
              type="submit"
              disabled={!stripe || processing}
              style={paymentStyles.button}
            >
              {processing ? 'Setting up...' : 'Use Card'}
            </button>
          </form>
        ) : (
          <BankAccountForm onSubmit={handleSubmit} processing={processing} />
        )}
      </div>
    </div>
  );
};

export default PaymentSetupForm;