import React, {useState, useCallback} from 'react';
import './company_profile.css';
import Layout from '../../layout/layout';
import SRM from '../../../assets/lexos_imgs/movingCos/amanat.png';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationArrows from '../../layout/nav_arrows';

const MoveMateProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeChange = () => {
    const url = "https://www.google.com/search?q=Amanat+Moving+%26+Storage%3A&rlz=1C1CHBF_enUS924US924&oq=Amanat+Moving+%26+Storage%3A&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBBzI1NGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x89e39d7a9559c57d:0x24fb47480446c18b,1,,,,";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const leaveReview = () => {
    // URL that should bring the user closer to the review section
    const mapsReviewUrl = `https://www.google.com/maps/place/Amanat+Moving+%26+Storage/@42.4068355,-71.257978,17z/data=!3m2!4b1!5s0x89e39b0f822b8713:0xb3c7316a312151fd!4m6!3m5!1s0x89e39d7a9559c57d:0x24fb47480446c18b!8m2!3d42.4068316!4d-71.2554031!16s%2Fg%2F11qbbnhlks?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D`;
  
    window.open(mapsReviewUrl, '_blank', 'noopener,noreferrer');
  };


  const [originalFrom, setOriginalFrom] = useState(() => {
    return location.state?.originalFrom || location.state?.from?.pathname || '/';
  });

  // Use this function for navigation after viewing company profile
  const navigateToOriginalDestination = useCallback(() => {
    navigate(originalFrom, { replace: true });
  }, [navigate, originalFrom]);

  return (
    <Layout>
      <div className="company-profile-container">
        <div className="property-info-nav">
              <NavigationArrows
                onPrevClick={navigateToOriginalDestination}
                prevLabel={'Back'}
                inverted
              />
          </div>
        <div className="company-header">
          <div className="company-info">
            <div className="company-img-placeholder">
              <img src={SRM} alt="Company Logo" className="zeke" />
            </div>
            <div className="company-details">
              <h2 className="company-name">Amanat Moving & Storage</h2>
              <div className="company-rating">
                <span className="rating-text">Rating: 4.9</span>
                <span className="stars">
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                </span>
                <button onClick={routeChange} className="review-count">(131 reviews)</button>
              </div>
              <button onClick={leaveReview} className="share-btn">Share</button>
            </div>
          </div>
        </div>

        <div className="company-introduction">
          <h3>Introduction</h3>
          <p>
          At Amanat Moving Company, we understand that moving can be a stressful experience. That’s why we’re here to make it as smooth and hassle-free as possible. With our years of experience and dedication to customer satisfaction, we are committed to providing top-notch moving services tailored to your needs. 
          </p>
        </div>

        <div className="company-overview">
          <div className="overview-section">
            <h4>Overview</h4>
            <ul>
              <li>Serving all of Massachusetts and offering interstate moving services.</li>
              <li>Background checked</li>
              <li>95% customer satisfaction rate</li>
            </ul>
          </div>

          <div className="overview-section">
            <h4>Contact Information</h4>
            <ul>
            Contact with the moving company will begin once you hit 'Accept' on your preferred offer in the Offers Dashboard. The company will then reach out to confirm details and proceed with your move.
            </ul>
          </div>

          <div className="overview-section">
            <h4>Payment Methods</h4>
            <p>
            This LexPro accepts payments via Cash, Credit card, and Zelle.
            </p>
          </div>

          <div className="overview-section">
            <h4>LexPro Gold Status</h4>
            <p>
              LexPro Gold's are among the highest-rated, most popular professionals on Lexos.
            </p>
          </div>
        </div>

        <div className="business-hours">
          <h4>Business Hours</h4>
          <table>
            <tbody>
            <tr>
                <td>Mon</td>
                <td>8:00 AM - 8:00 PM</td>
              </tr>
              {/* Add more days as needed */}
              <tr>
                <td>Tue</td>
                <td>8:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>8:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>8:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>8:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>8:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Sun</td>
                <td>8:00 AM - 8:00 PM</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="company-reviews">
          <h3>Reviews</h3>
          <section className="reviews-section">
            <div className="row text-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center mb-4 ">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "I recently used Amanat Moving Company for my move, and I was very impressed with their service. John and Cori, the movers, were incredibly quick and efficient. They arrived on time and worked diligently to pack and move my belongings."
                </p>
                <p className="font-italic font-weight-normal mb-0">-Sandra Sylvain</p>
              </div>
              <div className="col-md-6 mb-0">
                <div className="d-flex justify-content-center mb-4">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "Showed up exactly on time as planned. Worked efficiently and professionally.Took care of the furniture. Extremely reasonable per hour rate. Thanks a lot guys!!"
                </p>
                <p className="font-italic font-weight-normal mb-0">-Syed Naeem</p>
              </div>
            </div>
          </section>
        </div>
      </div>

    </Layout>
  );
};

export default MoveMateProfile;
