import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../../../firebase/fireconfig';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/fireconfig';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isMovingCompany, setIsMovingCompany] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const isCompany = await checkIfMovingCompany(user.uid);
          setIsMovingCompany(isCompany);
        } catch (error) {
          console.error('Error checking moving company status:', error);
          setIsMovingCompany(false);
        }
      } else {
        setIsMovingCompany(false);
      }
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  async function checkIfMovingCompany(uid) {
    try {
      const companyDoc = await getDoc(doc(db, 'movingCompanies', uid));
      return companyDoc.exists();
    } catch (error) {
      console.error('Error checking moving company status:', error);
      return false;
    }
  }
  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return auth.signOut();
  };

  const value = {
    user,
    isMovingCompany,
    login,
    logout,
    loading
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};