import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db, functions } from '../../../firebase/fireconfig';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getAuth } from 'firebase/auth';
import Layout from '../../layout/layout';
import withMovingCompanyAuth from './lexpro_auth';
import '../../homeOwner/register/signup_login.css';
import './lexpro_login.css';
import PaymentSetupForm from './lexpro_paySetup';

const stripePromise = loadStripe(`${process.env.STRIPE_TEST_KEY}`);

function LexproLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPaymentSetup, setShowPaymentSetup] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if the user is a moving company
      const isMovingCompany = await checkIfMovingCompany(user.uid);
      
      if (isMovingCompany) {
        // Check if payment method is already setup
        const companyDoc = await getDoc(doc(db, 'movingCompanies', user.uid));
        const hasPaymentSetup = companyDoc.data()?.stripeCustomerId;

        if (!hasPaymentSetup) {
          setCurrentUserId(user.uid);
          setShowPaymentSetup(true);
        } else {
          navigate('/movers-dashboard'); // or wherever you want to redirect
        }
      } else {
        throw new Error('You are not authorized as a moving company.');
      }
    } catch (error) {
      console.error('Error with authentication:', error.message);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStripeSetup = async (uid) => {
    try {
      const response = await fetch(
        'http://127.0.0.1:5001/lexos-inc/us-central1/initializeStripeAccount',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ companyId: uid }),
        }
      );
  
      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }
  
      const result = await response.json();
  
      // Redirect to the Stripe onboarding link
      window.location.href = result.url;
    } catch (error) {
      console.error('Error setting up Stripe:', error);
      setErrorMessage('Failed to set up Stripe. Please try again.');
    }
  };

  const checkIfMovingCompany = async (uid) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
        throw new Error('No authenticated user found');
    }

    // Check if the UID matches the current authenticated user
    if (user.uid !== uid) {
        throw new Error('UID mismatch with authenticated user');
    }

    // Check if the user has a corresponding document in the movingCompanies collection
    const moversDocRef = doc(db, 'movingCompanies', uid);
    const moversDoc = await getDoc(moversDocRef);

    return moversDoc.exists();
  };

  const handlePaymentSetupComplete = () => {
    navigate('/movers-dashboard');
  };

  return (
    <Layout>
      <div className="lexpro-auth-container">
        <h1 className="lexpro-register-header">Welcome, LexPro</h1>
        <div className="auth-content">
          {!showPaymentSetup ? (
            <form onSubmit={handleSubmit}>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="password-input-wrapper">
                <input
                  type={loginPasswordVisible ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                />
                <button
                  type="button"
                  className="password-toggle-button"
                  onClick={() => setLoginPasswordVisible(!loginPasswordVisible)}
                  aria-label={loginPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  {loginPasswordVisible ? 'Hide' : 'Show'}
                </button>
              </div>
              <button type="submit" className="submit-button-login" disabled={isLoading}>
                {isLoading ? 'Signing In...' : 'Sign In'}
              </button>
            </form>
          ) : (
              <PaymentSetupForm 
                companyId={currentUserId}
                onComplete={handlePaymentSetupComplete}
              />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default LexproLogin;