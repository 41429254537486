import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './layout.css'; // Adjust the path as needed
import white_logo from '../../assets/lexos_imgs/full_logo_all_white_txt.png';
import { createGlobalStyle } from 'styled-components';
import fastDelivery from '../../assets/lexos_imgs/fast-delivery.png';
import UserAccountDropdown from '../dropdown/user_dropdown';
import { auth } from '../../firebase/fireconfig';
import { onAuthStateChanged } from 'firebase/auth';
import './headers.css';

const Layout = ({ children }) => {
  const [userName, setUserName] = useState(null);  // State to hold the user's name
  const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans', sans-serif;
  }
  `;
  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
    // Hook to check the user's authentication state and get their info
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // Assuming the user's displayName is set in Firebase
          const firstName = user.displayName?.split(' ')[0];  // Get the first name
          setUserName(firstName || '');  // Fallback to 'User' if no displayName is available
        } else {
          setUserName(null);  // Reset to null if no user is logged in
        }
      });
  
      // Cleanup the subscription on component unmount
      return () => unsubscribe();
    }, []);
  return (
    <div id="page-top" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      
      <GlobalStyle />
      {/* Navigation */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="navbar-container">
          <Link className="navbar-brand" to="/">
            <img src={white_logo} alt="lexos-white"  />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

            <div className="navbar-nav ms-auto">
              <div className="nav-item">
                
                <Link className="nav-link text-white become-lexpro" to="/welcome-lexpro">
                  <h3 className="lexText">Become a LexPro</h3>
                  <img className="truck" src={fastDelivery} alt="truck" />
                  <div className="street"></div>
                </Link>
              </div>
              <li className="nav-item">
                <UserAccountDropdown userName={userName} />
              </li>
            </div>
          </div>
      </nav>
      {/* Body */}
      <div className="main-content">
        {children}
      </div>
      {/* Footer */}
      <footer>
        <div className="footer-content">
          <div className="footer-left">
            <p>&copy; Lexos, Inc. 2024</p>
          </div>
          <div className="footer-right">
            <ul>
            <li><Link className="footer-nav-item" to="/contact">Contact</Link></li>
            <li><Link className="footer-nav-item" to="/privacy">Privacy Policy</Link></li>
              <li><Link className="footer-nav-item" to="/terms">Terms</Link></li>
              
              
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
