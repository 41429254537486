import React, {useState, useCallback} from 'react';
import './company_profile.css';
import Layout from '../../layout/layout';
import SRM from '../../../assets/lexos_imgs/movingCos/flash.png';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationArrows from '../../layout/nav_arrows';

const MoveMateProfile = () => {
  const navigate = useNavigate();
  const routeChange = () => {
    const url = "https://www.google.com/search?q=flash+moving+%26+storage&rlz=1C1CHBF_enUS924US924&oq=Flash+Moving+%26+Storage&gs_lcrp=EgZjaHJvbWUqBwgAEAAYgAQyBwgAEAAYgAQyBggBEEUYQDIICAIQABgWGB4yCggDEAAYgAQYogQyCggEEAAYgAQYogTSAQcxNThqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8&lqi=ChZmbGFzaCBtb3ZpbmcgJiBzdG9yYWdlSJbWzYr4qoCACFooEAAQARACEAMYABgBGAIYAyIWZmxhc2ggbW92aW5nICYgc3RvcmFnZZIBDm1vdmluZ19jb21wYW55qgE_EAEyHxABIhuzo0AvDXHsgj0IdGQxWefIWyjRjEU9lcGs3MUyGhACIhZmbGFzaCBtb3ZpbmcgJiBzdG9yYWdl4AEA#lkt=LocalPoiReviews&rlimm=1511155360576506420";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const leaveReview = () => {
    // URL that should bring the user closer to the review section
    const mapsReviewUrl = `https://www.google.com/maps/place/Flash+Moving+%26+Storage/@42.3596221,-71.2468106,17z/data=!3m1!4b1!4m6!3m5!1s0x89e37b2e7a2fbecd:0x83990be33e18d454!8m2!3d42.3596182!4d-71.2442357!16s%2Fg%2F11gh8lbz8p?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D`;
  
    window.open(mapsReviewUrl, '_blank', 'noopener,noreferrer');
  };

  const location = useLocation();
  const [originalFrom, setOriginalFrom] = useState(() => {
    return location.state?.originalFrom || location.state?.from?.pathname || '/';
  });

  // Use this function for navigation after viewing company profile
  const navigateToOriginalDestination = useCallback(() => {
    navigate(originalFrom, { replace: true });
  }, [navigate, originalFrom]);

  return (
    <Layout>
      <div className="company-profile-container">
        <div className="property-info-nav">
            <NavigationArrows
              onPrevClick={navigateToOriginalDestination}
              prevLabel={'Back'}
              inverted
            />
        </div>
        <div className="company-header">
          
          <div className="company-info">
            <div className="company-img-placeholder">
              <img src={SRM} alt="Company Logo" className="zeke" />
            </div>
            <div className="company-details">
              <h2 className="company-name">Flash Moving & Storage</h2>
              <div className="company-rating">
                <span className="rating-text">Rating: 4.9</span>
                <span className="stars">
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                </span>
                <button onClick={routeChange} className="review-count">(387 reviews)</button>
              </div>
              <button onClick={leaveReview} className="share-btn">Share</button>
            </div>
          </div>
        </div>

        <div className="company-introduction">
          <h3>Introduction</h3>
          <p>
          Our insured and licensed moving company offers commercial, residential, local, and long-distance moving services. We have 5+ years of experience in the moving business. That is why our business strategy aims to provide high-quality service and create excellent teamwork among professionals, making you feel safe and relaxed before, during, and after your move day. 
          </p>
        </div>

        <div className="company-overview">
          <div className="overview-section">
            <h4>Overview</h4>
            <ul>
              <li>Serving all of Massachusetts and offering interstate moving services.</li>
              <li>Background checked</li>
              <li>5+ years serving the Boston area</li>
            </ul>
          </div>

          <div className="overview-section">
            <h4>Contact Information</h4>
            <ul>
            Contact with the moving company will begin once you hit 'Accept' on your preferred offer in the Offers Dashboard. The company will then reach out to confirm details and proceed with your move.
            </ul>
          </div>

          <div className="overview-section">
            <h4>Payment Methods</h4>
            <p>
            This LexPro accepts payments via Cash, Credit card, and Zelle.
            </p>
          </div>

          <div className="overview-section">
            <h4>LexPro Gold Status</h4>
            <p>
              LexPro Gold's are among the highest-rated, most popular professionals on Lexos.
            </p>
          </div>
        </div>

        <div className="business-hours">
          <h4>Business Hours</h4>
          <table>
            <tbody>
            <tr>
                <td>Mon</td>
                <td>9:00 AM - 8:00 PM</td>
              </tr>
              {/* Add more days as needed */}
              <tr>
                <td>Tue</td>
                <td>9:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>9:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>9:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>9:00 AM - 8:00 PM</td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>10:00 AM - 4:00 PM</td>
              </tr>
              <tr>
                <td>Sun</td>
                <td>11:00 AM - 2:00 PM</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="company-reviews">
          <h3>Reviews</h3>
          <section className="reviews-section">
            <div className="row text-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center mb-4 ">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "Flash Movers did an amazing job with my belongings and antique furniture. I would hire them again. They also put together furniture should the need arise. Thank you so much for all your help.
                  "
                </p>
                <p className="font-italic font-weight-normal mb-0">-Melissa Parker</p>
              </div>
              <div className="col-md-6 mb-0">
                <div className="d-flex justify-content-center mb-4">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "I have used Flash twice and both local moves were great. The team showed up on time, I love the call ahead they make so you know when to expect them, they worked quickly & efficiently, no damage, etc.
                  "
                </p>
                <p className="font-italic font-weight-normal mb-0">-Edward Schiappa</p>
              </div>
            </div>
          </section>

        </div>
      </div>

    </Layout>
  );
};

export default MoveMateProfile;
