import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const PageRefreshWarning = () => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return null;
};

const LexosRefresh = ( {children}) => {
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    const handlePageHide = () => {
      setShowModal(true);
    };

    window.addEventListener('pagehide', handlePageHide);

    return () => {
      window.removeEventListener('pagehide', handlePageHide);
    };
  }, []);

  return (
    <>
      <PageRefreshWarning />
      {children}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to refresh the page. Any unsaved data will be lost.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </>
  );
};

export default LexosRefresh;