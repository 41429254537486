import React , {useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes , useLocation} from 'react-router-dom';
import {OpenAIProvider}  from './components/openai/openai_context';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
import { FormProvider } from './components/homeOwner/context/form_context';
import { ConfirmationProvider } from './components/homeOwner/context/alert';
import { AddressProvider } from './components/homeOwner/context/address_context';
import {UserProvider} from './components/homeOwner/context/user_context';
import './protect.css';
import LoadingScreen from './components/layout/loading_screen';

/* Auth Components */
import HomeRoute from './home_route';
import { ProtectedRoute } from './components/auth/protected_route';
import { ProtectedCompanyRoute } from './components/movingCompany/auth/protected_company_route';
import { MovingCompanyProvider } from './components/movingCompany/auth/movers_context';



/* Homeowner Components */
import SignupLogin from './components/homeOwner/register/homeowner-sign_log';
import ResetPassword from './components/homeOwner/register/forgot';
import LandingPage from './components/landing_page/landing_page';
import BeginTour from './components/homeOwner/tour/begin_tour';
import VirtualWalkthrough from './components/homeOwner/tour/virtual_walkthrough';
import GenerateInventory from './components/homeOwner/inventory/generate_inventory';
import Destination from './components/homeOwner/arrival/desination';
import FinalMessage from './components/homeOwner/review/final_message';
import FinalReview from './components/homeOwner/review/final_review';

import Terms from './policy/terms';
import Privacy from './policy/privacy';
import UserFAQ from './components/dropdown/user-faq';
import MoverFAQ from './components/dropdown/mover-faq';
import EditProfile from './components/dropdown/edit_profile_info';


/* Moving Company Components */
import MovingCompanyDashboard from './components/movingCompany/movers-dash';
import BidForm from './components/movingCompany/bidding/bid_form';
import ViewListing from './components/movingCompany/listings/view_listing';
import LexproLogin from './components/movingCompany/auth/lexpro_login';
import LeadAnalysis from './components/movingCompany/listings/lead_analysis';

import Dashboard from './components/homeOwner/dashboard/dashboard';
import Checklist from './components/dropdown/checklist';
import ContactForm from './components/layout/contact/contact_form';
import ViewBreakdown from './components/homeOwner/dashboard/view_breakdown';

import { AuthProvider } from './components/homeOwner/context/auth_context';
import PropertyInformation from './components/homeOwner/property_info/property_info';
import LexosRefresh from './components/layout/refresh_warn';


/* Company Profile Components- Temporarily Rendering as Individual Copmonents*/
import ArmstrongProfile from './components/movingCompany/profiles/armstrong_profile';
import SRMProfile from './components/movingCompany/profiles/srm_profile';
import MoveMateProfile from './components/movingCompany/profiles/move_mate_profile';
import BostonBestProfile from './components/movingCompany/profiles/boston-best_profile';
// Shower 
import MichaelsProfile from './components/movingCompany/profiles/michaels_profile';
import AmanatProfile from './components/movingCompany/profiles/amanat_profile';
import PinkZebraProfile from './components/movingCompany/profiles/pink_zebra_profile';
import RextonProfile from './components/movingCompany/profiles/rexton_profile';
import FlashProfile from './components/movingCompany/profiles/flash_profile';

/* Testing */
import OpenAIComponent from './components/openai/sample_return';
import ComingSoon from './components/layout/coming_soon';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_PUB_LIVE_KEY);


function NavigationLoader() {
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(true);

  // Add routes where you don't want the loader to appear
  const excludedRoutes = [
    '/generate-inventory',
    '/virtual-walkthrough'  // example
  ];

  // Check if current path should exclude loader
  const shouldShowLoader = !excludedRoutes.some(route => 
    location.pathname.startsWith(route)
  );

  React.useEffect(() => {
    if (!shouldShowLoader) return;

    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [location, shouldShowLoader]);

  return shouldShowLoader && isLoading ? <LoadingScreen /> : null;
}

function App() {
  return (
    <Elements stripe={stripePromise} >
    <OpenAIProvider>
      <ConfirmationProvider>
    <AddressProvider>
    <FormProvider>
    <UserProvider>
    <AuthProvider>
    <MovingCompanyProvider>
      <LexosRefresh>
      <Router>
      <NavigationLoader />
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {/* HomeOwner paths */}
          <Route path="/" element={ <HomeRoute /> } />
          <Route path="/login" element={<SignupLogin />} />
          <Route path="/forgot-password" element={<ResetPassword />} />
          <Route path="/property-info" element={<PropertyInformation />} />
          <Route path="/begin-tour" element={<BeginTour/>} />
          <Route path="/virtual-walkthrough" element={<VirtualWalkthrough />} />
          <Route path="/generate-inventory" element={<GenerateInventory />} />
          <Route path="/destination" element={<Destination />} />
          <Route 
            path="/review" 
            element={
              <ProtectedRoute>
                <FinalReview />
              </ProtectedRoute>
            } 
          />
          <Route path="/final-message" element={<FinalMessage />} />
          
          {/* Dropdown paths */}
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/view-breakdown" 
            element={
              <ProtectedRoute>
                <ViewBreakdown />
              </ProtectedRoute>
            } 
          />
          <Route path="/checklist" element = {<Checklist/>}/>
          <Route path="/contact" element={<ContactForm/>}/>

          {/* Moving Company Profiles */}
          <Route path="/armstrong-profile" element={<ArmstrongProfile />} />
          <Route path="/safe-responsible-movers-profile" element={<SRMProfile />} />
          <Route path="/move-mate-profile" element={<MoveMateProfile />} />
          <Route path="/boston-best-rate-profile" element={<BostonBestProfile />} />
          <Route path="/michaels-profile" element={<MichaelsProfile />} />
          <Route path="/amanat-profile" element={<AmanatProfile />} />
          <Route path="/pink-zebra-profile" element={<PinkZebraProfile />} />
          <Route path="/rexton-profile" element={<RextonProfile />} />
          <Route path="/flash-profile" element={<FlashProfile />} />

          {/* Nav bar paths */}
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element = {<Privacy/>}/>
          {/* Moving Company paths */}
          <Route path="/welcome-lexpro" element={<LexproLogin/>} />
          {/* Protected Moving Company Routes */}
          <Route
            path="/movers-dashboard"
            element={
              <ProtectedCompanyRoute>
                <MovingCompanyDashboard />
              </ProtectedCompanyRoute>
            }
          />
          <Route
            path="/bid-form/:moveDetailsId"
            element={
              <ProtectedCompanyRoute>
                <BidForm />
              </ProtectedCompanyRoute>
            }
          />
          <Route
            path="/view-listing/:moveDetailsId"
            element={
              <ProtectedCompanyRoute>
                <ViewListing />
              </ProtectedCompanyRoute>
            }
          />
          <Route
            path="/lead-analysis/:moveDetailsId"
            element={
              <ProtectedCompanyRoute>
                <LeadAnalysis />
              </ProtectedCompanyRoute>
            }
          />
          <Route
            path="/mover-FAQ"
            element={
              <ProtectedCompanyRoute>
                <MoverFAQ />
              </ProtectedCompanyRoute>
            }
          />
          {/* Coming soon - Movers */}
          <Route
            path="/movers-reviews"
            element={
              <ProtectedCompanyRoute>
                <ComingSoon />
              </ProtectedCompanyRoute>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <ProtectedCompanyRoute>
                <EditProfile/>
              </ProtectedCompanyRoute>
            }
          />
          {/* Reference later */}
          <Route path="/openai" element={<OpenAIComponent />} />
          <Route path="/about" element={<ComingSoon />} />
          <Route path="/FAQ-user" element={<UserFAQ />} />
          <Route path="/edit-move-info" element={<EditProfile />} />
          <Route path="/account" element={<ComingSoon />} />
        </Routes>
        </Suspense>
      </Router>
      </LexosRefresh>
      </MovingCompanyProvider>
    </AuthProvider>
    </UserProvider>
    </FormProvider>
    </AddressProvider>
    </ConfirmationProvider>
    </OpenAIProvider>
    </Elements>
  );
}

export default App;
