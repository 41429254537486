import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase/fireconfig'; // Adjust the import path as needed

const withMovingCompanyAuth = (WrappedComponent) => {
  return (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          // Check if the user is a moving company
          // You'll need to implement this check based on your data structure
          const isMovingCompany = await checkIfMovingCompany(user.uid);
          if (isMovingCompany) {
            setIsAuthenticated(true);
          } else {
            navigate('/become-lexpro');
          }
        } else {
          navigate('/become-lexpro');
        }
        setIsLoading(false);
      });

      return () => unsubscribe();
    }, [navigate]);

    if (isLoading) {
      return <div>Loading...</div>; // Or your custom loading component
    }

    return isAuthenticated ? <WrappedComponent {...props} /> : null;
  };
};

// You'll need to implement this function based on your Firestore structure
async function checkIfMovingCompany(uid) {
  // Example implementation:
  // const userDoc = await db.collection('users').doc(uid).get();
  // return userDoc.exists && userDoc.data().userType === 'movingCompany';
  return true; // Placeholder, replace with actual logic
}

export default withMovingCompanyAuth;