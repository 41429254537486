import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import useDetectScroll from '@smakss/react-scroll-direction';
import Layout from '../../layout/layout';
import { useConfirm }  from '../context/alert'

import {useOpenAI}  from '../../openai/openai_context';
import 'react-quill/dist/quill.snow.css';
import CopyComponent from '../../layout/copy';
import { FormContext } from '../context/form_context';
import './generate_inventory.css';
import DestinationSelectionPopup from './storage-popup';
import Trash from '../../../assets/lexos_imgs/icons/trash.png';
import InventoryIcon from '../../../assets/lexos_imgs/inventory.png';
import Item from '../../../assets/lexos_imgs/icons/items.png';
import Trolly from '../../../assets/lexos_imgs/icons/trolly.png';
import Value from '../../../assets/lexos_imgs/icons/handle-with-care.png';
import NavigationArrows from '../../layout/nav_arrows';
import { DetectOpenAI } from '../../openai/openai_util';
import {
  CheckboxText,
  InventoryTitle,
  StyledInventoryBox,
  HeaderRow,
  ContentArea,
  ScrollableRows,
  ItemColumn,
  ServiceColumn,
  HandledColumn,
  ButtonContainer,
  EditButton,
  InventoryContent,
  RoomTitleRow,
  CheckboxContainer,
  CheckboxLabel,
  SubCheckbox,
  EstimatedCostInput,
  EstimatedCostInputWrapper,
  DollarSign,
  TypingContainer,
  TypingCursor,
  ScrollArrow,
  CopyButtonWrapper,
  AnimatedItemRow,
  AnimatedCheckbox,
  RoomTitleWithCursor,
  EditButtonText,
  RoomTitleText,
} from './inventory_styles';


const GenerateInventory = () => {
  // State for inventory content and checkboxes
  const [allCheckboxes, setAllCheckboxes] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const location = useLocation();
  const isFromReview = location.state?.fromReview || false;
  const isFromAccess = location.state?.fromAccess || false;
  const confirm = useConfirm();

  // Form data from previous contexts
  const { formData, setFormData, inventoryItems, setInventoryItems, isNewInventory, setIsNewInventory } = useContext(FormContext);
  const { moveType } = formData;
  const { destinations, abbreviatedDestinations } = formData;

  const { openAIGenerator } = useOpenAI();

  // State for undo/redo functionality
  const [inventoryHistory, setInventoryHistory] = useState([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);

  // Typing effect state
  const [typingText, setTypingText] = useState('');
  const [currentTypingItem, setCurrentTypingItem] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ room: null, itemIndex: null, detailIndex: null });
  const contentAreaRef  = useRef(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);
  const isAutoScrollingRef = useRef(true);



  // State for processing inventory data
  const [roomOrder, setRoomOrder] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [cursorOnlyRoom, setCursorOnlyRoom] = useState(null);
  const [processedRooms, setProcessedRooms] = useState([]);
  const [isProcessingComplete, setIsProcessingComplete] = useState(false);
  const [processingQueue, setProcessingQueue] = useState([]);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  // Array of common items for disassembly/reassembly
  const assemblyItems = [
    'headboard', 'footboard', 'bed frame', 'bunk bed', 'canopy frame', 'base frame',
    'table legs', 'extension leaves', 'desk legs', 
  ];


  /* What type of inventory experience we have on navigation */ 
  // Should check if we're on mobile, and slightly scroll
  useEffect(() => {
    // Check if we're on mobile
    const isMobile = window.innerWidth <= 768;
    
    if (isMobile) {
      // Small timeout to ensure DOM is ready
      setTimeout(() => {
        // Scroll the outer container slightly (80 works on my phone)
        window.scrollTo({
          top: 80,
          behavior: 'smooth'
        });
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (processedRooms.length === roomOrder.length && isGenerating) {
      setIsProcessingComplete(true);
      setIsGenerating(false);
      setIsNextDisabled(false);
    }
  }, [processedRooms, roomOrder, isGenerating]);

  useEffect(() => {
    if (isNewInventory) {
      setInventoryItems({});
      setIsNewInventory(false);
      localStorage.removeItem('inventoryItems');
      setIsGenerating(true);
      setIsNextDisabled(true);
    } else {
      const storedInventory = localStorage.getItem('inventoryItems');
      if (storedInventory) {
        const parsedInventory = JSON.parse(storedInventory);
        setInventoryItems(parsedInventory);
        setRoomOrder(Object.keys(parsedInventory));
        setIsProcessingComplete(true);
        setIsNextDisabled(false);
      }
    }
  }, [location.state], setInventoryItems);

  useEffect(() => {
    localStorage.setItem('inventoryItems', JSON.stringify(inventoryItems));
  }, [inventoryItems]);

  /* ***** TODO UPDATE: Checkbox Changes *****
  * * 
  */
  /****** Service & High Value Checkbox Logic ****
   * 
   * 
   * 
   * 
   * 
   * 
  */




  const shouldCheckCheckbox = (checkboxType) => {
    switch (moveType) {
      case 'Packing, moving, and unpacking':
        return true; // Show all checkboxes
      case 'Packing and moving':
        return checkboxType !== 'isUnpacked';
      case 'Moving and unpacking':
        return checkboxType !== 'isPacked';
      case 'Packing and unpacking':
        return checkboxType !== 'isMoved';
      case 'Just moving':
        return checkboxType === 'isMoved';
      case 'Just packing':
        return checkboxType === 'isPacked';
      case 'Just unpacking':
        return checkboxType === 'isUnpacked';
      default:
        return true; // Default behavior is to show all checkboxes
    }
  };
  const handleServiceChange = (room, itemIndex, service, checked) => {
    setInventoryItems(prevItems => {
      const newItems = {
        ...prevItems,
        [room]: prevItems[room].map((item, index) => 
          index === itemIndex ? { ...item, [service]: checked } : item
        )
      };
  
      // After updating individual item, check if ALL items have this service checked
      const allChecked = newItems[room].every(item => item[service]);
      
      // Update the "all" checkbox state
      setAllCheckboxes(prev => ({
        ...prev,
        [room]: { ...prev[room], [service]: allChecked }
      }));
  
      return newItems;
    });
  };

  const handleAllCheckboxChange = (room, service, checked) => {
    setAllCheckboxes(prev => ({
      ...prev,
      [room]: { ...prev[room], [service]: checked }
    }));
  
    setInventoryItems(prevItems => ({
      ...prevItems,
      [room]: prevItems[room].map(item => ({
        ...item,
        [service]: checked,
        ...(service === 'isPacked' && { needsPacking: !checked })
      }))
    }));
  };
  

  /* Handling and Destination Checkbox Logic 
  *  
  * handleHighValueChange
  * handleEstimatedCostChange
  *
  *   *
  *
  *
  *
  */
  const handleHighValueChange = (room, itemIndex, isHighValue) => {
    setInventoryItems(prevItems => ({
      ...prevItems,
      [room]: prevItems[room].map((item, index) => 
        index === itemIndex ? { ...item, isHighValue, estimatedCost: isHighValue ? '' : null } : item
      )
    }));
  };

  const handleEstimatedCostChange = (room, itemIndex, cost) => {
    setInventoryItems(prevItems => ({
      ...prevItems,
      [room]: prevItems[room].map((item, index) => 
        index === itemIndex ? { ...item, estimatedCost: cost } : item
      )
    }));
  };


  /* Destination Change Logic 
  *
  *
  */

    useEffect(() => {
    const updateInventoryDestinations = () => {
      if(!isNewInventory){
        const defaultDestination = abbreviatedDestinations[0] || "No destination set";
      
        const updatedInventoryItems = Object.entries(inventoryItems).reduce((acc, [room, items]) => {
          acc[room] = items.map(item => {
            if (!abbreviatedDestinations.includes(item.destination)) {
              return {
                ...item,
                destination: defaultDestination,
                goingToDifferentAddress: false
              };
            }
            return item;
          });
          return acc;
        }, {});
  
        setInventoryItems(updatedInventoryItems);
      };
    };


    updateInventoryDestinations();
  }, [abbreviatedDestinations]);


  const handleDestinationChange = (room, itemIndex, newDestination) => {
    setInventoryItems(prevItems => ({
      ...prevItems,
      [room]: prevItems[room].map((item, index) => 
        index === itemIndex ? {
          ...item,
          destination: newDestination || abbreviatedDestinations[0],
          goingToStorage: false,
          goingToDifferentAddress: newDestination !== abbreviatedDestinations[0] && newDestination !== null
        } : item
      )
    }));
  };

  const handleGoingToDifferentAddress = (room, itemIndex, checked) => {
    // Only show popup if there are 3+ destinations
    const shouldShowPopup = abbreviatedDestinations.length > 2;

    if (checked) {
      if (shouldShowPopup) {
        setShowPopup(true);
        setCurrentItemIndex(itemIndex);
        setCurrentRoom(room);
      } else if (abbreviatedDestinations.length === 2) {
        // If exactly 2 destinations, automatically select the second one
        handleDestinationChange(room, itemIndex, abbreviatedDestinations[1]);
      }
    } else {
      handleDestinationChange(room, itemIndex, abbreviatedDestinations[0]);
    }
    
    setInventoryItems(prevItems => ({
      ...prevItems,
      [room]: prevItems[room].map((item, index) => 
        index === itemIndex ? {
          ...item,
          goingToDifferentAddress: checked,
          goingToStorage: false,
          destination: checked && abbreviatedDestinations.length === 2 ? abbreviatedDestinations[1] 
          : checked ? null : abbreviatedDestinations[0]
        } : item
      )
    }));
  };



  const handleAddDestination = (newAddress) => {
    const abbreviated = newAddress.split(',').slice(0, 2).join(',');
    setFormData(prevData => ({
      ...prevData,
      destinations: [...prevData.destinations, newAddress],
      abbreviatedDestinations: [...prevData.abbreviatedDestinations, abbreviated]
    }));
  };

  const handleRemoveDestinations = (addressToRemove) => {
    setFormData(prevData => ({
      ...prevData,
      destinations: prevData.destinations.filter(address => addressToRemove !== address),
      abbreviatedDestinations: prevData.abbreviatedDestinations.filter(address => addressToRemove !== address)
    }));
  };
  
  const handleGoingToStorage = (room, itemIndex, checked) => {
    setInventoryItems(prevItems => ({
      ...prevItems,
      [room]: prevItems[room].map((item, index) => 
        index === itemIndex ? {
          ...item,
          goingToStorage: checked,
          destination: checked ? null : abbreviatedDestinations[0],
          goingToDifferentAddress: false
        } : item
      )
    }));
  };

  /* ************** Core Functionality ************************ */
  /* ****************** ****************** ****************** 
  * 
  *
  * 
  *  
  * 
  * 
  * 
  */
  

  /* *****  *****
  * extractInventoryItems - Extracts inventory items from the parameter "analysis"
    ** TODO
  * First UseEffect - 
  * Second USeEffect - Typing Effect Logic
  * 
  * 
  */
  const extractInventoryItems = (analysis) => {
    const lines = analysis.split('\n');
    const items = [];
    let currentItem = null;

    lines.forEach(line => {
      line = line.trim();
      if (line.startsWith('-') && !line.startsWith('--')) {
        if (currentItem) items.push(currentItem);
        currentItem = { name: line.slice(1).trim(), details: [],           
          isMoved: true,
          isPacked: true,
          isUnpacked: true,
          isHighValue: false,
          furnitureAssembly: false,
          estimatedCost: null};
      } else if (line.startsWith('--') && currentItem) {
        currentItem.details.push(line.slice(2).trim());
      }
    });
    if (currentItem) items.push(currentItem);

    return items;
  };



  /* ******* Effect for processing inventory data ********* */
  useEffect(() => {
    if (isNewInventory){ 
      const processInventory = async () => {
        setIsGenerating(true);
        const { initialResult, photosByRoom } = location.state;
        if(initialResult.analysis === 'No significant items detected for moving.'){
          setIsGenerating(false);
          const shouldReturn = await confirm(
            "Please try again! Your photo(s) were unable to be processed",
            "Try Again",
            null, // Pass null to hide the cancel button
            { 
              title: "Error Processing Photos",
              // Use custom styles to match the error state
              className: "error-modal",
            }
          );
          navigate('/virtual-walkthrough', { state: location.state });
          return;
        }
        const roomNames = Object.keys(photosByRoom);
        setRoomOrder(roomNames);
    
        // Process the initial result first
        if (initialResult) {
          setProcessingQueue([initialResult]);
        }
    
        // Set cursor for all rooms initially
        setRoomOrder(roomNames);
        setCursorOnlyRoom(roomNames[0]);
    
        // Continue processing the remaining rooms using the generator from the context
        if (openAIGenerator) {
          for await (const result of openAIGenerator) {
            if (result.error) {
              console.error('Error:', result.error);
            } else {
              setProcessingQueue(prevQueue => [...prevQueue, result]);
            }
          }
          setIsProcessingComplete(true);
        }
      };
    
      processInventory();
    }
  }, [location.state, openAIGenerator]);

  
  useEffect(() => {
    const processNextRoom = async () => {
      if (processingQueue.length > 0 && !currentRoom) {
        const nextRoom = processingQueue[0];
        setProcessingQueue(prevQueue => prevQueue.slice(1)); // Remove the processed room from the queue
        setCursorOnlyRoom(nextRoom.room); // Display the cursor while waiting for the room to be processed
        await processRoom(nextRoom);
      } else if (processingQueue.length === 0) {
      }
    };
  
    processNextRoom();
  }, [processingQueue, currentRoom]);


  
  const processRoom = async (result) => {
    const { room, analysis } = result;
    const items = extractInventoryItems(analysis);
    setCurrentRoom(room);
  
    for (let item of items) {
      setCurrentTypingItem(item);
      await typeItem(item);
      /* If we're on the last item, scroll to the bottom of each room */
      if(item.name === items[items.length-1].name && isAutoScrollingRef.current){
        scrollToBottom(room);
      }

      const processedItem = {
        ...item,
        isMoved: shouldCheckCheckbox('isMoved'),
        isPacked: shouldCheckCheckbox('isPacked'),
        isUnpacked: shouldCheckCheckbox('isUnpacked'),
        furnitureAssembly: false,
      };

      setAllCheckboxes(prev => ({
        ...prev,
        [room]: {
          isMoved: shouldCheckCheckbox('isMoved'),
          isPacked: shouldCheckCheckbox('isPacked'),
          isUnpacked: shouldCheckCheckbox('isUnpacked'),
        }
      }));

      setInventoryItems(prev => ({
        ...prev,
        [room]: [...(prev[room] || []), processedItem],
      }));
    }
    setCurrentTypingItem(null);
    setCurrentRoom(null);
    setCursorOnlyRoom(null); // Clear the cursor-only state after processing
    setProcessedRooms(prev => [...prev, room]);
  };


/* Effect for typing with inline cursor */
const typeItem = async (item) => {
  const fullText = `${item.name}\n${item.details.map((detail) => `  - ${detail}`).join('\n')}`;
  for (let i = 0; i <= fullText.length; i++) {
    setTypingText(fullText.slice(0, i));
    // Update cursor position
    if (i === 0) {
      setCursorPosition({ room: currentRoom, itemIndex: inventoryItems[currentRoom]?.length || 0, detailIndex: null });
    } else if (i > item.name.length) {
      const detailIndex = fullText.slice(0, i).split('\n').length - 2;
      setCursorPosition({ room: currentRoom, itemIndex: inventoryItems[currentRoom]?.length || 0, detailIndex });
    }
    // setTimeout(scrollToCursor, 0);
    await new Promise((resolve) => setTimeout(resolve, 15)); // Adjust typing speed as needed
  }
  setTypingText(''); // Clear text once typing is done
  setCursorPosition({ room: null, itemIndex: null, detailIndex: null });
  };

  /* 
  * Scroll Functions
  * 0. useEffect- detects if auto scroll should be in place
  * 1. scrollToCursor
  * 2. scrollToBottom - use in addItem as well as at end of inventory
  */

  const {scrollDir, scrollPosition} = useDetectScroll({target: contentAreaRef.current});

  useEffect(() => {
    isAutoScrollingRef.current = isAutoScrolling;
  }, [isAutoScrolling]);

  useEffect(() => {
    if (contentAreaRef.current && isGenerating && scrollDir === "up" ){
      setIsAutoScrolling(false);
      isAutoScrollingRef.current = false;
    }
  }, [isGenerating, scrollDir]);

  /* Scrolls to location of cursor within the content area */
  useEffect(() => {
    if (contentAreaRef.current && isAutoScrolling) {
      const cursorElement = document.querySelector('.typing-cursor');
      if (cursorElement) {
        const containerElement = contentAreaRef.current;
        
        const cursorRect = cursorElement.getBoundingClientRect();
        const containerRect = containerElement.getBoundingClientRect();
        
        if (cursorRect.bottom > containerRect.bottom) {
          containerElement.scrollTop += (cursorRect.bottom - containerRect.bottom) + 20; // 20px extra for padding
        } else if (cursorRect.top < containerRect.top) {
          containerElement.scrollTop -= (containerRect.top - cursorRect.top) + 20; // 20px extra for padding
        }
      }
    }
  }, [isAutoScrolling, contentAreaRef, cursorPosition]);

  const handleResumeScrolling = () => {
    setIsAutoScrolling(true);
    const cursorElement = document.querySelector('.typing-cursor');
    if (cursorElement) {
      const containerElement = contentAreaRef.current;
      
      const cursorRect = cursorElement.getBoundingClientRect();
      const containerRect = containerElement.getBoundingClientRect();
      
      if (cursorRect.bottom > containerRect.bottom) {
        containerElement.scroll({top: cursorRect.bottom - containerRect.bottom + 20, behavior: "smooth"}); // 20px extra for padding
      } else if (cursorRect.top < containerRect.top) {
        containerElement.scroll({top: cursorRect.bottom - containerRect.bottom + 20, behavior: "smooth"}); // 20px extra for padding
      }
    }
  }

  /* Scroll to the bottom of the last item within a given room */
  const scrollToBottom = useCallback((room, timeout = 0) => {
      setTimeout(() => {
        const contentArea = document.querySelector('.content-area');


        if (contentArea) {
          const roomElements = contentArea.querySelectorAll(`[data-room="${room}"]`);


          if (roomElements.length > 0) {
            const lastRoomElement = roomElements[roomElements.length - 1];
            const itemElements = lastRoomElement.querySelectorAll('.item-column');


            if (itemElements.length > 0) {
              const lastItemElement = itemElements[itemElements.length - 1];


              lastItemElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            } else {
              console.error(`No item elements found in room ${room}`);
            }
          } else {
            console.error(`No room elements found for ${room}`);
          }
        } else {
          console.error('Content area not found');
        }
      }, timeout)
    
  }, []);
 

  /* *************************************** */


  /* ***** Edit Inventory Logic *****
  * handleEditToggle - Toggles between "edit" and "done" modes
  * handleItemChange - Handles changes to an item's name
  * handleDetailChange - Handles changes to an item's detail
  * handleAddNewItem - Adds a new item to the inventory
  * handleDeleteItem - Deletes an item from the inventory
  * saveToHistory - Saves the current inventory state to history
  * handleUndo - Undoes the last inventory change
  * handleRedo - Redoes the last inventory change
  * Second UseEffect - Saves the current state to history when editing
  * 
  */
  const handleEditToggle = () => {
    if (isEditing) {
      // On toggling from "edit" to "done," check if any items have an empty name and remove them
      const cleanedInventoryItems = {};
      Object.entries(inventoryItems).forEach(([room, items]) => {
        cleanedInventoryItems[room] = items.filter((item) => item.name.trim() !== '');
      });
      setInventoryItems(cleanedInventoryItems);
    }
    setIsEditing(!isEditing);
  };


  const handleItemChange = (room, itemIndex, newValue) => {
    setInventoryItems((prevItems) => {
      const updatedItems = prevItems[room].map((item, index) =>
        index === itemIndex ? { ...item, name: newValue } : item
      );
      // Automatically remove the item if its name is empty
      if (newValue.trim() === '') {
        return {
          ...prevItems,
          [room]: updatedItems.filter((item, index) => index !== itemIndex)
        };
      }
      return {
        ...prevItems,
        [room]: updatedItems
      };
    });
  };
  const handleDetailChange = (room, itemIndex, detailIndex, newValue) => {
    setInventoryItems((prevItems) => ({
      ...prevItems,
      [room]: prevItems[room].map((item, index) =>
        index === itemIndex
          ? {
              ...item,
              details: item.details.map((detail, idx) =>
                idx === detailIndex ? newValue : detail
              ),
            }
          : item
      ),
    }));
  };
  const handleAddNewItem = (room) => {

  
    setIsEditing(true);
    setInventoryItems((prevItems) => {
      const updatedRoomItems = [
        ...(prevItems[room] || []),
        {
          name: ' ',
          details: [' '],
          isMoved: true,
          isPacked: true,
          isUnpacked: true,
          isHighValue: false,
          furnitureAssembly: false,
          estimatedCost: '',
        },
      ];
  
      scrollToBottom(room, 100);
  
      return {
        ...prevItems,
        [room]: updatedRoomItems,
      };
    });
  };
  const handleDeleteItem = (room, itemIndex) => {
    const updatedInventoryItems = {
      ...inventoryItems,
      [room]: inventoryItems[room].filter((item, index) => index !== itemIndex),
    };
    
    // Save the new state to history.
    saveToHistory(updatedInventoryItems);
    
    // Set the new state.
    setInventoryItems(updatedInventoryItems);
  };
  const saveToHistory = (newInventoryItems) => {
    // Prevent saving to history if the new state is identical to the current one.
    if (currentHistoryIndex >= 0 && inventoryHistory[currentHistoryIndex] === newInventoryItems) {
      return; // Don't save if the state hasn't changed.
    }
    
    const newHistory = inventoryHistory.slice(0, currentHistoryIndex + 1); // Remove "future" history when a new change is made.
    newHistory.push(newInventoryItems);
    setInventoryHistory(newHistory);
    setCurrentHistoryIndex(newHistory.length - 1);
  };
  const handleUndo = () => {
    if (currentHistoryIndex >= 0) {
      setCurrentHistoryIndex((prevIndex) => prevIndex - 1);
      setInventoryItems(inventoryHistory[currentHistoryIndex - 1]);
    }
  };
  
  const handleRedo = () => {
    if (currentHistoryIndex <= inventoryHistory.length - 1) {
      setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
      setInventoryItems(inventoryHistory[currentHistoryIndex + 1]);
    }
  };
  useEffect(() => {
    if (isEditing) {
      saveToHistory(inventoryItems); // Automatically saves the current state when editing
    }
  }, [inventoryItems, isEditing]);

  



  /* *************************************** */

  /* ***** Navigation Logic ***** */

  const navigate = useNavigate();

  /* Keep the state if we navigate back to the previous page */
  const handleBack = async () => {
    if (isFromReview) {
      navigate('/review', { state: { ...location.state, inventoryItems } });
    } else if (isFromAccess){
      navigate('/property-info', { state: { ...location.state, step: 'access' , fromInventory: true, inventoryItems } });
    } else {
      try {
        const isConfirmed = await confirm(
          "Returning to the previous page will resubmit your photos for a new inventory generation.",
          "Restart",
          "Cancel",
          { title: "Restart Virtual Walkthrough?" }
        );
        
        if (isConfirmed) {
          navigate('/virtual-walkthrough', { state: location.state });
        }
      } catch (error) {
        console.error("Confirmation was dismissed or failed", error);
      }
    }
  };

/* *************************************** */

const renderInventory = () => {
  return (
    <ScrollableRows>
      {roomOrder.map((room) => (
        <React.Fragment key={room}>
          <RoomTitleRow>
            <ItemColumn>
              <RoomTitleWithCursor>
                <RoomTitleText>{room}</RoomTitleText>
                {(cursorOnlyRoom === room || (!inventoryItems[room] && !processedRooms.includes(room))) && <TypingCursor />}
              </RoomTitleWithCursor>
              <button
                  className="add-item-button"
                  style={{ marginTop: '20px', marginBottom: '10px' }} // Adjust the margin as needed
                  onClick={() => handleAddNewItem(room)}
                >
                  + Add Item
                </button>
            </ItemColumn>
            <ServiceColumn>
            <CheckboxContainer>
              <CheckboxLabel>
                <AnimatedCheckbox
                  type="checkbox"
                  checked={allCheckboxes[room]?.isMoved}
                  onChange={(e) => handleAllCheckboxChange(room, 'isMoved', e.target.checked)}
                  defaultChecked={shouldCheckCheckbox('isMoved')}
                />
                Move all 
              </CheckboxLabel>

              <CheckboxLabel>
                <AnimatedCheckbox
                  type="checkbox"
                  checked={allCheckboxes[room]?.isPacked}
                  onChange={(e) => handleAllCheckboxChange(room, 'isPacked', e.target.checked)}
                  defaultChecked={shouldCheckCheckbox('isPacked')}
                />
                Pack all 
              </CheckboxLabel>

              <CheckboxLabel>
                <AnimatedCheckbox
                  type="checkbox"
                  checked={allCheckboxes[room]?.isUnpacked}
                  onChange={(e) => handleAllCheckboxChange(room, 'isUnpacked', e.target.checked)}
                  defaultChecked={shouldCheckCheckbox('isUnpacked')}
                />
                Unpack all 
              </CheckboxLabel>
            </CheckboxContainer>
            </ServiceColumn>
            <HandledColumn>
              {/* Empty for room title row */}
            </HandledColumn>
          </RoomTitleRow>
          {inventoryItems[room]?.map((item, itemIndex) => (
            <AnimatedItemRow className="item-row" key={`${room}-${itemIndex}`} data-room={room}>
              <ItemColumn className="item-column">
                <InventoryContent>
                  <div className="item-details">
                    {isEditing ? (
                      // Editing Mode
                      <input
                        type="text"
                        value={item.name}
                        onChange={(e) => handleItemChange(room, itemIndex, e.target.value)}
                        className="edit-input"
                      />
                    ) : (
                      <div>
                        <strong>{item.name}</strong>
                        {cursorPosition.room === room &&
                        cursorPosition.itemIndex === itemIndex &&
                        cursorPosition.detailIndex === null }
                      </div>
                    )}
                    {item.details.map((detail, detailIndex) => (
                      <div key={detailIndex} style={{ paddingLeft: '20px' }}>
                        {isEditing ? (
                          <input
                            type="text"
                            value={detail}
                            onChange={(e) => handleDetailChange(room, itemIndex, detailIndex, e.target.value)}
                            className="edit-input"
                          />
                        ) : (
                          <div>
                            - {detail}
                            {cursorPosition.room === room &&
                              cursorPosition.itemIndex === itemIndex &&
                              cursorPosition.detailIndex === detailIndex }
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <ItemColumn className="delete-icon-column">
                    {isEditing && (
                      <img
                        src={Trash}
                        alt="Delete"
                        className="delete-icon"
                        onClick={() => handleDeleteItem(room, itemIndex)}
                      />
                    )}
                  </ItemColumn>
                </InventoryContent>
              </ItemColumn>
              <ServiceColumn>
                <CheckboxContainer>

                    <CheckboxLabel>
                    <AnimatedCheckbox
                      type="checkbox"
                      checked={item.isMoved} // Use the item's state directly
                      onChange={(e) => handleServiceChange(room, itemIndex, 'isMoved', e.target.checked)}
                      defaultChecked={shouldCheckCheckbox('isMoved')}
                    />
                      <CheckboxText>Moved</CheckboxText>
                    </CheckboxLabel>


                    <CheckboxLabel>
                    <AnimatedCheckbox
                      type="checkbox"
                      checked={item.isPacked} // Use the item's state directly
                      onChange={(e) => handleServiceChange(room, itemIndex, 'isPacked', e.target.checked)}
                      defaultChecked={shouldCheckCheckbox('isPacked')}
                    />
                      <CheckboxText>Packed</CheckboxText>
                    </CheckboxLabel>


                    <CheckboxLabel>
                    <AnimatedCheckbox
                      type="checkbox"
                      checked={item.isUnpacked} // Use the item's state directly
                      onChange={(e) => handleServiceChange(room, itemIndex, 'isUnpacked', e.target.checked)}
                      defaultChecked={shouldCheckCheckbox('isUnpacked')}
                    />
                      <CheckboxText>Unpacked</CheckboxText>
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <AnimatedCheckbox
                        type="checkbox"
                        checked={item.furnitureAssembly}
                        onChange={(e) => handleServiceChange(room, itemIndex, 'furnitureAssembly', e.target.checked)}
                      />
                      <CheckboxText>Assembly/disassembly?</CheckboxText>
                    </CheckboxLabel>
                </CheckboxContainer>
              </ServiceColumn>
              {/* Handle Column */}
              <HandledColumn>
              <CheckboxContainer>
                    <CheckboxLabel>
                      <AnimatedCheckbox
                        type="checkbox"
                        checked={!item.goingToDifferentAddress && !item.goingToStorage}
                        onChange={(e) =>
                          handleDestinationChange(room, itemIndex, e.target.checked ? abbreviatedDestinations[0] : null)
                        }
                      />
                      {`To: ${abbreviatedDestinations[0]}`}
                    </CheckboxLabel>
                    {abbreviatedDestinations.length <= 1 
                          ? null 
                          :
                    <CheckboxLabel>
                      <AnimatedCheckbox
                        type="checkbox"
                        checked={item.goingToDifferentAddress}
                        onChange={(e) => handleGoingToDifferentAddress(room, itemIndex, e.target.checked)}
                      />
                      <CheckboxText>
                        {abbreviatedDestinations.length <= 1 
                          ? null // Don't show the checkbox at all
                          : abbreviatedDestinations.length === 2
                            ? `To: ${abbreviatedDestinations[1]}` // Show second destination
                            : item.destination && item.destination !== abbreviatedDestinations[0]
                              ? `To: ${item.destination}` // Show selected destination
                              : 'Select different address' // Default text for 3+ destinations
                        }
                      </CheckboxText>
                    </CheckboxLabel>
                    }

                    <CheckboxLabel>
                      <AnimatedCheckbox
                        type="checkbox"
                        checked={item.goingToStorage}
                        onChange={(e) => handleGoingToStorage(room, itemIndex, e.target.checked)}
                      />
                      <CheckboxText>To: Company storage</CheckboxText>
                    </CheckboxLabel>
                    {/* Existing High Value checkbox */}
                    <CheckboxLabel>
                      <AnimatedCheckbox
                        type="checkbox"
                        checked={item.isHighValue}
                        onChange={(e) => handleHighValueChange(room, itemIndex, e.target.checked)}
                      />
                      <CheckboxText>High Value?</CheckboxText>
                    </CheckboxLabel>
                    {item.isHighValue && (
                      <EstimatedCostInputWrapper>
                        <DollarSign>$</DollarSign>
                        <EstimatedCostInput
                          type="text"
                          placeholder="Estimate"
                          value={item.estimatedCost || ''}
                          onChange={(e) => handleEstimatedCostChange(room, itemIndex, e.target.value)}
                        />
                      </EstimatedCostInputWrapper>
                    )}
                  </CheckboxContainer>
              </HandledColumn>
            </AnimatedItemRow>
          ))}
          {(currentRoom === room && isGenerating)  && !isEditing && (
            <AnimatedItemRow className="edit-item-row">
              <ItemColumn>
                <InventoryContent>
                  <TypingContainer>
                    <div className="item-details">
                    {typingText.split('\n').map((line, index) => (
                          <div key={index} style={index > 0 ? { paddingLeft: '20px' } : {}}>
                            {line}
                            {index === typingText.split('\n').length - 1 && <TypingCursor className="typing-cursor"/>}
                          </div>
                      ))}
                    </div>
                  </TypingContainer>
                </InventoryContent>
              </ItemColumn>
              <ServiceColumn />
              <ServiceColumn />
            </AnimatedItemRow>
          )}
        </React.Fragment>
      ))}
    </ScrollableRows>
  );
};

return (
  <Layout >
    <InventoryTitle>
      My Inventory <img src={InventoryIcon} alt="inventory-icon" className="inventory-icon" />
    </InventoryTitle>
    <div style={{ height: 'calc(100vh - 100px)', overflowY: 'auto' }}>
    <StyledInventoryBox style={{ flex: 1, overflowY: 'auto' }}>
      <HeaderRow>
        <div className="item-icon-container">
          Items
          <img src={Item} alt="logo" className="item-icon" />
        </div>
        <div className="trolly-icon-container">
          Services
          <img src={Trolly} alt="logo" className="trolly-icon" />
        </div>
        <div className="value-icon-container">
          Handling
          <img src={Value} alt="logo" className="value-icon" />
        </div>
      </HeaderRow>
      <ButtonContainer>
          <EditButton onClick={handleEditToggle}>
            <div className="edit-icon">{isEditing ? '✓' : '✎'}</div>
            <EditButtonText className="edit-text" isEditing={isEditing} />
          </EditButton>
          {isEditing ? (
            <div className="undo-redo-container">
              <button
                className="undo-button"
                onClick={handleUndo}
                disabled={currentHistoryIndex <= 0}
              >
                Undo
              </button>
              <button
                className="redo-button"
                onClick={handleRedo}
                disabled={currentHistoryIndex >= inventoryHistory.length - 1}
              >
                Redo
              </button>
            </div>
          ) : (
            <CopyButtonWrapper>
              <CopyComponent inventoryItems={inventoryItems} />
            </CopyButtonWrapper>
          )}
        </ButtonContainer>
      <ContentArea className = "content-area" ref={contentAreaRef}>
        {renderInventory()}
          {(!isAutoScrolling && isGenerating) && <ScrollArrow onClick={handleResumeScrolling}/>}
      </ContentArea>
    </StyledInventoryBox>
    </div>
    {showPopup && (
        <DestinationSelectionPopup
          destinations={formData.destinations}
          abbreviatedDestinations={formData.abbreviatedDestinations}
          onSelect={(address) => {
            handleDestinationChange(currentRoom, currentItemIndex, address);
            setShowPopup(false);
          }}
          onClose={() => setShowPopup(false)}
          onAddDestination={handleAddDestination}
          onRemoveDestinations={handleRemoveDestinations}
        />
      )}
    <div className="generate-inventory-nav">
      <NavigationArrows
        onPrevClick={handleBack}
        onNextClick={() => navigate('/destination')}
        prevLabel={isFromReview ? 'Back to Review' : 'Previous'}
        nextLabel={"Next"}
        isNextDisabled={isNextDisabled}
      />
    </div>
  </Layout>
);
};

export default GenerateInventory;