import React, { useState } from 'react';
import Layout from '../layout/layout.js';
import Map from './map.js';
import ListingCard from './listings/listing_card.js';
import './movers-dash.css';
import { collection, query, orderBy, getDocs, where, limit, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/fireconfig';
import useListings from '../../firebase/use_listings.js';
import UseOffersStatus from '../../firebase/use_status.js';
import dashIcon from '../../assets/lexos_imgs/icons/dashboard.svg';
import { useConfirm } from '../homeOwner/context/alert';
import { getFunctions, httpsCallable } from 'firebase/functions';

const MovingCompanyDashboard = () => {
  const [activeTab, setActiveTab] = useState('Leads');
  // listings: array of objects - {moveDetailsId, userId, inventory, destinationInfo, moveDetails, originInfo, virtualWalkthrough}
  const { listings, loading, error } = useListings(activeTab);
  // Status: map - key documentId, value {state, moveDetailsId}
  const { offersStatus } = UseOffersStatus();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const confirm = useConfirm();

  const tabs = ['Leads', 'Offers Submitted', 'Moves Booked', 'Leads Lost'];

  // Based on offers per tab
  const getEmptyMessage = (tab) => {
    const tabName = tab.toLowerCase();
    return `No ${tabName === 'leads lost' ? 'lost leads' : tabName === 'moves booked' ? 'booked moves' : tabName} at this time`;
  };

  // Function checks whether the value is nested in the map (generic function)
  function hasMadeOffer(map, searchValue) {
    for (let value of map.values()) {
      if (value === searchValue) {
        return true;
      }
      if (typeof value === 'object' && value !== null) {
        if (JSON.stringify(value).includes(JSON.stringify(searchValue))) {
          return true;
        }
      }
    }
    return false;
  };

  // Need to add logic above and below here that checks if payment went through - then we consider their status truly 'booked'
  const retryPayment = async (listing) =>{
               
    // Create payment intent using Firebase Function
    try {
        // First confirmation before retrying payment
        const shouldRetry = await confirm(
          "Would you like to retry the payment with your updated payment method?",
          "Retry Payment",
          "Cancel",
          { title: "Retry Payment?" }
        );
  
        if (!shouldRetry) return;
  
        setProcessingPayment(true);
        
        let offerData;
        for (let [_, value] of offersStatus.entries()) {
          if (value.moveDetailsId === listing.moveDetailsId) {
            offerData = value;
            break;
          }
        }

        const functions = getFunctions();
        const createPaymentIntentFn = httpsCallable(functions, 'createPaymentIntent');
        const result = await createPaymentIntentFn({
          data: {
            offerId: offerData.offerId,
            moveDetailsId: listing.moveDetailsId,
            companyId: offerData.movingCompanyId,
            amount: Math.round(Number(offerData.quoteAmount) * 0.10 * 100) // Convert to cents for Stripe
          }
        });
        if (result.data.success) {
          // Update as booked for the moving company
          const offersRef = collection(db, 'offers');
          const q = query(offersRef, where("offerId", "==", offerData.offerId), orderBy('timestamp', 'desc'), limit(1));
          const querySnapshot = await getDocs(q);
          
          if (querySnapshot.empty) {
              throw new Error('Offer not found');
          }
          
          const offerDoc = querySnapshot.docs[0];
          
          await updateDoc(offerDoc.ref, { status: 'booked'});
          const viewBookedMove = await confirm(
            "Your payment was successful! Would you like to view your booked move?",
            "View Booked Move",
            "Back to Submitted Offers",
            { title: "Payment Successful!" }
          );
  
          // Navigate based on user choice
          setActiveTab(viewBookedMove ? 'Moves Booked' : 'Offers Submitted');
        } else {
          throw new Error('Payment failed');
        }
      } catch (err) {
        console.error('Payment retry failed:', err);
        // Show error message
        await confirm(
          `Payment failed: ${err.message}Please contact (617) 798-4863 for support`,
          "OK",
          null,
          { title: "Payment Failed" }
      );
    } finally {
      setProcessingPayment(false);
    }
  };

  // Updated to include payment status
  const getListingStatus = (listing) => {
    if (!hasMadeOffer(offersStatus, listing.moveDetailsId)) return 'new';
    
    for (let value of offersStatus.values()) {
      if (JSON.stringify(value).includes(JSON.stringify(listing.moveDetailsId))) {
        if (value.state === 'accepted') {
          switch (value.paymentStatus) {
            case 'completed':
              return 'booked';
            case 'failed':
              return 'payment_failed';
            case 'pending':
            default:
              return 'awaiting_payment';
          }
        }
        return value.state;
      }
    }
    return 'unknown';
  };


  // Status indicator helper (for the card)
  // Updated status indicator to show payment states within pending tab
  const renderStatusIndicator = (status, moveDate, listing) => {
    switch (status) {
      case 'new':
        return (
          <span className="status-indicator new">
            <span className="status-dot"></span>
            New
          </span>
        );
      case 'pending':
        return (
          <span className="status-indicator pending">
            <span className="status-dot"></span>
            Pending
          </span>
        );
      case 'awaiting_payment':
        return (
          <span className="status-indicator payment-pending">
            <span className="status-dot" style={{ backgroundColor: '#ff4d4f' }}></span>
            Awaiting Payment
          </span>
        );
      case 'payment_failed':
        return (
          <span className="status-indicator payment-failed">
            <span className="status-dot" style={{ backgroundColor: '#ff4d4f' }}></span>
            Payment Failed!
            <button 
              className="retry-payment"
              onClick={() => retryPayment(listing)}
              disabled={processingPayment}
            >
              {processingPayment ? 'Processing...' : 'Retry'}
            </button>
          </span>
        );
      case 'booked':
        return (
          <span className="status-indicator booked">
            <span className="status-dot"></span>
            Move scheduled: {moveDate || 'Date TBD'}
          </span>
        );
      case 'lost':
        return null;
      default:
        return null;
    }
  };

    // Function to determine if a listing should show in the current tab
    const shouldShowInTab = (status, activeTab) => {
      switch (activeTab) {
        case 'Leads':
          return status === 'new';
        case 'Offers Submitted':
          // Show all payment-related statuses in the Offers Submitted tab
          return status === 'pending' || 
                 status === 'awaiting_payment' || 
                 status === 'payment_failed';
        case 'Moves Booked':
          return status === 'booked';
        case 'Leads Lost':
          return status === 'lost';
        default:
          return false;
      }
    };

  function formatDate(date){
    return date.substring(0, (date.length -6));
  }

  return (
    <Layout>
      <div className="moving-company-dashboard">
        <h1 className="dashboard-title">Dashboard <img className="dash-icon" src={dashIcon}/></h1>
        <div className="tabs">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="postings-page">
          <div className="listings-container">
            {error && <p>Error: {error.message}</p>}
            {!loading && !error && listings.map((listing) => {
              const status = getListingStatus(listing);
              
              if (shouldShowInTab(status, activeTab)) {
                return (
                  <ListingCard 
                    key={listing.moveDetailsId} 
                    {...listing} 
                    status={status}
                    statusIndicator={renderStatusIndicator(status, `${formatDate(listing.moveDetails?.dom)}`, listing)}
                    className={status === 'lost' ? 'lost-lead' : ''}
                    virtualWalkthrough={listing.virtualWalkthrough}
                  />
                );
              }
              return null;
            })}
            {!loading && !error && listings.filter(listing => 
              shouldShowInTab(getListingStatus(listing), activeTab)).length === 0 && (
              <p className="empty-tab-message">{getEmptyMessage(activeTab)}</p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MovingCompanyDashboard;