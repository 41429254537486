import React, { useState, useRef, useEffect, useContext} from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { Accordion, Button, Form, Modal} from 'react-bootstrap';
import {useOpenAI}  from '../../openai/openai_context';
import { FormContext } from '../context/form_context';
import Layout from '../../layout/layout';
import './virtual_walkthrough.css';
import ProcessWalkthrough from './process_walkthrough';
import { DetectOpenAI } from '../../openai/openai_util';
import Snapchat from '../../../assets/lexos_imgs/icons/phone-camera.png';
import Trash from '../../../assets/lexos_imgs/icons/trash.png';
import NavigationArrows from '../../layout/nav_arrows';
import { MdCameraEnhance, MdDriveFolderUpload } from 'react-icons/md';
import MobileTour from './mobile_tour';
import { InventoryTitle } from '../inventory/inventory_styles';


const VirtualWalkthrough = () => {
  const { formData, setFormData, inventoryItems, setInventoryItems, isNewInventory, setIsNewInventory } = useContext(FormContext);
  const navigate = useNavigate();
  const { setOpenAIGenerator } = useOpenAI();
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [currentRoomId, setCurrentRoomId] = useState(null);
  const [animatingRoomId, setAnimatingRoomId] = useState(null);


  const handleBack = () => {
    navigate('/begin-tour', { state: location.state });
  };

  /* State variables for managing photos, processing, and results */
  const [isProcessing, setIsProcessing] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [activeKey, setActiveKey] = useState('0');
  const [showModal, setShowModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const fileInputRefs = useRef({});
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const checkIfMobile = () => {
      // Check if the device has touch capabilities and a relatively narrow screen
      const isMobileDevice = window.matchMedia("(max-width: 768px)").matches 
        && (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
      
      setIsMobile(isMobileDevice);
    };
  
    checkIfMobile();
  
    // Add listener for screen resize
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleResize = (e) => {
      checkIfMobile();
    };
  
    mediaQuery.addEventListener('change', handleResize);
  
    // Cleanup
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  // useEffect(() => {
  // Detect if the user is on a mobile or tablet device
//   const checkIfMobile = () => {
//     const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//     const isMobile = /android|iPhone|iPod/.test(userAgent) && !window.MSStream;
//     const isTablet = /iPad|Macintosh/.test(userAgent) && 'ontouchend' in document; // Detects iPad specifically

//     if (isMobile || isTablet) {
//       setIsMobile(true);
//     } else {
//       setIsMobile(false);
//     }
//   };

//   checkIfMobile();
// }, []);


  /* ****** Room Objects (Accordions) and their functionalities ********
  *
  *
  */
  useEffect(() => {
    if (!formData.rooms || formData.rooms.length === 0) {
      const initialRooms = [];
      const { bedrooms, exactBedrooms, floors } = formData;

      const useBedrooms = parseInt(exactBedrooms) > 0 ? exactBedrooms : bedrooms;

      if (bedrooms === 'Studio') {
        initialRooms.push({ id: String(Date.now()), name: 'Studio' });
      } else if (bedrooms === '1') {
        initialRooms.push({ id: String(Date.now()), name: 'Bedroom' });
      } else {
        for (let i = 1; i <= parseInt(useBedrooms); i++) {
          initialRooms.push({ 
            id: String(Date.now() + i), 
            name: `Bedroom #${i}` 
          });
        }
      }
      
      initialRooms.push({ 
        id: String(Date.now() + initialRooms.length + 1), 
        name: 'Living Room' 
      });
      initialRooms.push({ 
        id: String(Date.now() + initialRooms.length + 1), 
        name: 'Dining Room' 
      });
      initialRooms.push({ 
        id: String(Date.now() + initialRooms.length + 2), 
        name: 'Kitchen' 
      });
      if (floors === '3' || floors === '4+') {
        initialRooms.push({ 
          id: String(Date.now() + initialRooms.length + 3), 
          name: 'Basement' 
        });
      }
      initialRooms.push({ 
        id: String(Date.now() + initialRooms.length + 4), 
        name: 'Additional Items' 
      });

      setFormData(prevData => ({ ...prevData, rooms: initialRooms, photos: {} }));
    }
  }, [formData.bedrooms, formData.exactBedrooms, formData.floors, setFormData]);


  const getNextRoomNumber = (rooms) => {
    const existingNumbers = rooms
      .map(room => {
        const match = room.name.match(/Room #(\d+)/);
        return match ? parseInt(match[1]) : 0;
      })
      .filter(num => num > 0);

    if (existingNumbers.length === 0) return 1;
    return Math.max(...existingNumbers) + 1;
  };

  const updateRoomNumbers = (rooms) => {
    return rooms.map(room => {
      const match = room.name.match(/^(Bedroom|Room) #\d+$/);
      if (match) {
        const prefix = match[1];
        const index = rooms.filter(r => r.name.startsWith(prefix)).indexOf(room) + 1;
        return { ...room, name: `${prefix} #${index}` };
      }
      return room;
    });
  };

  const handleAccordionChange = (key) => {
    setActiveKey(key);
  };

  const handleFileUpload = (roomId, event) => {
    const files = event.target.files;
    setFormData(prevData => ({
      ...prevData,
      photos: {
        ...prevData.photos,
        [roomId]: [...(prevData.photos[roomId] || []), ...files]
      }
    }));
  };


  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDeletePhoto = (roomId, index) => {
    setFormData(prevData => ({
      ...prevData,
      photos: {
        ...prevData.photos,
        [roomId]: prevData.photos[roomId].filter((_, i) => i !== index)
      }
    }));
  };

  /* ******************************************************************* */

  /* ********* Submitting the walkthrough and handling the response ********
  *
  *
  * 
  * 
  * 
  */ 
 /* Inventory Items is an Object */ 

  const handleSubmit = async () => {
    setIsProcessing(true);
    const photosByRoom = formData.rooms.reduce((acc, room) => {
      if (formData.photos[room.id] && formData.photos[room.id].length > 0) {
        acc[room.name] = formData.photos[room.id];
      }
      return acc;
    }, {});

    if (Object.keys(photosByRoom).length > 0) {
      const detectOpenAI = DetectOpenAI(photosByRoom);
      const firstResult = await detectOpenAI.next();

      if (!firstResult.done && !firstResult.value.error) {
        setOpenAIGenerator(detectOpenAI); // Store the generator in the context
        setIsNewInventory(true);
        navigate('/generate-inventory', { 
          state: {
            // Pass the property information 
            ...location.state, 
            initialResult: firstResult.value, // Pass only the first room's result
            photosByRoom, // Pass the photos for further processing
          } 
        });
      } else {
        console.error('Error processing the first room:', firstResult.value.error || 'Unknown error');
        setIsProcessing(false);
      }
    } else {
      alert("Please upload photos for at least one room before submitting.");
      setIsProcessing(false);
    }
  };

  
  const handleProcessingComplete = (result) => {
    setIsProcessing(false);
    if (analysisResult) {
      navigate('/generate-inventory', { state: { result: analysisResult } });
    } else {
      console.error("No analysis result available");
      alert("An error occurred while processing your photos. Please try again.");
    }
  };
  /* Logic to add, remove, adjust rooms 
  *
  *
  */
  const addRoom = () => {
    const newRoomId = String(Date.now());
    
    setFormData(prevData => {
      const existingRooms = prevData.rooms || [];
      const customRoomCount = existingRooms.filter(room => 
        room.name.startsWith('Room #')
      ).length;
      
      const newRooms = [
        ...existingRooms,
        { id: newRoomId, name: `Room #${customRoomCount + 1}` }
      ];

      return {
        ...prevData,
        rooms: updateRoomNumbers(newRooms)
      };
    });
  };

  const updateRoomName = (id, newName) => {
    setFormData(prevData => ({
      ...prevData,
      rooms: prevData.rooms.map(room => 
        room.id === id ? { ...room, name: newName } : room
      )
    }));
  };

  const deleteRoom = (e, id) => {
    e.stopPropagation(); // Prevent accordion toggle
    e.preventDefault(); // Prevent any default browser behavior

    setFormData(prevData => {
      // Filter out the deleted room
      const updatedRooms = prevData.rooms.filter(room => room.id !== id);
      
      // Remove the photos for the deleted room
      const { [id]: deletedPhotos, ...remainingPhotos } = prevData.photos;
      
      // Update room numbers for remaining rooms
      const finalRooms = updateRoomNumbers(updatedRooms);

      return {
        ...prevData,
        rooms: finalRooms,
        photos: remainingPhotos
      };
    });
  };

  return (
    <Layout>
      <div className="walkthrough-container">
        <h1>Virtual Walkthrough <img src={Snapchat} alt="SnapWalks" className="img-walk" /></h1>
        <p>Please upload or capture photos for each room in your home.</p>
        <Accordion activeKey={activeKey} onSelect={handleAccordionChange} className="styled-accordion">
        {formData.rooms.map((room, index) => (
          <Accordion.Item key={room.id} eventKey={String(index)}>
            <Accordion.Header>
              <input
                type="text"
                value={room.name}
                onChange={(e) => updateRoomName(room.id, e.target.value)}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.preventDefault()}
                className="room-name-input"
                placeholder="Enter room name"
              />
              <button className="delete-room-btn" onClick={(e) => deleteRoom(e, room.id)}>
                <img src={Trash} alt="Delete" className="trash-icon" />
              </button>
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group>
                <Form.Label>Upload photos for your {room.name}:</Form.Label>
                <div className="button-group">
                <Button 
                  variant="primary"
                  className="me-2"
                  onClick={() => {
                    if (isMobile) {
                      setIsCameraOpen(true);
                      setCurrentRoomId(room.id);
                    }
                  }}
                  disabled={!isMobile}
                >
                  <MdCameraEnhance className="camera-icon" /> Take Photos
                </Button>
                  <Button variant="secondary" onClick={() => fileInputRefs.current[room.id]?.click()} className="me-2">
                    <MdDriveFolderUpload className="upload-icon" />
                     Upload Photos
                  </Button>

                  <Form.Control
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => handleFileUpload(room.id, e)}
                    style={{ display: 'none' }}
                    ref={(el) => {
                      if (el) fileInputRefs.current[room.id] = el;
                    }}
                  />
                </div>
                {formData.photos[room.id] && formData.photos[room.id].length > 0 && (
                  <div className="photo-container">
                    {formData.photos[room.id].map((photo, idx) => (
                      <div key={idx} className="photo-preview-wrapper">
                        <img
                          src={URL.createObjectURL(photo)}
                          alt={`Preview ${idx + 1}`}
                          className="photo-preview"
                          onClick={() => handlePhotoClick(URL.createObjectURL(photo))}
                          style={{ cursor: 'pointer' }}
                        />
                        <button className="delete-photo-btn" onClick={() => handleDeletePhoto(room.id, idx)}>
                            <img src={Trash} alt="Delete" className="delete-photo-icon" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </Form.Group>
            </Accordion.Body>
            {room.name === "Additional Items" && 
                  <div className="added-items-note-container">
                    <div className="note">
                      <strong>Note: </strong> For items not specific to a room (e.g. art in a hallway)
                    </div>
                  </div>
            }
          </Accordion.Item>
        ))}
      </Accordion>
      <div className="add-room-container">
          <button onClick={addRoom} className="add-room-btn">
            + Add Room
          </button>
        </div>
        <div className="virtual-walkthrough-nav">
        <NavigationArrows
                onPrevClick={handleBack}
                onNextClick={handleSubmit}
                prevLabel={'Previous'}
                nextLabel={"Submit Walkthrough"}
        />
      </div>
      </div>

      <ProcessWalkthrough 
        isVisible={isProcessing} 
        onComplete={handleProcessingComplete} 
        analysisResult={analysisResult}
        photosByRoom={formData.photos}
      />

      {/* Modal for displaying full image */}
      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="photo-popup">
        <Modal.Header closeButton style={{ filter: 'invert(1)'}}>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedPhoto} alt="Full Size" style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>
      <MobileTour
        isOpen={isCameraOpen}
        onClose={() => setIsCameraOpen(false)}
        onPhotoCapture={handleFileUpload}
        roomId={currentRoomId}
      />
    </Layout>
  );
};

export default VirtualWalkthrough;