import React, { useState } from 'react';
import styled from 'styled-components';

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--dark-gray-color) 100%);
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  position: relative;
  color: white;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: white;
`;

const DestinationOption = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;

  &:checked {
    background-color: var(--primary-color);
    &:after {
      content: '✓';
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
`;

const Label = styled.label`
  color: white;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 1rem;
`;

const Button = styled.button`
  flex: 1;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(0.9);
  }
`;

const CancelButton = styled(Button)`
  background-color: red;
  color: white;
  
  &:hover {
    background-color: #ff1a1a;
  }
`;

const DoneButton = styled(Button)`
  background-color: green;
  color: white;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const DestinationSelectionPopup = ({ 
  abbreviatedDestinations,
  onSelect,
  onClose
}) => {
  const [selectedDestination, setSelectedDestination] = useState(null);

  if (abbreviatedDestinations.length <= 1) {
    return null;
  }

  // Filter out the primary destination
  const additionalDestinations = abbreviatedDestinations.slice(1);

  const handleSelect = (address) => {
    setSelectedDestination(address);
    if (additionalDestinations.length === 1) {
      onSelect(address);
      onClose();
    }
  };

  const handleDone = () => {
    if (selectedDestination) {
      onSelect(selectedDestination);
    }
    onClose();
  };

  return (
    <PopupOverlay>
      <PopupContent onClick={(e) => e.stopPropagation()}>
        <Title>
          {additionalDestinations.length === 1 
            ? `Send to: ${additionalDestinations[0]}`
            : 'Select Destination'}
        </Title>

        {additionalDestinations.map((dest, index) => (
          <DestinationOption key={index}>
            <StyledCheckbox
              type="checkbox"
              id={`dest-${index}`}
              checked={selectedDestination === dest}
              onChange={() => onSelect(dest)}
            />
            <Label htmlFor={`dest-${index}`}>{dest}</Label>
          </DestinationOption>
        ))}
      </PopupContent>
    </PopupOverlay>
  );
};

export default DestinationSelectionPopup;