import React, { useEffect, useRef } from 'react'; 
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/layout.js';
import '../movingCompany/listings/lead_analysis.css';
import { ArrowLeft } from 'lucide-react';
import FAQPdf from '../../assets/LexPro-FAQs.pdf';

const MoverFAQ = () => {
    const navigate = useNavigate();
    const pdfContainerRef = useRef(null);

    useEffect(() => {
        let isCancelled = false; // Flag to track if the effect has been cancelled
        let renderTasks = []; // Array to keep track of rendering tasks

        const loadAndRenderPDF = async () => {
            try {
                const pdfjsLib = window['pdfjsLib'];
                pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';

                // Clear container before rendering to prevent duplication
                if (pdfContainerRef.current) {
                    pdfContainerRef.current.innerHTML = '';
                }

                const loadingTask = pdfjsLib.getDocument({
                    url: FAQPdf,
                    disableStream: false,
                    disableAutoFetch: false,
                    disableFontFace: false,
                    maxImageSize: -1,
                });

                const pdf = await loadingTask.promise;
                const numPages = pdf.numPages;

                const renderPage = async (pageNum) => {
                    if (isCancelled) return; // Exit if cancelled

                    const page = await pdf.getPage(pageNum);
                    if (isCancelled) return; // Exit if cancelled

                    // Get container width and calculate initial viewport
                    const containerWidth = pdfContainerRef.current.offsetWidth;
                    const baseScale = containerWidth / page.getViewport({ scale: 1 }).width;
                    const scale = baseScale * 2; // Adjust for higher resolution

                    const viewport = page.getViewport({ scale });

                    // Create canvas with higher resolution
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d', { alpha: false });

                    // Set canvas dimensions to match viewport
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Set display size
                    canvas.style.width = '100%';
                    canvas.style.height = 'auto';
                    canvas.style.marginBottom = '20px'; // Add space between pages

                    // Enable better image rendering
                    context.imageSmoothingEnabled = true;
                    context.imageSmoothingQuality = 'high';

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                        enableWebGL: true,
                        renderInteractiveForms: true,
                    };

                    // Start rendering the page
                    const renderTask = page.render(renderContext);
                    renderTasks.push(renderTask); // Keep track of the render task

                    await renderTask.promise;

                    // Remove completed task from the list
                    renderTasks = renderTasks.filter(task => task !== renderTask);

                    if (isCancelled) return; // Exit if cancelled

                    pdfContainerRef.current.appendChild(canvas);

                    // Render next page
                    if (pageNum < numPages) {
                        await renderPage(pageNum + 1);
                    }
                };

                // Start rendering from the first page
                await renderPage(1);

            } catch (error) {
                console.error('Error loading PDF: ', error);
                // Add error message to container
                if (pdfContainerRef.current) {
                    pdfContainerRef.current.innerHTML = `
                        <div class="pdf-error">
                            <p>Error loading PDF. Please try again.</p>
                        </div>
                    `;
                }
            }
        };

        // Call the function to load and render the PDF
        loadAndRenderPDF();

        // Cleanup function to cancel ongoing tasks when component unmounts or effect re-runs
        return () => {
            isCancelled = true; // Set the cancellation flag
            renderTasks.forEach(task => task.cancel()); // Cancel all rendering tasks
            if (pdfContainerRef.current) {
                pdfContainerRef.current.innerHTML = ''; // Clear the container
            }
        };
    }, []); // Empty dependency array ensures the effect runs only once

    return (
        <Layout>
            <div className="analysis-container">
                <button 
                    onClick={() => navigate(-1)}
                    className="submitOffer-back-button"
                >
                    <ArrowLeft className="mr-2" size={20} />
                    Back
                </button>
                <div className="pdf-viewer-container">
                    <div ref={pdfContainerRef} className="pdf-container">
                        {/* PDF pages will be rendered here */}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default MoverFAQ;
