import React, {useState, useCallback} from 'react';
import './company_profile.css';
import Layout from '../../layout/layout';
import SRM from '../../../assets/lexos_imgs/movingCos/michaels.png';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationArrows from '../../layout/nav_arrows';

const MoveMateProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeChange = () => {
    const url = "https://www.google.com/search?sca_esv=33073fab614d76a8&rlz=1C1CHBF_enUS924US924&sxsrf=ADLYWIJuqmZI7C92KOgKKqTjxRTRtcUE9Q:1728887040660&q=Michaels+Moving+And+Storage&si=ACC90nydsYcm6pHgilPM6SI7Z50Hv5Y0BTzk_wmKR13SY4NPwert6ab3bdXzfvi6gWmz6QSxKnh1TE_wHCDIBGSqi1kfqjlgpl6rwabE58fULTvGGsg3rx2tGimHFNKKowlhiEcXJqZaA5IOY1iUarPEDQTSaKrFP4cflpTaBrANHrHbLdTGRukeCykbsEUe5srYnOaG19JoAS878JSxLDGNKg7mXsmGXEjWzfEr9nDyfsgxtc_aqTiyvgJjM6EV1Y4c4UlwRbDG&sa=X&sqi=2&ved=2ahUKEwjYhamPno2JAxVahIkEHTFZCQoQ6RN6BAgVEAE&biw=1536&bih=730&dpr=1.25#lrd=0x89e379d01a2eb8cf:0x383dbe95ad07f748,1,,,,";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const leaveReview = () => {
    // URL that should bring the user closer to the review section
    const mapsReviewUrl = `https://www.google.com/maps/place/Michael's+Moving+%26+Storage/@42.3545848,-71.1269557,17z/data=!3m1!4b1!4m6!3m5!1s0x89e379d01a2eb8cf:0x383dbe95ad07f748!8m2!3d42.3545809!4d-71.1243808!16s%2Fg%2F1tf03h7f?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D`;
  
    window.open(mapsReviewUrl, '_blank', 'noopener,noreferrer');
  };

  const [originalFrom, setOriginalFrom] = useState(() => {
    return location.state?.originalFrom || location.state?.from?.pathname || '/';
  });

  // Use this function for navigation after viewing company profile
  const navigateToOriginalDestination = useCallback(() => {
    navigate(originalFrom, { replace: true });
  }, [navigate, originalFrom]);

  return (
    <Layout>
      <div className="company-profile-container">
        <div className="property-info-nav">
              <NavigationArrows
                onPrevClick={navigateToOriginalDestination}
                prevLabel={'Back'}
                inverted
              />
          </div>
        <div className="company-header">
          <div className="company-info">
            <div className="company-img-placeholder">
              <img src={SRM} alt="Company Logo" className="zeke" />
            </div>
            <div className="company-details">
              <h2 className="company-name">Michael’s Moving & Storage</h2>
              <div className="company-rating">
                <span className="rating-text">Rating: 4.9</span>
                <span className="stars">
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                </span>
                <button onClick={routeChange} className="review-count">(746 reviews)</button>
              </div>
              <button onClick={leaveReview} className="share-btn">Share</button>
            </div>
          </div>
        </div>

        <div className="company-introduction">
          <h3>Introduction</h3>
          <p>
          Thank you for taking the time to visit our profile. We have been serving the Boston area as well as across the country for over 10 years. We have built our reputation as Boston’s most affordable moving company without sacrificing service. Our local Boston movers are polite, fast, and of course, caring about your belongings. 
          </p>
        </div>

        <div className="company-overview">
          <div className="overview-section">
            <h4>Overview</h4>
            <ul>
              <li>Serving all of Massachusetts and offering interstate moving services.</li>
              <li>Background checked</li>
              <li>National moving services for 10+ years</li>
            </ul>
          </div>

          <div className="overview-section">
            <h4>Contact Information</h4>
            <ul>
            Contact with the moving company will begin once you hit 'Accept' on your preferred offer in the Offers Dashboard. The company will then reach out to confirm details and proceed with your move.
            </ul>
          </div>

          <div className="overview-section">
            <h4>Payment Methods</h4>
            <p>
            This LexPro accepts payments via Apple Pay, Cash, Credit card, Cash App, Venmo, and Zelle. 
            </p>
          </div>

          <div className="overview-section">
            <h4>LexPro Gold Status</h4>
            <p>
              LexPro Gold's are among the highest-rated, most popular professionals on Lexos.
            </p>
          </div>
        </div>

        <div className="business-hours">
          <h4>Business Hours</h4>
          <table>
            <tbody>
            <tr>
                <td>Mon</td>
                <td>9:00 AM - 6:00 PM</td>
              </tr>
              {/* Add more days as needed */}
              <tr>
                <td>Tue</td>
                <td>9:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>9:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>9:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>9:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>9:00 AM - 6:00 PM</td>
              </tr>
              <tr>
                <td>Sun</td>
                <td>10:00 AM - 3:00 PM</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="company-reviews">
          <h3>Reviews</h3>
          <section className="reviews-section">
            <div className="row text-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center mb-4 ">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "I recently had the pleasure of using Michael’s Moving and Storage for my relocation, and I cannot recommend them highly enough! The entire process was smooth and stress-free."
                </p>
                <p className="font-italic font-weight-normal mb-0">-Olivia Johnson</p>
              </div>
              <div className="col-md-6 mb-0">
                <div className="d-flex justify-content-center mb-4">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  " Felix, Jason and Manuel were the best movers!! They were all super professional, efficient, and very friendly. They even had the supplies to pack up a few odds and ends we didn't have time to pack, and everything was handled with care. We would recommend Michael's Moving and Storage and especially Felix, Jason and Manuel to anyone moving in the Boston area."
                </p>
                <p className="font-italic font-weight-normal mb-0">- Colleen Harrington</p>
              </div>
            </div>
          </section>
        </div>
      </div>

    </Layout>
  );
};

export default MoveMateProfile;
