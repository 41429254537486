import React, { useState } from 'react';
import { DetectOpenAI } from './openai_util';
import Layout from '../layout/layout.js';

function OpenAIComponent() {
    const [photos, setPhotos] = useState([]);
    const [response, setResponse] = useState('');

    const handlePhotoChange = (e) => {
        setPhotos(e.target.files);  // Keep the FileList directly
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (photos.length > 0) {
            const apiResponse = await DetectOpenAI(photos);
            setResponse(apiResponse);
        } else {
            setResponse("Please upload at least one photo.");
        }
    };

    return (
        <Layout>
        <div>
            <h1>OpenAI Integration</h1>
            <form onSubmit={handleSubmit}>
                <input type="file" accept="image/*" multiple onChange={handlePhotoChange} />
                <button type="submit">Send Photos</button>
            </form>
            <div>
                <h2>Response:</h2>
                <p>{response}</p>
            </div>
        </div>
        </Layout>
    );
}

export default OpenAIComponent;
