import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { AddressContext } from './address_context';

const FormContext = React.createContext();

const abbreviateAddress = (fullAddress) => {
    const parts = fullAddress.split(',');
    return parts.length > 1 ? `${parts[0]}, ${parts[1].trim()}` : fullAddress;
};

const FormProvider = ({ children }) => {
    const { address } = useContext(AddressContext);
    const [formData, setFormData] = useState({
      currentAddress: "Current Address",
      destinations: [],
      abbreviatedDestinations: [],
      moveType: '',
      residenceType: '',
      bedrooms: '',
      squareFootage: '',
      floors: '',
      hasMultipleFloors: '',
      buildingFloor: '',
      exactFloors: '',
      exactBedrooms: '',
      selectedDate: '',
      preferredTime: '',
      presentDuringMove: '',
      accessInstructions: '',
      specificInstructions: '',
      moverNotes: '',
      rooms:[],
      photos: [],
    });

    // const[isFromReview, setIsFromReview] = useState(false);

    const [isNewInventory, setIsNewInventory] = useState(false);

    const [inventoryItems, setInventoryItems] = useState(() => {
      const savedInventory = localStorage.getItem('inventoryItems');
      return savedInventory ? JSON.parse(savedInventory) : {};
    });


    const [accessOptions, setAccessOptions] = useState({});

    const updateAccessOptions = useCallback((address) => {
      setAccessOptions(prevOptions => {
        if (prevOptions[address]) return prevOptions; // Return early if options already exist
        return {
          ...prevOptions,
          [address]: {
            elevator: false,
            stairs: false,
            driveway: false,
            streetParking: false,
            loadingDock: false,
            gatedEntrance: false,
            noneOfTheAbove: false,
          }
        };
      });
    }, []);

    useEffect(() => {
      if (address) {
        const abbreviatedAddress = abbreviateAddress(address);
        setFormData(prevData => ({
          ...prevData,
          currentAddress: abbreviatedAddress,
        }));
        updateAccessOptions(abbreviatedAddress);
      }
    }, [address, updateAccessOptions]);

    useEffect(() => {
      updateAccessOptions(formData.currentAddress);
      formData.abbreviatedDestinations.forEach(updateAccessOptions);
    }, [formData.currentAddress, formData.abbreviatedDestinations, updateAccessOptions]);


    /* Callback functions used to set specific data within this context */
    const setFormDataCallback = useCallback((newData) => {
      setFormData(prevData => {
        const updatedData = typeof newData === 'function' ? newData(prevData) : newData;
        return { ...prevData, ...updatedData };
      });
    }, []);

    const setAccessOptionsCallback = useCallback((newOptions) => {
      setAccessOptions(prevOptions => {
        const updatedOptions = typeof newOptions === 'function' ? newOptions(prevOptions) : newOptions;
        return { ...prevOptions, ...updatedOptions };
      });
    }, []);

    /* Modified this to only use ...updatedItems and not use prevItems while setting */
    const setInventoryItemsCallback = useCallback((newItems) => {
      setInventoryItems(prevItems => { 
        const updatedItems = typeof newItems === 'function' ? newItems(prevItems) : newItems;
        const finalItems = { ... updatedItems};
        localStorage.setItem('inventoryItems', JSON.stringify(finalItems));
        return finalItems;
      });
    },[]);

    const setIsNewInventoryCallback = useCallback((newInventoryValue) => {
      setIsNewInventory(newInventoryValue);
    }
    );

    /* Value of the Form Context */ 
    const contextValue = useMemo(() => ({
      formData,
      setFormData: setFormDataCallback,
      accessOptions,
      setAccessOptions: setAccessOptionsCallback,
      updateAccessOptions,
      inventoryItems,
      setInventoryItems: setInventoryItemsCallback,
      isNewInventory, 
      setIsNewInventory: setIsNewInventoryCallback,
    }), [formData, accessOptions, inventoryItems, isNewInventory, setIsNewInventory, setFormDataCallback, setAccessOptionsCallback, updateAccessOptions, setInventoryItemsCallback]);

    return (
      <FormContext.Provider value={contextValue}>
        {children}
      </FormContext.Provider>
    );
};

export { FormContext, FormProvider };