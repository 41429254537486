import { useState, useEffect } from 'react';
import { collection, query, orderBy, where, onSnapshot } from 'firebase/firestore';
import { db } from './fireconfig';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
/* 
* TODO: Comment and understand thoroughly, 10/21
*/
const UseOffersStatus = () => {
  const [offersStatus, setOffersStatus] = useState(new Map());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    let unsubscribeSnapshot = null;
    let unsubscribePayments = null;

    // Cannot just use getAuth.currentUser; need in most cases this onAuthStateChanged listener
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        const fetchStatus = async () => {
          try {
            const moversOffersQuery = query(
              collection(db, 'offers'),
              where('movingCompanyId', '==', user.uid),
            //   COMMMON ISSUE: Make sure orderBy is correct time field
              orderBy('timestamp', 'desc') 
            );

            // Map stores payment status for each offer
            const paymentStatusMap = new Map();
            // Listen for payment status changes
            unsubscribePayments = onSnapshot(
              query(collection(db, 'payments'), where('companyId', '==', user.uid)),
              (paymentsSnapshot) => {
                paymentsSnapshot.forEach((doc) => {
                  const payment = doc.data();
                  paymentStatusMap.set(payment.offerId, {
                    status: payment.status,
                    updatedAt: payment.updated,
                    error: payment.error // For failed payments
                  });
                });
              }
            );

            unsubscribeSnapshot = onSnapshot(moversOffersQuery, (snapshot) => {
              const offerStatusMap = new Map();
              snapshot.docs.forEach((doc) => {
                const offerDetails = doc.data();
                offerStatusMap.set(doc.id, {
                  state: offerDetails.status,
                  movingCompanyId: user.uid, 
                  moveDetailsId: offerDetails.moveDetailsId,
                  companyName: offerDetails.movingCompany,
                  quoteAmount: offerDetails.quoteAmount,
                  offerId: offerDetails.offerId,
                  paymentStatus: paymentStatusMap.get(doc.id) || 
                    (offerDetails.status === 'accepted' ? 'pending' : null)
                });
              });
              setOffersStatus(offerStatusMap);
              setLoading(false);
            }, (err) => {
              console.error('Error in snapshot listener:', err);
              setError(err);
              setLoading(false);
            });
          } catch (err) {
            console.error('Error setting up query:', err);
            setError(err);
            setLoading(false);
          }
        };

        fetchStatus();
      } else {
        setOffersStatus(new Map());
        setLoading(false);
      }
    });
    // Just checks if unsubscribeSnapshot exists before calling it- best practice with firestore listeners
    return () => {
      if (unsubscribeSnapshot) unsubscribeSnapshot();
      if (unsubscribePayments) unsubscribePayments();
      unsubscribeAuth();
    };
  }, []);

  return { offersStatus, loading, error };
};

export default UseOffersStatus;