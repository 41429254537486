import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MdCameraAlt, MdClose, MdCheck, MdRefresh } from 'react-icons/md';
import './mobile_tour.css';

const MobileTour = ({ isOpen, onClose, onPhotoCapture, roomId }) => {
  const [capturedImage, setCapturedImage] = useState(null);
  const [photoCount, setPhotoCount] = useState(0);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);

  // Start or stop the camera based on the modal's open state
  useEffect(() => {
    if (isOpen) {
      setPhotoCount(0);
      startCamera();
    } else {
      stopCamera();
    }
    return () => {
      stopCamera();
    };
  }, [isOpen]);

  // Function to start the camera
  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
        width: { min: 640, ideal: 1920, max: 2560 },
        height: { min: 480, ideal: 1080, max: 1440 }
      });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }

    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  // Function to stop the camera
  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setStream(null);
    setCapturedImage(null);
  };

  /* Ensures the video element has the stream after capturing or retaking a photo */
  useEffect(() => {
    if (!capturedImage && videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [capturedImage, stream]);

  /* Function takes picture */ 
  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageDataUrl = canvas.toDataURL('image/jpeg', 1.0);
    setCapturedImage(imageDataUrl);

    // Clear canvas context
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  /* State for Retaking Photo */
  const retakePhoto = () => {
    setCapturedImage(null);
  };

  // Reset when closing manually
  const handleClose = async () => {
    if (capturedImage) {
      try {
        await submitPhoto();
      } catch (error) {
        console.error('Error saving final photo:', error);
      }
    }
    stopCamera();
    setPhotoCount(0);
    onClose();
  };


  /* Function Submits Photo to the Room ID */
  const submitPhoto = async () => {
    if (capturedImage) {
      try {
        // Convert base64 to Blob
        const response = await fetch(capturedImage);
        const blob = await response.blob();
        const file = new File([blob], `photo_${Date.now()}.jpg`, {
          type: 'image/jpeg',
        });

        // Submit the photo
        onPhotoCapture(roomId, { target: { files: [file] } });
        
        // Increment photo count after successful submission
        const newCount = photoCount + 1;
        setPhotoCount(newCount);

        // Reset captured image state
        setCapturedImage(null);

        // Only close after successful submission if we've hit the limit
        if (newCount >= 11) {
          // Slight delay to ensure the last photo is processed
          setTimeout(() => {
            stopCamera();
            setPhotoCount(0);
            onClose();
          }, 500);
        }
      } catch (error) {
        console.error('Error submitting photo:', error);
      }
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      fullscreen
      className="modal-fullscreen"
      backdrop="static"
    >
      <Modal.Header className="border-0">
        <Button
          onClick={onClose}
          className="position-absolute close-button"
          style={{ left: 25, top: 25 }}
        >
          <MdClose size={24} />
        </Button>
        <Button
          onClick={handleClose}
          className="position-absolute done-button"
          style={{ right: 25, top: 25 }}
        >
          <MdCheck style={{ marginRight: '5px' }} />
          I'm Done
        </Button>
      </Modal.Header>
      <Modal.Body className="p-0 bg-dark">
        
        {!capturedImage ? (
          <video
            ref={videoRef}
            autoPlay
            playsInline
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ) : (
          <>
          <img
            src={capturedImage}
            alt="Captured"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <div className="refresh-btn-container">
            <button
              onClick={retakePhoto}
              className="refresh-btn"
              aria-label="Retake photo"
            >
              <MdRefresh style={{ width: '100%', height: '100%' }} />
            </button>
          </div>
          </>
        )}
        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </Modal.Body>
      <Modal.Footer className="border-0 bg-transparent">
      <div className="w-100 d-flex justify-content-center align-items-center">
        <Button
          onClick={!capturedImage ? capturePhoto : submitPhoto}
          className="camera-button"
        >
        {!capturedImage ? (
        <div className="camera-button-inner"></div>
          ) : (
            <MdCheck size={42} className="camera-button-icon" />
          )}
        </Button>
      </div>
    </Modal.Footer>
    </Modal>
  );
};

export default MobileTour;
