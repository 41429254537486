import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBUIjYbNmTlZP3j09_z-qPjGJaQqZ4vDq4",
  authDomain: "lexosmove.com",
  projectId: "lexos-inc",
  storageBucket: "lexos-inc.appspot.com",
  messagingSenderId: "994049577124",
  appId: "1:994049577124:web:8a8055b7b42091266c5631",
  measurementId: "G-EW9NMTH6HB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app);

// Initialize Firebase Functions and connect to the emulator during development
const functions = getFunctions(app);

// If you're running the emulator locally, connect to it
if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { auth, provider, storage, db, functions };
