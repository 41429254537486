import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LeadAnalysis from '../../../assets/lexos_imgs/icons/lead.svg';
import ViewDetails from '../../../assets/lexos_imgs/icons/viewDetails.svg';
import './listing_card.css';
import Logo from '../../../assets/lexos_imgs/logos/black-on-white.jpg';

const ListingCard = ({ moveDetailsId, status, destinationInfo, originInfo, virtualWalkthrough, statusIndicator }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCardClick = () => {
    navigate(`/view-listing/${moveDetailsId}`, {state: {status: status}});
  };
  const handleAnalysisClick = () => {
    navigate(`/lead-analysis/${moveDetailsId}`);
  }
  const DEFAULT_COVER = Logo;

  // Enhanced image handling logic
  const getCoverImage = () => {
    if (!virtualWalkthrough || typeof virtualWalkthrough !== 'object') {
      return DEFAULT_COVER;
    }

    // Try to get the first image from the first room
    try {
      const rooms = Object.values(virtualWalkthrough);
      for (const room of rooms) {
        if (Array.isArray(room) && room.length > 0) {
          return room[0];
        }
      }
      return DEFAULT_COVER;
    } catch (error) {
      console.error('Error getting cover image:', error);
      return DEFAULT_COVER;
    }
  };

  return (
    <div className={`listing-card ${status}`} >
      <img src={getCoverImage()} alt="Virtual Walkthrough" className="listing-image" />
      <div className="listing-info">
        <h4>From: {originInfo?.line}, {originInfo?.city}, {originInfo?.state}</h4>
        <h4>To: {destinationInfo?.line}, {destinationInfo?.city}, {destinationInfo?.state}</h4>
        <p>{`${originInfo?.numBedrooms !== 'Studio'? `${originInfo.numBedrooms} bedrooms` : `Studio ${originInfo.residenceType}`}`} | {originInfo?.numFloors} floors</p>
        <div className="dashboard-card-btns">
        {/* Lead Analysis Report Button */}
        {(status === 'booked' || status === 'lost') && 
        <button className="lead-analysis-btn" onClick={handleAnalysisClick}>
          Lead Analysis 
          <img className="lead-button-icon" src= {LeadAnalysis}></img>
        </button>
        }
        {/* View Details Button */}
        {status !== 'lost' && 
        <button className="listed-view-details-button" onClick={handleCardClick}>
          {status === 'booked' ? 'Contact Info' : 'View Details'}
          <img className="view-details-button-icon" src= {ViewDetails}></img>
        </button>
        }
        </div>
        <div className="status-indicator-container">
          {statusIndicator}
        </div>
      </div>
    </div>
  );
};

export default ListingCard;