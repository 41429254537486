import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import CustomTime from './custom_time';
import {FormContext} from '../context/form_context';

const CalendarContainer = styled.div`
  width: 100%;
  border: 2px solid var(--pure-white);
  border-radius: 5px;
  font-family: Open Sans, sans-serif;
  position: relative;
`;

const CalendarHeader = styled.div`
  background-color: #8b50f7;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background-color: #f0f0f0;
  color: var(--dark-gray-color);
  padding: 10px;
`;

const CalendarCell = styled.div`
  height: 60px;
  padding: 5px;
  background-color: white;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: var(--secondary-color);
  }
  ${props => props.isSelected && `
    background-color: #8b50f7;
    color: white;
  `}
  ${props => props.isToday && `
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      background-color: var(--primary-color);
      border-radius: 50%;
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
  `}
  ${props => props.isDisabled && `
    background-color: #e0e0e0;
    color: #b0b0b0;
    pointer-events: none;
    cursor: default;
  `}
`;

const DayNumber = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  color: inherit;

  @media(max-width: 650px){
    font-size: 14px;
  }
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  ${props => props.disabled && `
    color: #999;
    pointer-events: none;
  `}
`;

/* Added for the time picker */
const PopupContainer = styled.div`
  position: absolute;
  // top: 100%;
  // left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 0 0 10px 10px;
`;
const SelectedDateDisplay = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  color: var(--dark-gray-color);
  border-radius: 5px;
  font-size: 20px;
  width: 100%;
`;


const CustomCalendar = ({ onDateSelect, initialSelectedDate, onTimeSelect }) => {
  const { formData, setFormData } = useContext(FormContext);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [showTimePopup, setShowTimePopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [formattedDateTime, setFormattedDateTime] = useState('');
  const calendarRef = useRef(null);

  useEffect(() => {
    if (initialSelectedDate) {
      const date = new Date(initialSelectedDate);
      setSelectedDate(date);
      setCurrentDate(new Date(date.getFullYear(), date.getMonth(), 1));
    }
  }, [initialSelectedDate]);

  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const handleDateClick = (e, day) => {
    e.preventDefault();
    const selected = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    selected.setHours(0, 0, 0, 0);
    if (selected >= today) {
      setSelectedDate(selected);
      onDateSelect(selected);

      const cellRect = e.currentTarget.getBoundingClientRect();
      const calendarRect = calendarRef.current.getBoundingClientRect();

      let top = cellRect.bottom - calendarRect.top;
      let left = cellRect.left - calendarRect.left;

      const timePickerWidth = 300; // Assuming the time picker width is 300px
      if (left + timePickerWidth > calendarRect.width) {
        left = Math.max(0, calendarRect.width - timePickerWidth);
      }
      setPopupPosition({ top, left });

      setShowTimePopup(true);
    }
  };

  const handlePopupClose = () => {
    setShowTimePopup(false);
  };

  const handleTimeSelect = (timeObj) => {
    const { date, startTime, endTime } = timeObj;
    const formattedDate = date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    console.log("formattedDate is type...", typeof(formattedDate));
    const formattedStartTime = startTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
    });
    const formattedEndTime = endTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
    });
    const formatted = `${formattedDate} ${formattedStartTime} - ${formattedEndTime}`;
    setFormattedDateTime(formatted);
    onTimeSelect(date, formatted);
    setShowTimePopup(false);
    setFormData(prevData => ({
      ...prevData,
      selectedDate: `${formattedDate}`,
      preferredTime: `${formattedStartTime} - ${formattedEndTime}`
    }));
    // Keep the current month displayed after time selection
    setCurrentDate(new Date(date.getFullYear(), date.getMonth() +1, 1)); // no idea why we have to do +1???
  };

  const nextMonth = (e) => {
    e.preventDefault();
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const prevMonth = (e) => {
    e.preventDefault();
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    if (newDate >= new Date(today.getFullYear(), today.getMonth(), 1)) {
      setCurrentDate(newDate);
    }
  };

  const renderCells = () => {
    const cells = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      cells.push(<CalendarCell key={`empty-${i}`} />);
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      date.setHours(0, 0, 0, 0);
      const isToday = date.getTime() === today.getTime();
      const isSelected = selectedDate && date.getTime() === selectedDate.getTime();
      const isDisabled = date < today;

      cells.push(
        <CalendarCell 
          key={day} 
          onClick={isDisabled ? null : (e) => handleDateClick(e, day)}
          isToday={isToday}
          isSelected={isSelected}
          isDisabled={isDisabled}
        >
          <DayNumber>{day}</DayNumber>
        </CalendarCell>
      );
    }
    return cells;
  };

  return (
    <>
      <CalendarContainer ref={calendarRef}>
        <CalendarHeader>
          <ArrowButton 
            onClick={prevMonth}
            disabled={currentDate <= new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1))}
          >
            «
          </ArrowButton>
          <span>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
          <ArrowButton onClick={nextMonth}>»</ArrowButton>
        </CalendarHeader>
        <CalendarGrid>
          {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
            <CalendarCell key={day}>{day}</CalendarCell>
          ))}
          {renderCells()}
        </CalendarGrid>
        {showTimePopup && (
          <PopupContainer style={{ top: `${popupPosition.top}px`, left: `${popupPosition.left}px` }}>
            <CustomTime
              selectedDate={selectedDate}
              onClose={handlePopupClose}
              onSave={handleTimeSelect}
              calendarRef={calendarRef}
              onDateChange={handleDateClick}
            />
          </PopupContainer>
        )}
      </CalendarContainer>
      {formattedDateTime && (
        <SelectedDateDisplay>{formattedDateTime}</SelectedDateDisplay>
      )}
    </>
  );
};

export default CustomCalendar;